import React from 'react';
import { List as ListAnt } from 'antd';
import {ListGridType, ListProps, ListSize} from "antd/lib/list";
interface Props {
  grid?: ListGridType;
  size?: ListSize;
  dataSource?: any[];
  renderItem?: (item: ListProps<any>, index: number) => React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

interface PropsItem {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

export const ListItem: React.FC<PropsItem> = (props) =>
  <ListAnt.Item style={props.style} className={props.className}>{props.children}</ListAnt.Item>;
export const List: React.FC<Props> = (props) => {
  return (
    <ListAnt
      style={props.style}
      className={props.className}
      grid={props.grid}
      size={props.size}
      dataSource={props.dataSource}
      renderItem={props.renderItem}/>
  );
};
export default {List, ListItem};
