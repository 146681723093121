import React, {useEffect, useState} from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select, Tag,
  TimePicker,
  Typography
} from "antd";
import {SwapLeftOutlined} from '@ant-design/icons';
import moment from "moment";
import {getClientsList} from "../../../../services/client";
import {createMeeting, deleteMeeting, getMeetingDetails, updateMeeting} from "../../../../services/meeting";
import Messages from "../../../antDesign/Message";
import { CirclePicker } from 'react-color';
const {Text} = Typography;

interface Props {
  visible: boolean;
  isNew: boolean;
  isClickOnBoard: boolean;
  selected: CALENDAR.CalendarResult | undefined;
  onConfirm: () => void;
  onClose: () => void;
}

const AddEditEvent: React.FC<Props> = (props) => {

  const [selectOptionClients, setSelectOptionClients] = useState<any>([]);
  const [clientSelected, setClientSelected] = useState<string | undefined>(undefined);
  const [color, setColor] = useState<string>('#d50000');

  const [form] = Form.useForm();

  const createBodyRequest = (values: any) => {
    return {
      ...values,
      latitude: 0,
      longitude: 0,
      start_time: moment(values.start_time).format('HH:mm'),
      end_time: moment(values.end_time).format('HH:mm'),
      start_date: moment(values.start_date),
      end_date: moment(values.end_date),
      color: color
    }
  }

  const handleCreateEvent = (values: any) => {
    const body = createBodyRequest(values);
    console.log(body)
    createMeeting(body).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        props.onConfirm()
      } else {
        Messages.Error('הפעולה נכשלה').then(() => {
        });
      }
    }).catch(() => {
      Messages.Error('הפעולה נכשלה').then(() => {
      });
    })
  }

  const handleUpdateEvent = (values: any) => {
    let body = createBodyRequest(values);
    body.meeting_id = props.selected?.id;
    body.drag_and_drop = false;
    updateMeeting(body).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        props.onConfirm()
      } else {
        Messages.Error('הפעולה נכשלה').then(() => {
        });
      }
    }).catch(() => {
      Messages.Error('הפעולה נכשלה').then(() => {
      });
    })
  }

  const handleDeleteEvent = () => {
    if (props.selected?.id) {
      deleteMeeting({meeting_id: props.selected.id}).then(response => {
        if (response.status.toString() === 'SUCCESS') {
          props.onConfirm()
        } else {
          Messages.Error('הפעולה נכשלה').then(() => {
          });
        }
      }).catch(() => {
        Messages.Error('הפעולה נכשלה').then(() => {
        });
      })
    }
  }

  const handleAfterClose = () => {
    form.resetFields();
    setSelectOptionClients([]);
    setClientSelected(undefined);
    setColor('#d50000');
  }

  const getClients = () => {
    getClientsList().then(response => {
      createTemplateClientsOptions(response.list);
    })
  }

  const createTemplateClientsOptions = (clients: any) => {
    if (clients.length > 0) {
      const options: any = []
      clients.forEach((client: any) => {
        options.push({
          label: client.firstName + ' ' + client.lastName + ' - ' + client.clientId,
          value: client._id
        })
      })
      setSelectOptionClients(options)
    }
  }

  useEffect(() => {
    if (props.visible) {
      if (!props.isNew) {
        if (props.selected?.id) {
          getMeetingDetails({id: props.selected.id}).then(response => {
            if (response.status.toString() === 'SUCCESS') {
              setClientSelected(response.meeting?.client_id);
              form.setFieldsValue({
                title: response.meeting.title,
                description: response.meeting.description,
                importance: response.meeting.importance,
                client_id: response.meeting.client_id,
                start_date: moment(response.meeting.start_date),
                end_date: moment(response.meeting.end_date),
                start_time: moment(response.meeting.start_time, 'HH:mm'),
                end_time: moment(response.meeting.end_time, 'HH:mm'),
              })
              setColor(response.meeting.color);
            }
          })
        }
      } else {
        if (!props.isClickOnBoard) {
          form.setFieldsValue({
            start_date: moment(),
            end_date: moment(),
            start_time: moment(),
            end_time: moment().add(1, 'hours'),
          })
        } else {
          if (props.selected) {
            form.setFieldsValue({
              start_date: moment(props.selected?.start),
              end_date: moment(props.selected?.end).subtract(1, "days"),
              start_time: moment(),
              end_time: moment().add(1, 'hours'),
            })
          }
        }
      }
      getClients();
    }
  }, [props.visible])

  return (
    <Modal
      title={props.isNew ? "הוספת פגישה" : "עריכת פגישה"}
      open={props.visible}
      afterClose={handleAfterClose}
      onCancel={() => props.onClose()}
      onOk={form.submit}
      maskClosable={false}
      destroyOnClose
      okText={props.isNew ? "צור" : "שמור"}
      cancelText="ביטול"
      width={700}>

      <div className='calendar'>
        <Form form={form}
              layout="vertical"
              onFinish={props.isNew ? handleCreateEvent : handleUpdateEvent}>

          <Row gutter={[16, 16]}>

            <Col xl={24} lg={24} md={24} sm={24} xs={24} className={!props.isNew ? 'mt-4' : ''}>
              <Form.Item
                name="title"
                label="כותרת"
                rules={[{
                  required: true,
                  message: 'שדה חובה'
                }]}>
                <Input/>
              </Form.Item>
            </Col>

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Text strong><Text type="danger">*</Text> מועד</Text>
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
              <Form.Item
                name="start_date"
                rules={[{type: 'object' as const, required: true, message: 'שדה חובה'}]}>
                <DatePicker format='DD-MM-YYYY'/>
              </Form.Item>
            </Col>


            <Col xl={5} lg={5} md={5} sm={5} xs={5}>
              <Form.Item name="start_time" rules={[{type: 'object' as const, required: true, message: 'שדה חובה'}]}>
                <TimePicker format='HH:mm'/>
              </Form.Item>
            </Col>

            <Col xl={2} lg={2} md={2} sm={2} xs={2} className='text-center'>
              <SwapLeftOutlined/>
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
              <Form.Item
                name="end_date"
                rules={[{type: 'object' as const, required: true, message: 'שדה חובה'}]}>
                <DatePicker format='DD-MM-YYYY'/>
              </Form.Item>
            </Col>

            <Col xl={5} lg={5} md={5} sm={5} xs={5}>
              <Form.Item name="end_time" rules={[{type: 'object' as const, required: true, message: 'שדה חובה'}]}>
                <TimePicker format='HH:mm'/>
              </Form.Item>
            </Col>

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                name="description"
                label="תיאור">
                <Input.TextArea showCount maxLength={200}/>
              </Form.Item>
            </Col>

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Text strong>צבע</Text>
              <CirclePicker
                className='mt-3'
                colors={['#d50000', '#e67c73', '#f4511e',
                '#f6bf26', '#33b679', '#0b8043',
                '#039be5', '#3f51b5', '#7986cb',
                '#8e24aa', '#616161', '#000000']}
                color={color}
                onChange={(value) => {setColor(value.hex)}}/>
            </Col>

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                name="location"
                label="מיקום">
                <Input/>
              </Form.Item>
            </Col>

            <Col xl={20} lg={20} md={20} sm={20} xs={20}>
              <Form.Item name="client_id" label="לקוח">
                <Select onChange={(value) => setClientSelected(value)}>
                  {
                    selectOptionClients && selectOptionClients.length > 0 &&
                    selectOptionClients.map((client: any, index: any) => (
                      <Select.Option key={index}
                                     value={client.value}>{client.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>


            <Col xl={4} lg={4} md={4} sm={4} xs={4} className='my-auto'>
              <Button disabled={!clientSelected} type='link' target='_blank'
                      href={'/client-details?cid=' + clientSelected}>תיק לקוח</Button>
            </Col>


            {
              !props.isNew &&
                <Col xl={24} lg={24} md={24} sm={24} xs={24} className='text-center'>
                    <Button type='link' danger onClick={() => handleDeleteEvent()}>
                        מחק פגישה
                    </Button>
                </Col>
            }

          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddEditEvent;
