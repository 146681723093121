import React, {Component} from 'react';
import {
    Button,
    CardBody, Col,
    Container, CustomInput,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Form,
    Input,
    Media,
    Nav,
    NavItem, NavLink, Row, Spinner, TabContent, TabPane
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import {Link} from "react-router-dom";
import {addMessage, getClients, getMessages, readMessages} from "../../services/conversation";
import {withNamespaces} from "react-i18next";
import {Editor} from "react-draft-wysiwyg";
import EditorState from "draft-js/lib/EditorState";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {convertFromRaw, convertToRaw} from "draft-js";
import {Buffer} from 'buffer';

const defaultEditorText = {
    "blocks": [{
        "key": "8b44",
        "text": "",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    }], "entityMap": {}
};

class Conversation extends Component {

    constructor(props) {
        super(props);

        this.inputFile = React.createRef();

        this.state = {
            breadcrumbItems: [
                {title: this.props.t("BREADCRUMB.CONVERSATION.MAIN"), link: "#"},
                {title: this.props.t("BREADCRUMB.CONVERSATION.LIST"), link: "#"},
            ],
            conversations: [], // all clients in conversations
            conversation: { // single client and messages
                messages: [],
            },
            contacts: [], // all contacts
            other: false,
            settings: false,
            settings2: false,
            activeTab: '1',
            Chat_Box_Username: "", // top bar name of client
            curMessage: "", // regular text message
            loading: false,
            addSettings: { // button near send button
                isEditorActive: false
            },
            editorState: EditorState.createEmpty(), // editor text message
            uploadFiles: []
        }
    }

    componentDidMount = () => {
        this.loadConversations({type: 'MESSAGES'});
        this.onReload();
    }

    componentWillUnmount() {
        this.stopReload();
    }

    onReload() {
        window.onbeforeunload = (event) => {
            const conversations = this.state.conversations;
            const index = conversations.findIndex(conversation => conversation.isActive === true);
            if (conversations[index].total_messages_not_read > 0) {
                // read messages of client
                this.readClientMessages(conversations[index].client_id);
            }
        };
    }

    stopReload() {
        window.onbeforeunload = null;
    }

    loadConversations = (data, loadMessages = true) => {
        getClients(data).then(response => {
            if (response.status.toString() === 'SUCCESS') {

                response.meeting_summary.conversations.map((conversation, index) => {
                    conversation.isActive = index === 0;
                    return conversation;
                })
                if (loadMessages && response.meeting_summary.conversations.length > 0) {
                    this.loadMessages(response.meeting_summary.conversations[0].client_id)
                    this.setState({
                        Chat_Box_Username:
                            response.meeting_summary.conversations[0].client.firstName + " " +
                            response.meeting_summary.conversations[0].client.lastName
                    })
                }
                this.setState({conversations: response.meeting_summary.conversations});
                response.meeting_summary.contact.forEach((contact) => {
                    contact.child.forEach(child => {
                        child.isActive = false
                    })
                })
                this.setState({contacts: response.meeting_summary.contact});
            }
        })
    }

    startLoading = () => {
        this.setState({loading: true});
    }

    stopLoading = () => {
        this.setState({loading: false});
    }

    loadMessages = (client_id) => {
        this.startLoading();
        getMessages({client_id}).then(response => {
            if (response.status.toString() === 'SUCCESS') {
                this.setState({
                    conversation: response.conversations.length === 0 ?
                        {messages: []} : response.conversations
                })
            }
            this.stopLoading();
        })
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    readClientMessages = (client_id) => {
        readMessages({client_id: client_id, reader: 'AGENT'}).then((response) => {
            if (response.status.toString() === 'SUCCESS') {
                let conversationsModule = [...this.state.conversations];
                const index = conversationsModule.findIndex(conversation => conversation.client_id === client_id);
                if (index !== -1) {
                    conversationsModule[index].total_messages_not_read = 0;
                }
                this.setState({conversations: conversationsModule})
            }
        })
    }

    chatOpen = (conversation, type) => {

        let conversationsModule = [...this.state.conversations];
        let contactModule = [...this.state.contacts];

        const indexActive = conversationsModule.findIndex(conversation => conversation.isActive === true);

        if (indexActive !== -1) {
            // check if exists messages not read
            if (conversationsModule[indexActive].total_messages_not_read > 0) {
                // read messages of previous client
                this.readClientMessages(conversationsModule[indexActive].client_id);
            }
            conversationsModule[indexActive].isActive = false;
        }

        for (let i = 0; i < contactModule.length; i++) {
            for (let j = 0; j < contactModule[i].child.length; j++) {
                contactModule[i].child[j].isActive = false;
            }
        }

        let username = ''
        let index = -1;
        switch (type) {
            case 'MESSAGES':
                // Enable All Option First
                index = conversationsModule.findIndex(conversationModule => conversationModule._id === conversation._id);

                if (index !== -1) {
                    conversationsModule[index].isActive = true;

                    this.loadMessages(conversationsModule[index].client_id);

                    username = conversationsModule[index].client.firstName + " " + conversationsModule[index].client.lastName;
                }
                break;
            case 'CLIENTS':
                contactModule.forEach((contact, pos) => {
                    index = contact.child.findIndex(contact => contact.id === conversation.id);
                    if (index !== -1) {
                        contactModule[pos].child[index].isActive = true;
                        const fullName = conversation.name.split(' ');
                        username = fullName[0] + " " + fullName[1];
                        this.loadMessages(conversation.id);
                    }
                })
                break;
            default:
                break;
        }
        this.setState({conversations: conversationsModule, Chat_Box_Username: username})
    }

    insertMessage = (type) => {

        if (this.state.curMessage || this.state.editorState.getCurrentContent().hasText()) {
            let text = {...defaultEditorText};
            text.blocks[0].text = this.state.curMessage;
            text = JSON.stringify(text);
            const editorText = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));

            const files = [];
            this.state.uploadFiles.forEach(file => {
                files.push(file.file);
            })

            switch (type) {
                case 'MESSAGES':
                    const index = this.state.conversations.findIndex(conversation => conversation.isActive === true)

                    if (index === -1)
                        return;

                    const body = {
                        client_id: this.state.conversations[index].client_id,
                        sender: 'AGENT',
                        data: this.state.addSettings.isEditorActive ?
                            editorText :
                            text,
                        files: files
                    }
                    addMessage(body).then(response => {
                        if (response.status.toString() === 'SUCCESS') {
                            const conversation = this.state.conversation;
                            const conversations = this.state.conversations;

                            conversation.messages.push(response.message);

                            const index = conversations.findIndex(conversation => conversation.isActive === true);
                            conversations[index].last_message = response.message;

                            if (conversations[index].total_messages_not_read > 0) {
                                // read messages of client
                                this.readClientMessages(conversations[index].client_id);
                            }

                            this.loadConversations({type: 'MESSAGES'}, false);
                            this.setState({
                                conversation,
                                conversations,
                                curMessage: '',
                                editorState: EditorState.createEmpty(),
                                uploadFiles: []
                            })
                            this.scrollToBottom();
                        }
                    })
                    break;
                case 'CLIENTS':
                    let contactModule = [...this.state.contacts];
                    for (let i = 0; i < contactModule.length; i++) {
                        const index = contactModule[i].child.findIndex(contact => contact.isActive === true);

                        if (index !== -1) {
                            const body = {
                                client_id: contactModule[i].child[index].id,
                                sender: 'AGENT',
                                data: this.state.addSettings.isEditorActive ?
                                    editorText :
                                    text,
                                files: files
                            }

                            addMessage(body).then(response => {
                                if (response.status.toString() === 'SUCCESS') {
                                    const conversation = this.state.conversation;
                                    conversation.messages.push(response.message);

                                    // if (conversation[index].total_messages_not_read > 0) {
                                    //     // read messages of client
                                    //     this.readClientMessages(conversation[index].client_id);
                                    // }

                                    this.setState({
                                        conversation,
                                        curMessage: '',
                                        editorState: EditorState.createEmpty(),
                                        uploadFiles: []
                                    })
                                    this.loadConversations({type: 'MESSAGES'}, false);
                                    this.scrollToBottom();
                                }
                            })
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({editorState: editorState});
    }

    scrollToBottom() {

    }

    removeFile = (index) => {
        let uploadFiles = this.state.uploadFiles;
        uploadFiles.splice(index, 1);
        this.setState({uploadFiles});
    }

    displayFile = (file) => {
        const fileContentBase64 = new Buffer.from(JSON.parse(file.fileContent)).toString("base64")
        return `data:${file.contentType};base64,${fileContentBase64}`
    }

    downloadFile = (file) => {
        const arr = JSON.parse(file.fileContent).data;
        const byteArray = new Uint8Array(arr);
        const blob = new Blob([byteArray], {type: file.contentType});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file.fileName;
        link.click();
    }

    render() {
        return (
            <React.Fragment>

                <CustomInput id='file'
                             name='file'
                             type='file'
                             hidden
                             onChange={(event) => {
                                 const uploadFiles = this.state.uploadFiles;
                                 if (event.target.files.length > 0) {
                                     const file = event.target.files[0];
                                     uploadFiles.push({
                                         file,
                                         type: file.type,
                                         url: URL.createObjectURL(file)
                                     })
                                     this.setState({uploadFiles})
                                 }
                             }}
                             className="custom-file-input"/>

                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title={this.props.t("BREADCRUMB.CONVERSATION.LIST")}
                                     breadcrumbItems={this.state.breadcrumbItems}/>

                        <div className="d-lg-flex mb-4">
                            <div className="chat-leftsidebar">
                                <CardBody className="border-bottom py-2">
                                    <div className="search-box chat-search-box">
                                        <div className="position-relative">
                                            <Input type="text" className="form-control"
                                                   placeholder={this.props.t("CONVERSATION_PAGES.SEARCH") + "..."}/>
                                            <i className="ri-search-line search-icon"/>
                                        </div>
                                    </div>
                                </CardBody>

                                {/*tabs*/}
                                <div className="chat-leftsidebar-nav">
                                    <Nav pills justified>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({active: this.state.activeTab === '1'})}
                                                onClick={() => {
                                                    this.toggleTab('1');
                                                }}
                                            >
                                                <i className="ri-message-2-line font-size-20"/>
                                                <span
                                                    className="mt-2 d-none d-sm-block">{this.props.t("CONVERSATION_PAGES.MESSAGES")}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({active: this.state.activeTab === '2'})}
                                                onClick={() => {
                                                    this.toggleTab('2');
                                                }}>
                                                <i className="ri-contacts-book-2-line font-size-20"/>
                                                <span
                                                    className="mt-2 d-none d-sm-block">{this.props.t("CONVERSATION_PAGES.CUSTOMERS")}</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>

                                {/*tab selected*/}
                                <TabContent activeTab={this.state.activeTab} className="py-4">
                                    <TabPane tabId="1">
                                        {
                                            this.state.conversations.length > 0 &&
                                            <ul className="list-unstyled chat-list">
                                                <SimpleBar style={{maxHeight: "345px"}}>
                                                    {
                                                        this.state.conversations.map((conversation, key) =>
                                                            <li key={key}
                                                                className={conversation.isActive ? "active" : ""}>
                                                                <Link
                                                                    to="#" onClick={() => {
                                                                    this.chatOpen(conversation, 'MESSAGES')
                                                                }}>
                                                                    <Media>

                                                                        <div className="avatar-xs mr-3">
                                                                            <span
                                                                                className="avatar-title rounded-circle bg-light text-body">
                                                                                {conversation.client.firstName.substring(0, 1) +
                                                                                    "" +
                                                                                    conversation.client.lastName.substring(0, 1)}
                                                                            </span>
                                                                        </div>

                                                                        <Media body className="overflow-hidden">
                                                                            <h5 className="text-truncate font-size-14 mb-1">
                                                                                {conversation.client.firstName + " " +
                                                                                    conversation.client.lastName}</h5>
                                                                            <p className="text-truncate mb-0"
                                                                               style={conversation.total_messages_not_read > 0
                                                                                   ? {fontWeight: 'bold'} : {}}>{conversation.last_message.data}</p>

                                                                        </Media>

                                                                        <div className="overflow-hidden">
                                                                            <div className="font-size-11">

                                                                                {
                                                                                    conversation.last_message.sent_before.days !== 0 ?
                                                                                        conversation.last_message.sent_before.days + " " + this.props.t("CONVERSATION_PAGES.DAY") :
                                                                                        conversation.last_message.sent_before.hours !== 0 ?
                                                                                            conversation.last_message.sent_before.hours + " " + this.props.t("CONVERSATION_PAGES.HOUR_SHORTCUT") :
                                                                                            conversation.last_message.sent_before.minutes !== 0 ?
                                                                                                conversation.last_message.sent_before.minutes + " " + this.props.t("CONVERSATION_PAGES.MINUTES_SHORTCUT") :
                                                                                                conversation.last_message.sent_before.seconds !== 0 ?
                                                                                                    conversation.last_message.sent_before.seconds + " " + this.props.t("CONVERSATION_PAGES.SECONDS_SHORTCUT") :
                                                                                                    " " + this.props.t("CONVERSATION_PAGES.NOW")
                                                                                }
                                                                            </div>
                                                                            {
                                                                                conversation.total_messages_not_read > 0 &&
                                                                                <div className='mt-1'>
                                                                                <span
                                                                                    className="badge badge-pill badge-danger float-right">{conversation.total_messages_not_read}</span>
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                    </Media>

                                                                </Link>
                                                            </li>
                                                        )
                                                    }
                                                </SimpleBar>
                                            </ul>
                                        }

                                        {
                                            this.state.conversations.length === 0 &&
                                            <div className='text-center p-5'>
                                                <label>{this.props.t("CONVERSATION_PAGES.EMPTY_MESSAGES")}</label>
                                            </div>
                                        }

                                    </TabPane>

                                    <TabPane tabId="2">

                                        <SimpleBar style={{maxHeight: "345px"}}>
                                            {
                                                this.state.contacts.map((contact, key) =>
                                                    <div key={key} className={(key + 1 !== 1) ? "mt-4" : ""}>
                                                        <div className="p-3">
                                                            {contact.category}
                                                        </div>

                                                        <ul className="list-unstyled chat-list">
                                                            {
                                                                contact.child.map((client, key) =>
                                                                    <li key={key}>
                                                                        <Link to="#"
                                                                              onClick={() => {
                                                                                  this.chatOpen(client, 'CLIENTS')
                                                                              }}
                                                                        >
                                                                            <h5 className="font-size-14 mb-0">{client.name}</h5>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                        </SimpleBar>

                                    </TabPane>
                                </TabContent>
                            </div>

                            {/*top bar user chat*/}
                            <div className="w-100 user-chat mt-4 mt-sm-0">
                                <div className="p-3 px-lg-4 user-chat-border">
                                    <Row>
                                        <Col md={4} xs={6}>
                                            <h5 className="font-size-15 mb-1 text-truncate">{this.state.Chat_Box_Username}</h5>
                                        </Col>
                                        <Col md={8} xs={6}>
                                            <ul className="list-inline user-chat-nav text-right mb-0">
                                                <li className="list-inline-item d-inline-block d-sm-none">
                                                    <Dropdown isOpen={this.state.settings}
                                                              toggle={() => this.setState({settings: !this.state.settings})}>
                                                        <DropdownToggle className="btn nav-btn dropdown-toggle"
                                                                        type="button">
                                                            <i className="mdi mdi-magnify"/>
                                                        </DropdownToggle>
                                                        <DropdownMenu right className="dropdown-menu-md">
                                                            <Form className="p-2">
                                                                <div className="search-box">
                                                                    <div className="position-relative">
                                                                        <Input type="text"
                                                                               className="form-control rounded"
                                                                               placeholder="Search..."/>
                                                                        <i className="mdi mdi-magnify search-icon"/>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </li>
                                                <li className="d-none d-sm-inline-block">
                                                    <div className="search-box mr-2">
                                                        <div className="position-relative">
                                                            <Input type="text" className="form-control"
                                                                   placeholder={this.props.t("CONVERSATION_PAGES.SEARCH") + "..."}/>
                                                            <i className="mdi mdi-magnify search-icon"/>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-inline-item m-0 d-none d-sm-inline-block">
                                                    <Dropdown isOpen={this.state.settings2}
                                                              toggle={() => this.setState({settings2: !this.state.settings2})}>
                                                        <DropdownToggle className="btn nav-btn" tag="i">
                                                            <i className="mdi mdi-cog"/>
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <DropdownItem href="#">View Profile</DropdownItem>
                                                            <DropdownItem href="#">Clear chat</DropdownItem>
                                                            <DropdownItem href="#">Muted</DropdownItem>
                                                            <DropdownItem href="#">Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </li>

                                                <li className="list-inline-item">
                                                    <Dropdown isOpen={this.state.other}
                                                              toggle={() => this.setState({other: !this.state.other})}>
                                                        <DropdownToggle className="btn nav-btn " tag="i">
                                                            <i className="mdi mdi-dots-horizontal"/>
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <DropdownItem href="#">Action</DropdownItem>
                                                            <DropdownItem href="#">Another action</DropdownItem>
                                                            <DropdownItem href="#">Something else</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </li>

                                            </ul>
                                        </Col>
                                    </Row>
                                </div>

                                {/*All Messages*/}
                                <div className="px-lg-2">
                                    <div className="chat-conversation p-3 chat-conversation-height">
                                        <ul className="list-unstyled mb-0 pr-3">
                                            <SimpleBar ref={simpleBar => {
                                                this.simpleBar = simpleBar
                                            }} style={{maxHeight: "450px"}}>

                                                {
                                                    this.state.loading && (
                                                        <div style={{
                                                            width: '0%',
                                                            margin: '0 auto'
                                                        }}>
                                                            <Spinner color="primary"/>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    !this.state.loading && this.state.conversation.messages.map((message, key) =>
                                                        <div key={key}>
                                                            {
                                                                (key === 0) &&
                                                                <li className='text-center'>
                                                                    <div style={{padding: '10px'}}>
                                                                        <label>{message.createdAt}</label>
                                                                    </div>
                                                                </li>
                                                            }
                                                            {

                                                                (key !== 0 && this.state.conversation.messages[key - 1].createdAt !== message.createdAt) &&

                                                                <li className='text-center'>
                                                                    <div style={{padding: '10px'}}>
                                                                        <label>{message.createdAt}</label>
                                                                    </div>
                                                                </li>
                                                            }

                                                            {message.attach.map((file, key) =>
                                                                <div key={key}>
                                                                    <li className={message.sender === 'AGENT' ? "right" : ""}
                                                                        style={{paddingRight: "20px"}}>
                                                                        <div className="conversation-list">
                                                                            {
                                                                                message.sender === 'CLIENT' ?
                                                                                    <div className="chat-avatar">
                                                                            <span
                                                                                className="avatar-title rounded-circle bg-light text-body"
                                                                                style={{padding: '8px'}}>
                                                                                {
                                                                                    this.state.Chat_Box_Username.split(' ')[0].substring(0, 1) + "" +
                                                                                    this.state.Chat_Box_Username.split(' ')[1].substring(0, 1)
                                                                                }
                                                                            </span>
                                                                                    </div> : null
                                                                            }

                                                                            <div className="ctext-wrap">
                                                                                <div
                                                                                    className="conversation-name">{message.sender === 'AGENT' ? '' : this.state.Chat_Box_Username}</div>
                                                                                <div className="ctext-wrap-content"
                                                                                     style={
                                                                                         !file.contentType.startsWith('image') ?
                                                                                             {
                                                                                                 minWidth: 85,
                                                                                                 padding: '5px 5px',
                                                                                                 width: 200,
                                                                                                 height: 250
                                                                                             } : {
                                                                                                 minWidth: 85,
                                                                                                 padding: '5px 5px'
                                                                                             }}>
                                                                                    <div style={{whiteSpace: "pre-line"}}
                                                                                         className="mb-0">

                                                                                        {
                                                                                            file.contentType.startsWith('image') &&
                                                                                            <div
                                                                                                className="position-relative">
                                                                                                <div
                                                                                                    className="position-absolute"
                                                                                                    style={message.sender === 'CLIENT' ?
                                                                                                        {
                                                                                                            left: 5,
                                                                                                            top: 5
                                                                                                        } : {
                                                                                                            right: 5,
                                                                                                            top: 5
                                                                                                        }}>
                                                                                                    <Button
                                                                                                        color="light"
                                                                                                        className="btn-rounded waves-effect mr-1"
                                                                                                        onClick={() => this.downloadFile(file)}>
                                                                                                        <i className="mdi mdi-download cursor-pointer"/>
                                                                                                    </Button>
                                                                                                </div>
                                                                                                <img
                                                                                                    src={this.displayFile(file)}
                                                                                                    width='200px'
                                                                                                    height='250px'
                                                                                                    alt={file.fileName}/>
                                                                                            </div>
                                                                                        }

                                                                                        {
                                                                                            !file.contentType.startsWith('image') &&
                                                                                            <div
                                                                                                className="position-relative"
                                                                                                style={{textAlign: 'center'}}>
                                                                                                <div
                                                                                                    className="position-absolute"
                                                                                                    style={message.sender === 'CLIENT' ?
                                                                                                        {
                                                                                                            left: 5,
                                                                                                            top: 5
                                                                                                        } : {
                                                                                                            right: 5,
                                                                                                            top: 5
                                                                                                        }}>
                                                                                                    <Button
                                                                                                        color="light"
                                                                                                        className="btn-rounded waves-effect mr-1"
                                                                                                        onClick={() => this.downloadFile(file)}>
                                                                                                        <i className="mdi mdi-download cursor-pointer"/>
                                                                                                    </Button>
                                                                                                </div>
                                                                                                <i className={"mdi " +
                                                                                                    (file.contentType.endsWith('pdf') ?
                                                                                                        "mdi-file-pdf" : "mdi-file-document")}
                                                                                                   style={{fontSize: 120}}></i>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                                {
                                                                                    !file.contentType.startsWith('image') &&
                                                                                    <p style={{
                                                                                        fontSize: 12,
                                                                                        width: 200
                                                                                    }}>{file.fileName}</p>
                                                                                }

                                                                                <Row>
                                                                                    <Col lg='auto' xl='auto' md='auto'
                                                                                         xs='auto'
                                                                                         className={message.sender === 'AGENT' ? 'ml-auto' : 'mr-auto'}>
                                                                                        <p className="chat-time mb-0"><i
                                                                                            className="mdi mdi-clock-outline align-middle mr-1"/>
                                                                                            {
                                                                                                message.sent_before.time
                                                                                            }
                                                                                        </p>
                                                                                    </Col>
                                                                                    <Col lg='auto' xl='auto' md='auto'
                                                                                         xs='auto'>
                                                                                        {
                                                                                            message.sender === 'AGENT' &&
                                                                                            <i className="mdi mdi-check-all"
                                                                                               style={message.is_read ? {
                                                                                                   float: 'left',
                                                                                                   color: '#11bb69'
                                                                                               } : {float: 'left'}}/>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>

                                                                        </div>
                                                                    </li>
                                                                </div>
                                                            )}

                                                            <li className={message.sender === 'AGENT' ? "right" : ""}
                                                                style={{paddingRight: "20px"}}>
                                                                <div className="conversation-list">
                                                                    {
                                                                        message.sender === 'CLIENT' ?
                                                                            <div className="chat-avatar">
                                                                            <span
                                                                                className="avatar-title rounded-circle bg-light text-body"
                                                                                style={{padding: '8px'}}>
                                                                                {
                                                                                    this.state.Chat_Box_Username.split(' ')[0].substring(0, 1) + "" +
                                                                                    this.state.Chat_Box_Username.split(' ')[1].substring(0, 1)
                                                                                }
                                                                            </span>
                                                                            </div> : null
                                                                    }

                                                                    <div className="ctext-wrap">
                                                                        <div
                                                                            className="conversation-name">{message.sender === 'AGENT' ? '' : this.state.Chat_Box_Username}</div>
                                                                        <div className="ctext-wrap-content"
                                                                             style={{minWidth: 85}}>
                                                                            <div style={{whiteSpace: "pre-line"}}
                                                                                 className="mb-0">

                                                                                <Editor
                                                                                    wrapperClassName={"editor-wrapper"}
                                                                                    editorState={EditorState.createWithContent(
                                                                                        convertFromRaw(JSON.parse(message.data)))}
                                                                                    readOnly={true}
                                                                                    editorClassName={"editor-message"}
                                                                                    toolbarHidden
                                                                                />
                                                                            </div>

                                                                        </div>

                                                                        <Row>
                                                                            <Col lg='auto' xl='auto' md='auto' xs='auto'
                                                                                 className={message.sender === 'AGENT' ? 'mr-auto' : 'ml-auto'}>
                                                                                <p className="chat-time mb-0"><i
                                                                                    className="mdi mdi-clock-outline align-middle mr-1"/>
                                                                                    {
                                                                                        message.sent_before.time
                                                                                    }
                                                                                </p>
                                                                            </Col>
                                                                            <Col lg='auto' xl='auto' md='auto' xs='auto'>
                                                                                {
                                                                                    message.sender === 'AGENT' &&
                                                                                    <i className="mdi mdi-check-all"
                                                                                       style={message.is_read ? {
                                                                                           float: 'left',
                                                                                           color: '#11bb69'
                                                                                       } : {float: 'left'}}/>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                        {/*<p className="chat-time mb-0"><i*/}
                                                                        {/*    className="mdi mdi-clock-outline align-middle mr-1"/>*/}
                                                                        {/*    {*/}
                                                                        {/*        message.sent_before.time*/}
                                                                        {/*    }*/}
                                                                        {/*</p>*/}
                                                                    </div>

                                                                </div>
                                                            </li>
                                                        </div>
                                                    )
                                                }
                                            </SimpleBar>
                                        </ul>
                                    </div>

                                </div>

                                {/*Send Message*/}
                                <div className="px-lg-3">
                                    <div className="p-3 chat-input-section ">

                                        {
                                            this.state.uploadFiles.length > 0 &&
                                            <Row className='border mb-2' style={{height: 80}}>
                                                {this.state.uploadFiles.map((file, index) => (
                                                    <Col key={index} lg='auto' xl='auto' md='auto' xs='auto'
                                                         className='my-auto'>
                                                        <div className="position-relative">
                                                            {file.type.startsWith('image') &&
                                                                <>
                                                                    <div className="position-absolute"
                                                                         style={{left: -15, top: -10}}>

                                                                        <Button
                                                                            color="light"
                                                                            className="btn-rounded waves-effect mr-1"
                                                                            style={{padding: '3px 10px'}}
                                                                            onClick={() => this.removeFile(index)}>
                                                                            <i className="mdi mdi-close cursor-pointer"
                                                                               style={{
                                                                                   color: 'red',
                                                                                   fontSize: 10
                                                                               }}/>
                                                                        </Button>
                                                                    </div>
                                                                    <img src={file.url} width='70px' height='70px'
                                                                         alt=""/>
                                                                </>
                                                            }

                                                            {!file.type.startsWith('image') &&
                                                                <>
                                                                    <div
                                                                        className="position-absolute"
                                                                        style={{left: -15, top: -10}}>
                                                                        <Button
                                                                            color="light"
                                                                            className="btn-rounded waves-effect mr-1"
                                                                            style={{padding: '3px 10px'}}
                                                                            onClick={() => this.removeFile(index)}>
                                                                            <i className="mdi mdi-close cursor-pointer"
                                                                               style={{
                                                                                   color: 'red',
                                                                                   fontSize: 10
                                                                               }}/>
                                                                        </Button>
                                                                    </div>
                                                                    <i className={"mdi " +
                                                                        (file.type.endsWith('pdf') ?
                                                                            "mdi-file-pdf" : "mdi-file-document")}
                                                                       style={{fontSize: 50}}></i>
                                                                </>
                                                            }
                                                        </div>

                                                    </Col>
                                                ))
                                                }
                                            </Row>
                                        }

                                        <Row>
                                            <Col>
                                                <div className="position-relative">
                                                    {
                                                        !this.state.addSettings.isEditorActive &&
                                                        <Input type="text" value={this.state.curMessage}
                                                               onChange={(e) => {
                                                                   this.setState({curMessage: e.target.value})
                                                               }} className="form-control chat-input"
                                                               placeholder={this.props.t("CONVERSATION_PAGES.ENTER_MESSAGE") + "..."}/>
                                                    }

                                                    {
                                                        this.state.addSettings.isEditorActive &&
                                                        <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            editorState={this.state.editorState}
                                                            onEditorStateChange={this.onEditorStateChange}
                                                        />
                                                    }

                                                </div>
                                            </Col>
                                            <Col xs={{size: 'auto'}} style={{marginTop: 'auto'}}>


                                                <Dropdown
                                                    isOpen={this.state.singlebtn1}
                                                    toggle={() =>
                                                        this.setState({singlebtn1: !this.state.singlebtn1})
                                                    }>
                                                    <DropdownToggle color="light" caret style={{borderRadius: '50%'}}>
                                                        <i className="mdi mdi-plus"/>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => this.setState(
                                                            {
                                                                addSettings: {
                                                                    isEditorActive:
                                                                        !this.state.addSettings.isEditorActive
                                                                }
                                                            })}>תיבת טקסט
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => {
                                                                document.getElementById('file').click()
                                                            }}>הוסף קובץ</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                            <Col xs={{size: 'auto'}} style={{marginTop: 'auto'}}>

                                                <Button
                                                    onClick={() => this.insertMessage(this.state.activeTab === '1' ? 'MESSAGES' : 'CLIENTS')}
                                                    type="submit" color="primary"
                                                    className="chat-send w-md waves-effect waves-light">

                                                    <i className="mdi mdi-send"/>
                                                    <span className="d-none d-sm-inline-block ml-2">
                                                        {this.props.t("CONVERSATION_PAGES.SENT")}</span></Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Conversation);
