import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import heIL from 'antd/lib/locale/he_IL';
import store from './store';
import {ConfigProvider} from "antd";

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <ConfigProvider direction="rtl" locale={heIL}>
                <App/>
            </ConfigProvider>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
