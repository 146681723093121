import baseService from "../base";
import axios from "../axios";

export const getClientsList = async (data: CLIENT.ClientTableParam = {}) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getClientsList;
  url = url.replace(':current', "current=" + (data && data.current ? data.current : ''))
    .replace(':pageSize', "pageSize=" + (data && data.pageSize ? data.pageSize : ''));
  return await axios.GET(url, {auth: true}).catch();
}

export const getTreatmentClientsList = async (data: CLIENT.ClientTableParam = {}) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getTreatmentClientsList;
  url = url.replace(':current', "current=" + (data && data.current ? data.current : ''))
    .replace(':pageSize', "pageSize=" + (data && data.pageSize ? data.pageSize : ''));
  return await axios.GET(url, {auth: true}).catch();
}

export const getClient = async (data: CLIENT.ClientParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getClient;
  url = url.replace(':clientId', "clientId=" + data.clientId);
  return await axios.GET(url, {auth: true}).catch();
}

export const sendAgainDocumentSignature = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.client.sendAgainDocumentSignature;
  return await axios.POST(url, data, {auth: true}).catch();
}

export const sendPoliciesToInsuranceCompanies = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.client.sendPoliciesToInsuranceCompanies;
  return await axios.POST(url, data, {auth: true}).catch();
}

export const getClientWithoutSignature = async (data: CLIENT.ClientParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getClientWithoutSignature;
  url = url.replace(':clientId', "clientId=" + data.clientId);
  return await axios.GET(url, {auth: true}).catch();
}

export const getClientDetails = async (data: CLIENT.ClientDetailsParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getClientDetails;
  url = url.replace(':clientId', "clientId=" + data.clientId);
  return await axios.GET(url, {auth: true}).catch();
}

export const getClientDetailsProducts = async (data: CLIENT.ClientParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getClientDetailsProducts;
  url = url.replace(':clientId', "clientId=" + data.clientId);
  return await axios.GET(url, {auth: true}).catch();
}

export const getClientDetailsProductsElementary = async (data: CLIENT.ClientParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getClientDetailsProductsElementary;
  url = url.replace(':clientId', "clientId=" + data.clientId);
  return await axios.GET(url, {auth: true}).catch();
}

export const getPolicyDocument = async (documentId: string) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getPolicyDocument;
  url = url.replace(':documentId', "documentId=" + documentId);
  return await axios.GET(url, {auth: true}).catch();
}

export const deletePolicyDocument = async (data: {documentId: string}) => {
  let url = baseService.baseUrl + baseService.endpoints.client.deletePolicyDocument;
  return await axios.DELETE(url, data, {auth: true}).catch();
}

export const getClientDetailsDocuments = async (data: CLIENT.ClientParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getClientDetailsDocuments;
  url = url.replace(':clientId', "clientId=" + (data && data.clientId ? data.clientId : ''));
  return await axios.GET(url, {auth: true}).catch();
}

export const getClientDetailsInsuranceMountainDocuments = async (data: CLIENT.ClientParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getClientDetailsInsuranceMountainDocuments;
  url = url.replace(':clientId', "clientId=" + (data && data.clientId ? data.clientId : ''));
  return await axios.GET(url, {auth: true}).catch();
}

export const getClientDetailsSignatureDocuments = async (data: CLIENT.ClientParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getClientDetailsSignatureDocuments;
  url = url.replace(':clientId', "clientId=" + (data && data.clientId ? data.clientId : ''));
  return await axios.GET(url, {auth: true}).catch();
}

export const getBoxDocuments = async (data: CLIENT.ClientParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getBoxDocuments;
  url = url.replace(':clientId', "clientId=" + (data && data.clientId ? data.clientId : ''));
  return await axios.GET(url, {auth: true}).catch();
}
export const deleteBoxDocument = async (data: CLIENT.DeleteDocumentParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.deleteBoxDocuments;
  return await axios.DELETE(url, data, {auth: true}).catch();
}

// // Not Used
// const getClientDetailsTasks = (data) => {
//   let url = baseService.baseUrl + baseService.endpoints.client.getClientTasks;
//   url = url.replace(':clientId', "clientId=" + data.clientId);
//   let options = baseService.options;
//   options.headers.Authorization = getUserToken();
//
//   return axios.get(url, options).then(response => {
//     if (response.status === 400 || response.status === 500)
//       throw response.data;
//     return response.data;
//   });
// }

export const getTasks = async (data: CLIENT.TaskParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.getTasks;
  url = url.replace(':id', "id=" + (data && data.id ? data.id : ''))
    .replace(':clientId', "clientId=" + (data && data.name ? data.name : ''))
    .replace(':productId', "productId=" + (data && data.product_name ? data.product_name : ''))
    .replace(':policyId', "policyId=" + (data && data.policyId ? data.policyId : ''))
    .replace(':treatmentId', "treatmentId=" + (data && data.treatmentId ? data.treatmentId : ''))
    .replace(':type', "type=" + (data && data.type ? data.type : ''))
    .replace(':subtype', "subtype=" + (data && data.subtype ? data.subtype : ''))
    .replace(':status', "status=" + (data && data.status ? data.status : ''))
    .replace(':createdAt', "createdAt=" + (data && data.createdAt ? data.createdAt : ''))
    .replace(':updatedAt', "updatedAt=" + (data && data.updatedAt ? data.updatedAt : ''))
    .replace(':current', "current=" + data.current)
    .replace(':pageSize', "pageSize=" + data.pageSize);
  return await axios.GET(url, {auth: true}).catch();
}

export const createClientTask = async (data: any, files: any) => {
  let url = baseService.baseUrl + baseService.endpoints.client.createClientTask;
  const formData = new FormData();

  if (data.agentId) {
    formData.append('agentId', data.agentId);
  }
  if (data.product) {
    formData.append('product', data.product);
  }
  if (data.policy) {
    formData.append('policy', data.policy);
  }
  if (data.client) {
    formData.append('client', data.client);
  }
  if (data.property) {
    formData.append('property', data.property);
  }
  if (data.treatment) {
    formData.append('treatment', data.treatment);
  }

  if (data.body) {
    formData.append('body', data.body);
  }
  if (data.dynamic_fields && data.dynamic_fields.length > 0) {
    formData.append('dynamic_fields', JSON.stringify(data.dynamic_fields));
  }

  if (files && files.length > 0) {
    files.forEach((file: any) => {
      formData.append("files", file);
    });
  }
  return await axios.POST(url, formData, {auth: true, formData: true}).catch();
}

export const updateClientTask = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.client.updateClientTask;
  return await axios.PUT(url, data, {auth: true}).catch();
}

export const boxesFileClient = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.client.boxesFileClient;
  return await axios.POST(url, data, {auth: true}).catch();
}

export const deleteDocument = async (data: CLIENT.DeleteDocumentParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.deleteDocument;
  return await axios.DELETE(url, data, {auth: true}).catch();
}

export const deleteInsuranceMountainDocument = async (data: CLIENT.DeleteDocumentParam) => {
  let url = baseService.baseUrl + baseService.endpoints.client.deleteInsuranceMountainDocument;
  return await axios.DELETE(url, data, {auth: true}).catch();
}

export const createClient = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.client.create;
  return await axios.POST(url, data, {auth: true}).catch();
}

export const updateClient = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.client.update;
  return await axios.PUT(url, data, {auth: true}).catch();
}

export const updateClientDetailsProductsElementaryOwner = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.client.updateClientDetailsProductsElementaryOwner;
  return await axios.PUT(url, data, {auth: true}).catch();
}

export const uploadZipFile = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.client.file.uploadFile;
  const formData = new FormData();
  formData.append("files", data.files[0]);
  formData.append("agent_id", data.agent_id || '');
  return await axios.POST(url, formData, {auth: true, formData: true}).catch();
}

export const uploadInsuranceMountainFile = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.client.file.uploadInsuranceMountainFile;
  const formData = new FormData();
  formData.append("files", data.files[0]);
  formData.append("agent_id", data.agent_id || '');
  return await axios.POST(url, formData, {auth: true, formData: true}).catch();
}

export const uploadPolicyFile = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.client.file.uploadPolicyFile;
  const formData = new FormData();
  formData.append("files", data.file);
  formData.append("policy_number", data.policy_number);
  formData.append("clientId", data.clientId);
  return await axios.POST(url, formData, {auth: true, formData: true}).catch();
}
