import React from 'react';
import {Alert} from "antd";

type options = 'success' | 'info' | 'warning' | 'error';

interface Props {
  type: options;
  showIcon?: boolean;
  message: string;
}

const AlertMessage: React.FC<Props> = (props) => {
  return (<Alert message={props.message} type={props.type} showIcon={props.showIcon}/>);
};

export default AlertMessage;
