import React, {Key} from 'react';
import {SpinProps, Table as TableAnt, TablePaginationConfig} from "antd";
import {ColumnsType, ColumnType} from "antd/es/table";
import {ExpandableConfig, GetComponentProps} from "rc-table/lib/interface";
import {TableRowSelection} from "antd/es/table/interface";

interface Props {
  dataSource?: readonly any[] | undefined;
  columns?: ColumnsType<any> | undefined;
  pagination?: false | TablePaginationConfig | undefined;
  bordered?: boolean;
  size?: 'small' | 'middle' | 'large';
  scroll?: ({x?: string | number | true | undefined, y?: string | number | undefined} & {scrollToFirstRowOnChange?: boolean | undefined});
  style?: React.CSSProperties;
  className?: string;
  rowKey?: string;
  key?: Key;
  rowClassName?: (record: any, index: number, indent: number) => string;
  expandable?: ExpandableConfig<any>;
  onHeaderRow?: GetComponentProps<readonly ColumnType<any>[]>;
  showHeader?: boolean;
  loading?: boolean | SpinProps;
  rowSelection?: TableRowSelection<any>;
  onChange?: () => void;
}

const Table: React.FC<Props> = (props) => {
  return (
    <TableAnt
      key={props.key}
      rowKey={props.rowKey}
      style={props.style}
      className={props.className}
      loading={props.loading}
      rowClassName={props.rowClassName}
      rowSelection={props.rowSelection}
      expandable={props.expandable}
      showHeader={props.showHeader}
      onHeaderRow={props.onHeaderRow}
      columns={props.columns}
      dataSource={props.dataSource}
      bordered={props.bordered}
      size={props.size}
      scroll={props.scroll}
      pagination={props.pagination ? props.pagination : false}
    />
  );
};

export default Table;
