import React, {Component} from 'react';
import {
    Button,
    Col,
    Label,
    Modal, ModalBody, ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";

//i18n
import {withNamespaces} from 'react-i18next';
import moment from "moment";

class ViewEmployerModal extends Component {

    render() {
        let createByType = ''
        switch (this.props.employerDataModal.createByType) {
            case "manual":
                createByType = this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.SYSTEM.TYPE.MANUAL");
                break;
            case "file":
                createByType = this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.SYSTEM.TYPE.FILE");
                break;
            default:
                createByType = '';
                break;
        }
        let dateCreate = moment(this.props.employerDataModal.createdAt).format('HH:mm DD/MM/YYYY')
        let lastUpdate = moment(this.props.employerDataModal.updatedAt).format('HH:mm DD/MM/YYYY')

        return (
            <Modal
                isOpen={this.props.employerViewModalShow}
                toggle={this.tog_static}
                backdrop="static"
                centered
                size="lg"
            >
                <ModalHeader toggle={() => this.props.closeViewEmployerModal()}>
                    {this.props.t("ADMIRING_FACTOR.EMPLOYERS.LIST.TABLE.VIEW_MODAL.TITLE")}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="pt-4">
                            <Label
                                className="text-secondary">{this.props.t("COMPANIES_PAGES.COMPANY_MODAL.GENERAL")}</Label>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.TYPE")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal .text-gray">{this.props.employerDataModal.idType === 1 ? this.props.t("COMPANIES_PAGES.ID") : this.props.t("COMPANIES_PAGES.BN")} </Label>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.ID_NUMBER")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal text-gray">{this.props.employerDataModal.idNumber}</Label>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.NAME")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal text-gray">{this.props.employerDataModal.name}</Label>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="pt-4">
                            <Label
                                className="text-secondary">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.CONTACT_TITLE")}</Label>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_FIRST_NAME")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal text-gray">{this.props.employerDataModal.contact.firstName || '--'}</Label>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_LAST_NAME")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal text-gray">{this.props.employerDataModal.contact.lastName || '--'}</Label>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_MOBILE")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal text-gray">{this.props.employerDataModal.contact.mobile || '--'}</Label>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_PHONE")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal text-gray">{this.props.employerDataModal.contact.phone || '--'}</Label>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_EMAIL")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal text-gray">{this.props.employerDataModal.contact.email || '--'}</Label>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.ADDRESS")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal text-gray">{this.props.employerDataModal.contact.address || '--'}</Label>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CITY")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal text-gray">{this.props.employerDataModal.contact.city || '--'}</Label>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.ZIP_CODE")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal text-gray">{this.props.employerDataModal.contact.zipCode || '--'}</Label>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="pt-4">
                            <Label
                                className="text-secondary">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.SYSTEM.TITLE")}</Label>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.SYSTEM.DATE_CREATE")}</Label>
                            </div>
                            <div><Label className="font-weight-normal text-gray">{dateCreate}</Label></div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.SYSTEM.CREATED_BY_USER")}</Label>
                            </div>
                            <div><Label
                                className="font-weight-normal text-gray">{this.props.employerDataModal.user.firstName + " " + this.props.employerDataModal.user.lastName}</Label>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.SYSTEM.CREATED_BY")}</Label>
                            </div>
                            <div><Label className="font-weight-normal text-gray">{createByType}</Label></div>
                        </Col>
                        <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                            <div><Label
                                className="font-weight-bold">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.SYSTEM.LAST_UPDATE")}</Label>
                            </div>
                            <div><Label className="font-weight-normal text-gray">{lastUpdate}</Label></div>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="primary"
                            onClick={() => this.props.closeViewEmployerModal()}>{this.props.t("BUTTON.CLOSE")}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withNamespaces()(ViewEmployerModal);
