import io from 'socket.io-client';

let socket: any;

const initiate = (userId: string) => {
  socket = io('https://api.clickagentapp.com');//"https://localhost";' https://click-agent.com);
  socket.emit("joinUser", { userId });
}
const disconnect = () => {
  if(socket) socket.disconnect();
}

const subscribeToInsertPhoneCodeMountainInsurance = (cb: (err: any, data?: any) => void) => {
  if (!socket) return cb({'ERROR': 'socket undefined'});
  socket.on('insertPhoneCodeMountainInsurance', () => {
    return cb(null);
  });
}

const SocketIO = {
  initiate, disconnect, subscribeToInsertPhoneCodeMountainInsurance}
export default SocketIO;
