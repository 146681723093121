import React from 'react';
import TasksTable from "../../../tables/Tasks";
import {getLoggedInUserId} from "../../../../services/auth";

interface Props {
  reload: boolean;
  onReloaded: () => void;
}

const Tasks: React.FC<Props> = (props) => {

  const getClientId = () => {
    const params = new URLSearchParams(window.location.search);
    const url = window.location.href.split('?');
    const cid = params.get('cid');
    // agent
    if (url.length > 0 && url[0].endsWith('client-details'))
      if (cid)
        return cid;
      else
        // client
      if (window.location.href.endsWith('client/details'))
        return getLoggedInUserId();

    return '';
  }

  return (<TasksTable reload={props.reload} onReloaded={props.onReloaded} clientId={getClientId()}/>);
};

export default Tasks;
