import React from 'react';
import {Result as ResultAnt} from "antd";
import {ResultStatusType} from "antd/es/result";

interface Props {
  status?: ResultStatusType;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  extra?: React.ReactNode;
}

const Result: React.FC<Props> = (props) => {
  return (
    <ResultAnt
      status={props.status}
      title={props.title}
      subTitle={props.subTitle}
      extra={props.extra}
    />
  );
};

export default Result;
