import baseService from "../base";
import axios from "../axios";

export const getBanks = async () => {
  let url = baseService.baseUrl + baseService.endpoints.banks.getAll;
  return await axios.GET(url, {auth: true}).catch();
}

export const getBranchs = async (data: BANK.BranchsParams) => {
  let url = baseService.baseUrl + baseService.endpoints.banks.branchs;
  url = url.replace(':bankCode', "bankCode=" + data.bankCode)
  return await axios.GET(url, {auth: true}).catch();
}
