export const statuses = [
  {
    label: "ממתין לתפעול",
    value: 1
  },
  {
    label: "הושלמה",
    value: 2
  },
  {
    label: "ממתין לחתימת לקוח/ה",
    value: 3
  },
  {
    label: "ממתין להשלמות לקוח/ה",
    value: 4
  },
  {
    label: "ממתין לחתימת סוכן/ת",
    value: 5
  },
  {
    label: "ממתין לעדכון מעסיק",
    value: 6
  },
  {
    label: "ממתין לבירור מול סוכן/ת",
    value: 7
  },
  {
    label: "מממתין לבירור מול היצרנית",
    value: 8
  },
  {
    label: "ממתין להפקדה ראשונה",
    value: 9
  },
  {
    label: "ממתין לשיוך",
    value: 10
  },
  {
    label: "ממתין לתנאי חיתום",
    value: 11
  },
  {
    label: "ממתין להשלמת חוסרים",
    value: 12
  },
  {
    label: "ממתין ליצרנית",
    value: 13
  },
  {
    label: "נשלח ליצרנית",
    value: 14
  },
  {
    label: "בוטלה / נגנזה",
    value: 15
  }
]
