import React from 'react';
import {Col, Row, Typography} from "antd";

const {Text} = Typography;

type options = 'error';

interface Props {
  type: options
}

const Result: React.FC<Props> = (props) => {
  return (
    <Row>
      <Col lg={24} md={24} sm={24} xs={24} className='text-center'>
        {
          props.type === 'error' &&
            <>
                <div className="mb-4">
                    <i className="mdi mdi-close-circle-outline text-danger display-4"/>
                </div>
                <div><Text>קישור לא תקין</Text></div>
                <div><Text>פנה לסוכן לקבלת קישור חדש</Text></div>
            </>
        }
      </Col>
    </Row>
  );
};

export default Result;