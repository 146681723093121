import React, {useEffect, useState} from 'react';
import {Col, Form, Input, InputNumber, Modal, Row, Select, Typography} from "antd";
import Button from "../../../../antDesign/Button";
import {ColumnsType} from "antd/es/table";
import Table from "../../../../antDesign/Table";
import {boxesFileClient} from "../../../../../services/client";
import file from "../../../../../helpers/file";
import {manufacturersOptions} from "../../../../../services/manufacturer";
import '../../../../../assets/scss/components/boxes.scss';
import Messages from "../../../../antDesign/Message";
import Result from "../../../../antDesign/Result";
import {DownloadOutlined} from "@ant-design/icons";
import PATTERNS from "../../../../../helpers/Patterns";

const {Text} = Typography;

interface Props {
  visible: boolean;
  client: any;
  onClose: () => void;
  onOk: () => void;
}

const Boxes: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  const [manufacturers, setManufacturers] = useState<GLOBAL.OPTION[]>([]);
  const [created, setCreated] = useState<boolean>(false);
  const [boxBuffer, setBoxBuffer] = useState<string | undefined>(undefined);

  const handle = async (values: any) => {
    values.clientId = props.client._id;
    console.log(values)
    setCreated(false);
    await boxesFileClient(values).then(response => {
      if (response.status === 'SUCCESS') {
        switch (values.action) {
          case 'VIEW':
            file.view('application/pdf', response.buffer);
            break;
          case 'CREATE':
            setCreated(true);
            setBoxBuffer(response.buffer);
            break;
        }

      }
    }).catch(error => {
      Messages.Error(error);
    });
  }

  const manufacturersOptionsList = () => {
    manufacturersOptions().then(response => {
      if (response.status === 'SUCCESS') {
        setManufacturers(response.options.map((option: GLOBAL.OPTION) => {
          return {
            label: option.label,
            value: option.label,
          }
        }))
      }
    })
  }

  const resetValues = () => {
    setCreated(false);
    setBoxBuffer(undefined);
    form.resetFields();
  }

  const handleAfterClose = () => {
    resetValues()
  }

  const columns: ColumnsType = [
    {
      title: 'פרטי המוצר הפיננסי',
      children: [
        {
          title: 'שם קופת גמל',
          dataIndex: 'name',
          key: 'name',
          render: (_: any, record: any) => (
            <Form.Item
              className='boxes-table-form-item'
              name={['boxes', record.key, 'name']}>
              <Select style={{width: 150}} allowClear showSearch>
                {manufacturers.map((manufacturer, index) => (
                  <Select.Option key={index} value={manufacturer.value}>{manufacturer.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          ),
          width: 150,
        },
        {
          title: 'סוג מוצר פנסיוני',
          dataIndex: 'type',
          key: 'type',
          render: (_: any, record: any) => (
            <Form.Item
              className='boxes-table-form-item'
              name={['boxes', record.key, 'type']}>
              <Input/>
            </Form.Item>
          ),
          width: 150,
        },
        {
          title: 'מס׳ קופה',
          dataIndex: 'kupa_number',
          key: 'kupa_number',
          render: (_: any, record: any) => (
            <Form.Item
              className='boxes-table-form-item'
              name={['boxes', record.key, 'kupa_number']}>
              <InputNumber min={0}/>
            </Form.Item>
          ),
          width: 100,
        },
        {
          title: 'שם החברה המנהלת',
          dataIndex: 'company_name',
          key: 'company_name',
          render: (_: any, record: any) => (
            <Form.Item
              className='boxes-table-form-item'
              name={['boxes', record.key, 'company_name']}>
              <Input/>
            </Form.Item>
          ),
          width: 150,
        },
      ],
    },
    {
      title: 'אופן החלוקה',
      children: [
        {
          title: 'רובד שכר',
          dataIndex: 'roved_salary',
          key: 'roved_salary',
          render: (_: any, record: any) => (
            <Form.Item
              className='boxes-table-form-item'
              name={['boxes', record.key, 'roved_salary']}>
              <InputNumber min={0}/>
            </Form.Item>
          ),
          width: 150,
        },
        {
          title: 'שכר בש״ח / אחוז משכר',
          dataIndex: 'salary_or_percent',
          key: 'salary_or_percent',
          render: (_: any, record: any) => (
            <Form.Item
              className='boxes-table-form-item'
              name={['boxes', record.key, 'salary_or_percent']}>
              <Input/>
            </Form.Item>
          ),
          width: 150,
        },
        {
          title: 'אופן פיצול',
          dataIndex: 'ofen_hpichul',
          key: 'ofen_hpichul',
          render: (_: any, record: any) => (
            <Form.Item
              className='boxes-table-form-item'
              name={['boxes', record.key, 'ofen_hpichul']}>
              <Input/>
            </Form.Item>
          ),
          width: 150,
        },
      ],
    },
    {
      title: 'שיעור הפרשה',
      children: [
        {
          title: 'תגמולים',
          children: [
            {
              title: 'עובד',
              dataIndex: 'tagmulim_employee',
              key: 'tagmulim_employee',
              render: (_: any, record: any) => (
                <Form.Item
                  className='boxes-table-form-item'
                  name={['boxes', record.key, 'tagmulim_employee']}>
                  <InputNumber min={0}/>
                </Form.Item>
              ),
              width: 100,
            },
            {
              title: 'מעסיק',
              dataIndex: 'tagmulim_employer',
              key: 'tagmulim_employer',
              render: (_: any, record: any) => (
                <Form.Item
                  className='boxes-table-form-item'
                  name={['boxes', record.key, 'tagmulim_employer']}>
                  <InputNumber min={0}/>
                </Form.Item>
              ),
              width: 100,
            },
          ],
        },
        {
          title: 'פיצויים',
          children: [
            {
              title: 'פיצויים',
              dataIndex: 'pichuim',
              key: 'pichuim',
              render: (_: any, record: any) => (
                <Form.Item
                  className='boxes-table-form-item'
                  name={['boxes', record.key, 'pichuim']}>
                  <InputNumber min={0}/>
                </Form.Item>
              ),
              width: 100,
            },
          ],
        },
        {
          title: 'אובדן כושר עבודה',
          children: [
            {
              title: 'מעסיק',
              dataIndex: 'ovdan_kosher_avoda_employer',
              key: 'ovdan_kosher_avoda_employer',
              render: (_: any, record: any) => (
                <Form.Item
                  className='boxes-table-form-item'
                  name={['boxes', record.key, 'ovdan_kosher_avoda_employer']}>
                  <InputNumber min={0}/>
                </Form.Item>
              ),
              width: 100,
            },
            {
              title: 'עובד',
              dataIndex: 'ovdan_kosher_avoda_employee',
              key: 'ovdan_kosher_avoda_employee',
              render: (_: any, record: any) => (
                <Form.Item
                  className='boxes-table-form-item'
                  name={['boxes', record.key, 'ovdan_kosher_avoda_employee']}>
                  <InputNumber min={0}/>
                </Form.Item>
              ),
              width: 100,
            },
          ],
        },
        {
          title: 'שונות',
          children: [
            {
              title: 'מעסיק',
              dataIndex: 'shonot_employer',
              key: 'shonot_employer',
              render: (_: any, record: any) => (
                <Form.Item
                  className='boxes-table-form-item'
                  name={['boxes', record.key, 'shonot_employer']}>
                  <InputNumber min={0}/>
                </Form.Item>
              ),
              width: 100,
            },
            {
              title: 'עובד',
              dataIndex: 'shonot_employee',
              key: 'shonot_employee',
              render: (_: any, record: any) => (
                <Form.Item
                  className='boxes-table-form-item'
                  name={['boxes', record.key, 'shonot_employee']}>
                  <InputNumber min={0}/>
                </Form.Item>
              ),
              width: 100,
            },
          ],
        },
      ],
      fixed: 'right',
    },
  ];

  const data = [];
  for (let i = 0; i < 4; i++) {
    data.push({
      key: i,
      name: '',
      type: '',
      kupa_number: '',
      company_name: '',
      roved_salary: '',
      salary_or_percent: '',
      ofen_hpichul: '',
      tagmulim_employee: '',
      tagmulim_employer: '',
      pichuim: '',
      ovdan_kosher_avoda_employer: '',
      ovdan_kosher_avoda_employee: '',
      shonot_employer: '',
      shonot_employee: '',
    });
  }

  useEffect(() => {
    if (props.visible) {
      manufacturersOptionsList();
    }
    return () => {
    };
  }, [props.visible])

  return (
    <Modal
      title="צור קובץ קוביות (ה׳1)"
      open={props.visible}
      afterClose={handleAfterClose}
      onCancel={() => props.onClose()}
      onOk={form.submit}
      maskClosable={false}
      forceRender
      destroyOnClose
      footer={created ? [
        <Button
          key='close'
          onClick={() => props.onClose()}
        >סגור</Button>,
      ] : [
        <Button
          key='close'
          onClick={() => props.onClose()}
        >סגור</Button>,
        <Button
          key='view'
          type='primary'
          className='mx-2'
          onClick={() => {
            form.setFieldsValue({action: 'VIEW'});
            form.submit();
          }}
        >תצוגה מקדימה</Button>,
        <Button
          key='create'
          type='primary'
          onClick={() => {
            form.setFieldsValue({action: 'CREATE'});
            form.submit();
          }}
        >צור</Button>,
      ]}
      width={created ? 700 : 1200}>
      <div id='boxes-modal'>

        {!created &&
            <Form form={form}
                  autoComplete='off'
                  layout="vertical"
                  onFinish={(values) => {
                    handle(values).then(() => {
                    });
                  }}>

                <Form.Item
                    hidden
                    initialValue='create'
                    name='action'>
                    <Input hidden/>
                </Form.Item>

                <Row gutter={[16, 16]}>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className="my-3">
                        <Text strong type="warning">פרטי מעסיק</Text>
                    </Col>

                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Form.Item
                            label="שם"
                            rules={[
                              {required: true, message: "שדה חובה"},
                              {max: 50, message: "מקסימום 50 תווים"},
                            ]}
                            name='company_name'>
                            <Input placeholder="שם"/>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Form.Item
                            label="ת.ז"
                            rules={[
                              {pattern: PATTERNS.NUMBER_ID, message: "ת.ז צריכה להכיל 9 ספרות"}]}
                            name='employer_id_number'>
                            <Input placeholder="ת.ז"/>
                        </Form.Item>
                    </Col>

                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className="my-3">
                        <Text strong type="warning">איש קשר</Text>
                    </Col>

                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Form.Item
                            label="שם"
                            rules={[
                              {required: true, message: "שדה חובה"},
                              {max: 10, message: "מקסימום 10 תווים"},
                            ]}
                            name='contact_name'>
                            <Input placeholder="שם"/>
                        </Form.Item>
                    </Col>

                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Form.Item
                            label="טלפון"
                            rules={[
                              {required: true, message: "שדה חובה"},
                              {pattern: PATTERNS.MOBILE, message: "טלפון לא חוקי"}
                            ]}
                            name='contact_phone'>
                            <Input placeholder="טלפון"/>
                        </Form.Item>
                    </Col>

                </Row>
                <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className="my-3">
                    <Table
                        className='boxes-table mt-5 mb-4'
                        columns={columns}
                        dataSource={data}
                        size="middle"/>
                </Col>

            </Form>
        }

        {created &&
          <>
              <Result
                  status='success'
                  title='קובץ הקוביות (ה׳1) נוצר בהצלחה!'
                  subTitle='ניתן לראות קובץ זה במסמכים של פרטי הלקוח'
                  extra={[<Button
                    key='download'
                    // style={{width: 60}}
                    onClick={() => {
                      if(boxBuffer) {
                        file.download('קובץ קוביות (ה׳1) - ' + props.client.clientId, 'application/pdf', boxBuffer);
                      }
                    }}
                    icon={<DownloadOutlined />}>הורדת המסמך</Button>]}
              />
          </>
        }
      </div>
    </Modal>
  );
};
export default Boxes;
