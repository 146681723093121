import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Radio, Row} from "antd";
import AlertMessage from "../../../../../components/antDesign/Alert";
import {send} from "../../../../../services/conversation";

interface Props {
  client: any;
  beforeSendCode: () => void;
  afterSendCode: (tokenTemporary: string) => void;
  onFailed: () => void;
}

const SendCode: React.FC<Props> = (props) => {

  const [form] = Form.useForm();
  const [verifyType, setVerifyType] = useState<string>('EMAIL');

  const handleSendCode = (values: any) => {
    const body = {
      ...values,
      client_id: props.client._id,
      agent_id: props.client.agent._id,
      company_id: props.client.agent.companyId
    }
    send(body).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        props.afterSendCode(response.token)
      } else {
        props.onFailed();
      }
    }).catch(() => {
      props.onFailed();
    })
  }

  useEffect(() => {
    if (props.client) {
      form.setFieldsValue({
        email: props.client.email,
        phone: props.client.phone,
      })
    }
  }, [props.client])

  return (
    <Row>
      <Col lg={24} md={24} sm={24} xs={24}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSendCode}>

          <Row className='text-left'>

            {
              ((verifyType === 'EMAIL' && props.client && Object.keys(props.client).length > 0 && !props.client.email) ||
                (verifyType === 'SMS' && props.client && Object.keys(props.client).length > 0 && !props.client.phone)) &&
                <Col lg={24} md={24} sm={24} xs={24} className='mb-4'>
                    <AlertMessage
                        type={'error'}
                        message={verifyType === 'EMAIL' && !props.client.email ?
                          'אימייל לא מוגדר במערכת' : 'טלפון נייד לא מוגדר במערכת'}
                        showIcon/>
                </Col>
            }

            {
              ((verifyType === 'EMAIL' && props.client && Object.keys(props.client).length > 0 && props.client.email) ||
                (verifyType === 'SMS' && props.client && Object.keys(props.client).length > 0 && props.client.phone)) &&
                <Col lg={24} md={24} sm={24} xs={24} className='mb-4'>
                    <AlertMessage
                        type={'info'}
                        message={verifyType === 'EMAIL' && props.client.email ?
                          'קוד האימות ישלח לכתובת האימייל' : 'קוד האימות ישלח לטלפון הנייד'}
                        showIcon/>
                </Col>
            }

            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                label="בחר שיטת אימות"
                name="verify_by"
                initialValue='EMAIL'>
                <Radio.Group
                  onChange={(event) => setVerifyType(event.target.value)}>
                  <Radio value="EMAIL">אימייל</Radio>
                  <Radio value="SMS">סמס</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {
              verifyType === 'EMAIL' &&
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        label="אימייל"
                        initialValue={props.client && props.client.email ? props.client.email : ''}
                        name='email'>
                        <Input disabled={true} placeholder="אימייל"/>
                    </Form.Item>
                </Col>
            }

            {
              verifyType === 'SMS' &&
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        label="טלפון נייד"
                        initialValue={props.client && props.client.phone ? props.client.phone : ''}
                        name='phone'>
                        <Input disabled={true} placeholder="טלפון נייד"/>
                    </Form.Item>
                </Col>
            }

            <Col lg={24} md={24} sm={24} xs={24} className='text-center'>
              <Form.Item>
                <Button
                  type="primary"
                  disabled={(verifyType === 'EMAIL' && !props.client.email) ||
                    (verifyType === 'SMS' && !props.client.phone)}
                  htmlType="submit">
                  שלח קוד אימות
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default SendCode;
