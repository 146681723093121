import React, {Component} from "react";
import {Container} from "reactstrap";

// Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

//i18n
import {withNamespaces} from 'react-i18next';

import {uploadFiles} from "../../../services/admiringFactor/filesService";
import Uploader from "./Uploader";
import LatelyUpload from "./LatelyUpload";

class ImportFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: this.props.t("BREADCRUMB.ADMIRING_FACTOR.FILES.MAIN"), link: "#"},
                {title: this.props.t("BREADCRUMB.ADMIRING_FACTOR.FILES.IMPORT"), link: "#"}
            ],
            showErrorMsg: false,
            errorMsgText: '',
            selectedFiles: []
        };
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
    }

    showErrorMessage = (errMsg) => this.setState({errorMsgText: errMsg, showErrorMsg: true});


    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
                percentage: 0,
                responseMsg: '',
                responseStatus: null
            })
        );

        this.setState({selectedFiles: files});
    };

    removeFile = index => {
        let selectedFiles = this.state.selectedFiles.filter((file, i) => {
            return i !== index;
        });

        this.setState({selectedFiles: selectedFiles.length === 0 ? [] : selectedFiles});
    }

    uploadFiles = async () => {
        const selectedFiles = this.state.selectedFiles;

        this.setState({
            selectedFiles: selectedFiles.map((file) => {
                const items = [25, 50, 75];
                file.percentage = items[Math.floor(Math.random() * items.length)];
                return file;
            })
        });

        try {
            const response = await uploadFiles({files: this.state.selectedFiles});
            this.setState({
                selectedFiles: selectedFiles.map((file) => {
                    file.percentage = 100;

                    const resFileStatus = response.data.filter((res) => {
                        return res.file.originalname === file.path;
                    })[0];
                    file.responseMsg = resFileStatus.data.displayMessage;
                    file.responseStatus = resFileStatus.status;
                    return file;
                })
            });
        } catch (error) {
        }
    }

    clear = () => this.setState({selectedFiles: []});

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title={this.props.t("BREADCRUMB.ADMIRING_FACTOR.FILES.IMPORT")}
                                     breadcrumbItems={this.state.breadcrumbItems}/>

                        <Uploader showErrorMessage={(errMsg) => this.showErrorMessage(errMsg)}/>
                        <LatelyUpload/>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(ImportFiles);
