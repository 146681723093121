import React, {useState} from 'react';
import TasksPage from "../../components/tables/Tasks";
import Breadcrumbs from "../../components/antDesign/Breadcrumb";
import {Button, Card, Col, Row} from "antd";

const BreadcrumbsItems = {
  title: "רשימת המשימות",
  breadcrumbs: [{item: "רשימת המשימות"}, {item: "משימות"}]
}

const Tasks: React.FC = () => {

  const [addEditTaskModalShow, setAddEditTaskModalShow] = useState<boolean>(false);

  return (
    <React.Fragment>
      <div className="page-content">
       <Card>
          <Button type="primary"
                  style={{height: 40}}
                  onClick={() => {
                    setAddEditTaskModalShow(true);
                  }}>
            <i className="mdi mdi-plus mr-2"/>צור משימה
          </Button>
        </Card>

        <TasksPage
          visibleAddEditTaskModal={addEditTaskModalShow}
          filter={true}
          onClose={() => setAddEditTaskModalShow(false)}
        />
      </div>
    </React.Fragment>
  );
};

export default Tasks;
