import axios from '../axios';
import baseService from "../base";
export const getTaskProperties = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.task.getTaskProperties;
  url = url.replace(':type', "type=" + (data && data.type ? data.type : ''))
    .replace(':subtype', "subtype=" + (data && data.subtype ? data.subtype : ''))
  return await axios.GET(url, {auth: true}).catch();
}
export const getProperties = async (data?: any) => {
  let url = baseService.baseUrl + baseService.endpoints.task.getProperties;
  return await axios.GET(url, {auth: true}).catch();
}
export const getPropertiesCompanyOrDefault = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.task.getPropertiesCompanyOrDefault;
  url = url.replace(':company', "company=" + (data && data.company ? data.company : ''));
  return await axios.GET(url, {auth: true}).catch();
}
export const getSubProperties = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.task.getSubProperties;
  url = url.replace(':typeId', "typeId=" + (data && data.typeId ? data.typeId : ''));
  return await axios.GET(url, {auth: true}).catch();
}
export const createProperty = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.task.createProperty;
  return await axios.POST(url, data, {auth: true}).catch();
}
export const updateProperty = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.task.updateProperty;
  return await axios.PUT(url, data, {auth: true}).catch();
}
