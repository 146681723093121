import React, {useEffect, useState} from 'react';
import {Col, Form, Input, InputNumber, Modal, Row, Select, Tag, Typography} from "antd";
import {getCompaniesList} from "../../../services/company";
import {createUser, updateUser} from "../../../services/user";
import Messages from "../../../components/antDesign/Message";
import PATTERNS from "../../../helpers/Patterns";

const {Text} = Typography;

const {Option, OptGroup} = Select;

interface Props {
  visible: boolean;
  isNew: boolean;
  userDataModal: any;
  onClose: (refresh: boolean) => void;
}

const UserModal: React.FC<Props> = (props) => {

  const [form] = Form.useForm();

  const [companies, setCompanies] = useState<any>([]);
  const [type, setType] = useState<string | undefined>(undefined);

  const createBodyRequest = (values: any) => {
    values.type = type;
    return values;
  }

  const handleCreate = (values: any) => {
    values = createBodyRequest(values);
    createUser({user: values}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success('המשתמש נוצר בהצלחה')
        props.onClose(true)
      } else {
        Messages.Error('יצירת משתמש נכשלה');
      }
    }).catch(error => {
      Messages.Error(error.displayMessage).then(() => {});
    });
  }

  const handleUpdate = (values: any) => {
    values = createBodyRequest(values);
    updateUser({user: values}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success('המשתמש עודכן בהצלחה')
        props.onClose(true)
      } else {
        Messages.Error('עדכון משתמש נכשלה')
      }
    }).catch(error => {
      Messages.Error(error.displayMessage).then(() => {});
    });
  }

  const handleAfterClose = () => {
    form.resetFields();
    setType(undefined)
  }

  const getTypeLabel = () => {
    if (props.userDataModal.isAdmin === true || props.userDataModal.type === 'admin') {
      return <Tag color="#f50">אדמין</Tag>
    } else {
      switch (props.userDataModal.type) {
        // case "employee":
        //   return <Tag color="#87d068">עובד</Tag>
        case "agent":
          return <Tag color="#000000">סוכן</Tag>
        // case "admiringFactor":
        //   return <Tag color="#fcb92c">גורם מתפעל</Tag>
        case "admin-company":
          return <Tag color="#fcb92c">אדמין חברה</Tag>
        case "treatment":
          return <Tag color="#87d068">גורם מטפל</Tag>
      }
    }
    return <div></div>
  }

  const onModalOpen = () => {
    if (props.isNew)
      getCompaniesList().then(response => {
        setCompanies(response.list.map((company: any) => {
          return {
            label: company.name,
            value: company._id,
          }
        }))
      })
    setType(props.userDataModal.type);
  }

  useEffect(() => {
    if (props.visible) {
      onModalOpen();
    }

  }, [props.visible])

  return (
    <Modal
      title={props.isNew ? "הוספת משתמש" : "עריכת משתמש"}
      open={props.visible}
      afterClose={handleAfterClose}
      onCancel={() => props.onClose(false)}
      onOk={form.submit}
      maskClosable={false}
      destroyOnClose
      okText={props.isNew ? "הוסף" : "שמור"}
      cancelText="ביטול"
      width={1000}>

      <Form form={form}
            onValuesChange={(values) => {
              if (values.type)
                setType(values.type);
            }}
            layout="vertical"
            onFinish={props.isNew ? handleCreate : handleUpdate}>

        <Form.Item
          name='id'
          initialValue={props.userDataModal && props.userDataModal._id ? props.userDataModal._id : ''}
          hidden>
          <Input hidden/>
        </Form.Item>

        <Row gutter={[16, 16]}>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="שם פרטי"
              initialValue={props.userDataModal && props.userDataModal.firstName ? props.userDataModal.firstName : ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='firstName'>
              <Input placeholder="שם פרטי"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="שם משפחה"
              initialValue={props.userDataModal && props.userDataModal.lastName ? props.userDataModal.lastName : ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='lastName'>
              <Input placeholder="שם משפחה"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="ת.ז"
              initialValue={props?.userDataModal?.userId || ''}
              rules={[
                {required: true, message: "שדה חובה"},
                {pattern: PATTERNS.NUMBER_ID, message: "ת.ז צריכה להכיל 9 ספרות"}
              ]}
              name='userId'>
              <Input placeholder="ת.ז"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="טלפון"
              initialValue={props.userDataModal && props.userDataModal.phone ? props.userDataModal.phone : ''}
              rules={[{
                required: true,
                message: "שדה חובה"
              }, {pattern: PATTERNS.MOBILE, message: "טלפון לא חוקי"}]}
              name='phone'>
              <Input placeholder="טלפון"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="אימייל"
              initialValue={props.userDataModal && props.userDataModal.email ? props.userDataModal.email : ''}
              rules={[{required: true, message: "שדה חובה"}, {type: 'email', message: 'אימייל לא תקין'}]}
              name='email'>
              <Input placeholder="אימייל"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>

            {
              !props.isNew &&
                <Form.Item
                    label="סוג משתמש"
                    initialValue={props.userDataModal && props.userDataModal.type ? props.userDataModal.type : ''}
                    name='type'>
                  {getTypeLabel()}
                </Form.Item>
            }

            {
              props.isNew &&
                <Form.Item
                    label="סוג משתמש"
                    initialValue={props.userDataModal && props.userDataModal.type ? props.userDataModal.type : ''}
                    rules={[{required: true, message: "שדה חובה"}]}
                    name='type'>
                    <Select placeholder='בחר'>
                        <OptGroup label="ניהול">
                            <Option value='admin'>אדמין</Option>
                            <Option value='admin-company'>אדמין חברה</Option>
                        </OptGroup>
                        <OptGroup label="משתמשים">
                            <Option value='agent'>סוכן</Option>
                            <Option value='treatment'>גורם מטפל</Option>
                          {/*<Select.Option value='admiringFactor'>גורם מתפעל</Select.Option>*/}
                          {/*<Select.Option value='employee'>עובד</Select.Option>*/}
                        </OptGroup>
                    </Select>
                </Form.Item>
            }
          </Col>

          {type !== 'admin' &&
              <Col lg={8} md={12} sm={12} xs={12}>

                {!props.isNew &&
                    <Form.Item
                        label="חברה"
                        initialValue={props.userDataModal && props.userDataModal.companyId ? props.userDataModal.companyId : ''}
                        name='companyId'>
                        <Text>{props.userDataModal && props.userDataModal.companyName ? props.userDataModal.companyName : ''}</Text>
                    </Form.Item>
                }

                {
                  props.isNew &&
                    <Form.Item
                        label="חברה"
                        initialValue={props.userDataModal && props.userDataModal.companyId ? props.userDataModal.companyId : ''}
                        rules={[{required: true, message: "שדה חובה"}]}
                        name='companyId'>


                        <Select placeholder='בחר'>
                          {
                            companies.map((company: any) => <Select.Option key={company.value}
                                                                           value={company.value}>{company.label}</Select.Option>)
                          }
                        </Select>

                    </Form.Item>
                }
              </Col>
          }
        </Row>

        {
          type === 'agent' &&
            <Row gutter={[16, 16]}>
                <Col lg={24} md={24} sm={24} xs={24} className="mt-4 mb-3">
                    <Text strong type="warning">פרטי סוכן</Text>
                </Col>

                <Col lg={8} md={12} sm={12} xs={12}>
                    <Form.Item
                        label="רשיון סוכן"
                        initialValue={props.userDataModal && props.userDataModal.agentLicense ? props.userDataModal.agentLicense : ''}
                        rules={[{required: true, message: "שדה חובה"}]}
                        name='agentLicense'>
                        <InputNumber min={0} placeholder="רשיון סוכן"/>
                    </Form.Item>
                </Col>
            </Row>
        }

      </Form>
    </Modal>
  );
};

export default UserModal;
