import React, {useRef, useState} from 'react';
import Breadcrumbs from "../../antDesign/Breadcrumb";
import {Col, Row, Tooltip, Card, Button, MenuProps, Dropdown, Space} from "antd";
import {ActionType, ProColumns} from "@ant-design/pro-components";
import {getTreatmentClientsList} from "../../../services/client";
import {getClientsList} from "../../../services/client";
import {
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileOutlined,
  FolderOpenOutlined,
  CodeSandboxOutlined, UploadOutlined, MoreOutlined, CloseOutlined
} from "@ant-design/icons";
import ViewClientModal from "../ViewClientModal";
import DocumentsSignatureModal from "../DocumentsSignatureModal";
import AddEditClientModal from "../AddEditClientModal";
import {getLoggedInUser, getUserId} from "../../../services/auth";
import moment from "moment";
import InsuranceMountainUploader from "./component/insuranceMountainUploader";
import MislakaUploader from "./component/MislakaUploader";
import ConnectMountainInsurance from "../components/modals/connectMountainInsurance";
import Table from "../../antDesign/ProTable";
import Boxes from "../components/modals/boxes";
import {useHistory} from "react-router-dom";
import Messages from 'src/components/antDesign/Message';

const BreadcrumbsItems = {
  title: "רשימת לקוחות",
  breadcrumbs: [{item: "רשימת לקוחות"}, {item: "לקוחות"}]
}

const InsuranceMountain: React.FC = () => {

  const [userViewModalShow, setUserViewModalShow] = useState<boolean>(false);
  const [boxesModalShow, setBoxesModalShow] = useState<boolean>(false);
  const [addEditClientModalShow, setAddEditClientModalShow] = useState<boolean>(false);
  const [sendDocumentsModalShow, setSendDocumentsModalShow] = useState<boolean>(false);
  const [connectMountainInsuranceModalShow, setConnectMountainInsuranceModalShow] = useState<boolean>(false);
  const [isNew, setNew] = useState<boolean>(false);
  const [uploaderIndexSelected, setUploaderIndexSelected] = useState<number>(0);
  const [clientDataModal, setClientDataModal] = useState<any>({});
  const history = useHistory();
  const actionRef = useRef<ActionType>();

  const columnsAgent: ProColumns[] = [
    {
      align: 'right',
      sorter: (a: any, b: any) => a.clientId ? a.clientId.localeCompare(b.clientId) : false,
      title: "תעודת זהות",
      fieldProps: {
        placeholder: 'תעודת זהות'
      },
      dataIndex: 'clientId',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.firstName ? a.firstName.localeCompare(b.firstName) : false,
      title: "שם פרטי",
      fieldProps: {
        placeholder: 'שם פרטי'
      },
      dataIndex: 'firstName',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.lastName ? a.lastName.localeCompare(b.lastName) : false,
      title: "שם משפחה",
      fieldProps: {
        placeholder: 'שם משפחה'
      },
      dataIndex: 'lastName',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.phone ? a.phone.localeCompare(b.phone) : false,
      title: "טלפון",
      fieldProps: {
        placeholder: 'טלפון'
      },
      dataIndex: 'phone',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.note ? a.note.localeCompare(b.note) : false,
      title: "הערות",
      fieldProps: {
        placeholder: 'הערות'
      },
      dataIndex: 'note',
    }, {
      align: 'right',
      title: "פעולות",
      dataIndex: 'actions',
      render: (_: any, record: any) => [
        <div key={record._id}>
          <Dropdown arrow placement='bottomRight' menu={{ items: [
             /* {
                key: '1',
                label: (
                  <p
                    style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                    onClick={() => {
                      history.push('/client-details?cid=' + record._id);
                    }}>
                    <Space>
                      <FolderOpenOutlined/>
                      תיק לקוח
                    </Space>
                  </p>
                ),
              },*/
              {
                key: '2',
                label: (
                  <p
                    style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                    onClick={() => {
                      setUserViewModalShow(true);
                      setClientDataModal(record)
                    }}>
                    <Space>
                      <EyeOutlined/>
                      צפייה
                    </Space>
                  </p>
                ),
              },
              {
                key: '3',
                label: (
                  <p
                    style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                    onClick={() => {
                      setAddEditClientModalShow(true);
                      setNew(false);
                      setClientDataModal(record);
                    }}>
                    <Space>
                      <EditOutlined/>
                      עריכה
                    </Space>
                  </p>
                ),
              },
              /*{
                key: '4',
                label: (
                  <p
                    style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                    onClick={() => {
                      setSendDocumentsModalShow(true);
                      setClientDataModal(record);
                    }}>
                    <Space>
                      <FileOutlined/>
                      חתימת מסמכים
                    </Space>
                  </p>
                ),
              },*/
             /* {
                key: '5',
                label: (
                  <p
                    style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                    onClick={() => {
                      setBoxesModalShow(true);
                      setClientDataModal(record);
                    }}>
                    <Space>
                      <CodeSandboxOutlined/>
                      צור קובץ קוביות (ה׳1)
                    </Space>
                  </p>
                ),
              },
              {
                key: '6',
                label: (
                  <p
                    style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                    onClick={() => {
                      setConnectMountainInsuranceModalShow(true);
                      setClientDataModal(record);
                    }}>
                    <Space>
                      <DownloadOutlined/>
                      קבלת מידע ביטוחי - הר הביטוח
                    </Space>
                  </p>
                ),
              },*/
            ] }} trigger={['click']}>
            <Button
              type='link'>
              <MoreOutlined/>
            </Button>
          </Dropdown>
        </div>
      ]
    }
  ];

  const columnsTreatment: ProColumns[] = [
    {
      align: 'right',
      sorter: (a: any, b: any) => a.clientId ? a.clientId.localeCompare(b.clientId) : false,
      title: "תעודת זהות",
      fieldProps: {
        placeholder: 'תעודת זהות'
      },
      dataIndex: 'clientId',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.firstName ? a.firstName.localeCompare(b.firstName) : false,
      title: "שם פרטי",
      fieldProps: {
        placeholder: 'שם פרטי'
      },
      dataIndex: 'firstName',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.lastName ? a.lastName.localeCompare(b.lastName) : false,
      title: "שם משפחה",
      fieldProps: {
        placeholder: 'שם משפחה'
      },
      dataIndex: 'lastName',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.phone ? a.phone.localeCompare(b.phone) : false,
      title: "טלפון",
      fieldProps: {
        placeholder: 'טלפון'
      },
      dataIndex: 'phone',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.note ? a.note.localeCompare(b.note) : false,
      title: "הערות",
      fieldProps: {
        placeholder: 'הערות'
      },
      dataIndex: 'note',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.agent_name ? a.agent_name.localeCompare(b.agent_name) : false,
      title: "סוכן",
      fieldProps: {
        placeholder: 'סוכן'
      },
      dataIndex: 'agent_name',
    }, {
      align: 'right',
      title: "פעולות",
      dataIndex: 'actions',
      render: (_: any, record: any) => [
        <div key={record._id}>
          <Tooltip title='עריכה'>
            <Button
              type='link'
              onClick={() => {
                setAddEditClientModalShow(true);
                setNew(false);
                setClientDataModal(record);
              }}>
              <EditOutlined/>
            </Button>
          </Tooltip>

          <Tooltip title='צפייה'>
            <Button
              type='link'
              onClick={() => {
                setUserViewModalShow(true);
                setClientDataModal(record)
              }}>
              <EyeOutlined/>
            </Button>
          </Tooltip>

          <Tooltip title='תיק לקוח'>
            <Button
              type='link'
              onClick={() => {
                history.push('/client-details?cid=' + record._id);
              }}>
              <FolderOpenOutlined/>
            </Button>
          </Tooltip>

          <Tooltip title='חתימת מסמכים'>
            <Button
              type='link'
              onClick={() => {
                setSendDocumentsModalShow(true);
                setClientDataModal(record);
              }}>
              <FileOutlined/>
            </Button>
          </Tooltip>
        </div>
      ]
    }
  ];

  const loadDataTable = async (params: any) => {
    if (getLoggedInUser().type === 'treatment') {
      return await getTreatmentClientsList(params).then((response) => {
        const dataTable = response.list.map((user: any) => {
          return {
            _id: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone,
            agent: user.agent,
            agent_name: user.agent.firstName + " " + user.agent.lastName,
            telephone: user.telephone,
            products: user.products,
            postalCode: user.postalCode,
            note: user.note,
            houseNumber: user.houseNumber,
            country: user.country,
            clientId: user.clientId,
            city: user.city,
            issueIdDate: user.issueIdDate ? moment(user.issueIdDate) : '',
            dateOfBirth: user.dateOfBirth ? moment(user.dateOfBirth) : '',
            appendixDocuments: user.appendixDocuments,
            createdBy: user.createdBy,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
          }
        })
        return {
          status: response.status,
          current: response.current,
          total: response.total,
          pageSize: response.pageSize,
          data: dataTable,
        };
      })
    } else {
      return await getClientsList(params).then((response) => {
debugger
        const dataTable = response.list.map((user: any) => {
          return {
            _id: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone,
            telephone: user.telephone,
            products: user.products,
            postalCode: user.postalCode,
            note: user.note,
            houseNumber: user.houseNumber,
            country: user.country,
            clientId: user.clientId,
            city: user.city,
            issueIdDate: user.issueIdDate ? moment(user.issueIdDate) : '',
            dateOfBirth: user.dateOfBirth ? moment(user.dateOfBirth) : '',
            appendixDocuments: user.appendixDocuments,
            createdBy: user.createdBy,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
          }
        })
        return {
          status: response.status,
          current: response.current,
          total: response.total,
          pageSize: response.pageSize,
          data: dataTable,
        };
      })
    }
  }

  const closeViewUserModal = () => {
    setUserViewModalShow(false);
    setNew(false);
  }

  const closeAddEditClientModal = (refresh: boolean) => {
    if (refresh) {
      actionRef.current?.reload();
    }

    setAddEditClientModalShow(false);
    setNew(false);
  }

  const itemsUpload: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <p
          style={uploaderIndexSelected === 1 ?
            {cursor: "pointer", color: "#10bb69", marginBottom: 0, paddingBottom: 5} :
            {cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
          onClick={() => {
            Messages.Success('בקרוב!!!!')
           /* setUploaderIndexSelected(1)*/
          }}>
          קובץ מסלקה
        </p>
      ),
    },
    {
      key: '2',
      label: (
        <p
          style={uploaderIndexSelected === 2 ?
            {cursor: "pointer", color: "#10bb69", marginBottom: 0, paddingBottom: 5} :
            {cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
          onClick={() => setUploaderIndexSelected(2)}>
          קובץ הר הביטוח
        </p>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title={BreadcrumbsItems.title} breadcrumbs={BreadcrumbsItems.breadcrumbs}/>

        <Row className='mb-4'>
          <Col xs={24}>
            <Card>
              <Space>
                <Button type="primary"
                        size='large'
                        onClick={() => {
                          setAddEditClientModalShow(true);
                          setNew(true);
                          setClientDataModal({})
                        }}>
                  <i className="mdi mdi-plus mr-2"/>צור לקוח
                </Button>

                <Dropdown menu={{ items: itemsUpload }} trigger={['click']} placement="bottom" arrow>
                  <Button type="primary" size='large' icon={<UploadOutlined />}>העלאת קובץ</Button>
                </Dropdown>
              </Space>
            </Card>
          </Col>
        </Row>

        {(uploaderIndexSelected === 1 || uploaderIndexSelected === 2) &&
            <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Card>
                        <Button type='link' size='large' icon={<CloseOutlined/>} onClick={() => setUploaderIndexSelected(0)}/>
                      {uploaderIndexSelected === 1 &&
                          <MislakaUploader
                              accept='.zip'
                              maxCount={1}
                              onUploaded={() => {
                                actionRef.current?.reload()
                              }}/>
                      }

                      {uploaderIndexSelected === 2 &&
                          <InsuranceMountainUploader
                            accept='.xlsx'
                            maxCount={1}
                            onUploaded={() => {
                            actionRef.current?.reload()
                          }}/>
                      }
                    </Card>
                </Col>
            </Row>
        }

        <Row className='mt-4'>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Table
              actionRef={actionRef}
              request={loadDataTable}
              columns={getLoggedInUser().type === 'treatment' ? columnsTreatment : columnsAgent}
              showPagination
            />
          </Col>
        </Row>

        <ViewClientModal visible={userViewModalShow} clientDataModal={clientDataModal}
                         onClose={() => closeViewUserModal()}/>
        <AddEditClientModal visible={addEditClientModalShow}
                            clientDataModal={clientDataModal} isNew={isNew}
                            onClose={(refresh: boolean) => closeAddEditClientModal(refresh)}/>
        <DocumentsSignatureModal
          client={clientDataModal}
          visible={sendDocumentsModalShow}
          onClose={() => setSendDocumentsModalShow(false)}/>

        <ConnectMountainInsurance
          client={{
            firstName: clientDataModal?.firstName || '',
            lastName: clientDataModal?.lastName || '',
          }}
          userId={getUserId()}
          visible={connectMountainInsuranceModalShow}
          onClose={() => {setConnectMountainInsuranceModalShow(false)}}/>

        <Boxes
          visible={boxesModalShow}
          client={clientDataModal}
          onClose={() => {setBoxesModalShow(false)}}
          onOk={() => {setBoxesModalShow(false)}} />

      </div>
    </React.Fragment>
  );
};

export default InsuranceMountain;
