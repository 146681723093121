import React, {useEffect, useState} from 'react';
import {Typography, Row, Col, Upload, Button, Dropdown} from "antd";
import {ColumnsType} from "antd/es/table";
import moment from "moment/moment";
import Tag from "../../antDesign/Tag";
import {
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  MoreOutlined,
  MinusCircleOutlined,
  FilePdfOutlined, PlusOutlined, EyeOutlined, DownloadOutlined, DeleteOutlined
} from "@ant-design/icons";
import Switch from "../../antDesign/Switch";
import {
  deletePolicyDocument,
  getPolicyDocument,
  updateClientDetailsProductsElementaryOwner,
  uploadPolicyFile
} from "../../../services/client";
import Message from "../../antDesign/Message";
import Table from "../../antDesign/Table";
import {List, ListItem} from "../../antDesign/List";
import Card from "../../antDesign/Card";
import Loader from "../../antDesign/Loader";
import {RcFile} from "antd/es/upload";
import Messages from "../../antDesign/Message";
import File from "../../../helpers/file";
import Modal from "../../antDesign/Modal";

const {Text} = Typography;

interface Props {
  isClient: boolean | undefined;
  clientId: string;
  productType: any;
  policies: any;
  reload?: () => void;
}

const PoliciesElementary: React.FC<Props> = (props) => {

  const [dataSource, setDataSource] = useState<any>([])
  const [columns, setColumns] = useState<ColumnsType>([]);
  const [loadingSwitch, setLoadingSwitch] = useState<boolean>(false);
  const [isAllOwner, setAllOwner] = useState<boolean>(false);
  const [isUploadNewPolicyFile, setUploadNewPolicyFile] = useState<boolean>(false);
  const [visibleDeletePolicyDocumentModal, setVisibleDeletePolicyDocumentModal] = useState<boolean>(false);
  const [deletePolicyData, setDeletePolicyData] = useState<any>(undefined);

  const sharedOnCell = (record: any, index: any) => {
    if (record.isTitle) {
      return {colSpan: 0};
    }
    return {};
  };

  const subColumnsElementaryTable: ColumnsType = [
    {
      align: 'right',
      title: "ענף (משני)",
      dataIndex: 'secondary_branch',
      onCell: sharedOnCell,
      key: 'secondary_branch',
    },{
      align: 'right',
      title: "סוג מוצר",
      dataIndex: 'type_product',
      onCell: sharedOnCell,
      key: 'type_product',
    }, {
      align: 'right',
      title: "פרמיה",
      dataIndex: 'premium_in_NIS',
      onCell: sharedOnCell,
      render: (_: any, record: any) => {
        if (record.premium_in_NIS) {
          return <>{record.premium_in_NIS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</>
        }
        return <>0</>
      },
      key: 'premium_in_NIS',
    }, {
      align: 'right',
      title: "סוג פרמיה",
      dataIndex: 'premium_type',
      onCell: sharedOnCell,
      key: 'premium_type',
    }, {
      align: 'right',
      title: "פוליסה",
      dataIndex: 'policy_number',
      onCell: sharedOnCell,
      key: 'policy_number',
    }, {
      align: 'right',
      title: "תחילת הביטוח",
      dataIndex: 'start_policy_date',
      onCell: sharedOnCell,
      render: (_: any, record: any) => {
        return <>{moment(record.start_policy_date).format('DD/MM/YYYY')}</>
      },
      key: 'start_policy_date',
    }, {
      align: 'right',
      title: "סיום הביטוח",
      dataIndex: 'end_policy_date',
      onCell: sharedOnCell,
      render: (_: any, record: any) => {
        return <>{moment(record.end_policy_date).format('DD/MM/YYYY')}</>
      },
      key: 'end_policy_date',
    }, {
      align: 'right',
      title: "סיווג תכנית",
      dataIndex: 'program_classification',
      onCell: sharedOnCell,
      key: 'program_classification',
    }
  ];

  const updateProductsElementaryOwner = (data: { clientId: string; is_owner: boolean; policies_numbers: [string]; }, reload: boolean) => {
    setLoadingSwitch(true);
    updateClientDetailsProductsElementaryOwner(data)
      .then(() => {
        setLoadingSwitch(false);
        if (reload && props.reload) {
          props.reload();
        }
      }).catch((error) => {
      Message.Error(error.displayMessage).then(() => {
      });
      setLoadingSwitch(false);
    })
  }

  const initColumns = () => {
    const columnsElementaryTable: ColumnsType = [
      {
        align: 'right',
        title: "שם יצרן",
        dataIndex: ['manufacturer', 'manufacturer_label'],
        key: 'manufacturer_label',
        onCell: (record, index) => {
          return {
            // @ts-ignore
            colSpan: record.isTitle && !props.isClient ? 11 : record.isTitle && props.isClient ? 11 : 1
          }
        },
      }, {
        align: 'right',
        title: "ענף ראשי",
        dataIndex: 'main_branch',
        onCell: sharedOnCell,
        key: 'main_branch',
      },  {
        align: 'right',
        title: "סוג פרמיה",
        dataIndex: 'premium_type',
        onCell: sharedOnCell,
        key: 'premium_type',
      }, {
        align: 'right',
        title: "פוליסה",
        dataIndex: 'policy_number',
        onCell: sharedOnCell,
        key: 'policy_number',
      }, {
        align: 'right',
        title: "תחילת הביטוח",
        dataIndex: 'start_policy_date',
        onCell: sharedOnCell,
        render: (_: any, record: any) => {
          return <>{moment(record.start_policy_date).format('DD/MM/YYYY')}</>
        },
        key: 'start_policy_date',
      }, {
        align: 'right',
        title: "סיום הביטוח",
        dataIndex: 'end_policy_date',
        onCell: sharedOnCell,
        render: (_: any, record: any) => {
          return <>{moment(record.end_policy_date).format('DD/MM/YYYY')}</>
        },
        key: 'end_policy_date',
      }, {
        align: 'right',
        title: "סיווג תכנית",
        dataIndex: 'program_classification',
        onCell: sharedOnCell,
        key: 'program_classification',
      }, {
        align: 'right',
        title: "סטטוס",
        dataIndex: 'status',
        onCell: sharedOnCell,
        key: 'status_label',
        render: (_: any, record: any) => {
          return <>
            {record.status === 'ADDED' &&
                <Tag icon={<MinusCircleOutlined/>} color="processing">
                  {record.status_label}
                </Tag>
            }

            {record.status === 'SENT' &&
                <Tag icon={<ClockCircleOutlined/>} color="warning">
                  {record.status_label}
                </Tag>
            }

            {record.status === 'RECEIVED' &&
                <Tag icon={<CheckCircleOutlined/>} color="success">
                  {record.status_label}
                </Tag>
            }
          </>
        },
      },
      {
        align: 'right',
        title: (columnTitleProps) => {
          return <>
            {!props.isClient &&
                <Switch
                    loading={loadingSwitch}
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                    onChange={(isSelected) => {
                      const policies_numbers = props.policies.filter((p: any) => p.policy_number !== undefined).map((p: any) => p.policy_number);
                      updateProductsElementaryOwner({clientId: props.clientId, policies_numbers, is_owner: isSelected}, true);
                    }}
                    defaultChecked={isAllOwner}
                />
            }
            <Text style={{marginRight: 10}}>בטיפולי</Text>
          </>
        },
        dataIndex: 'is_owner',
        key: 'is_owner',
        onCell: sharedOnCell,
        render: (checked: any, record: any) => {
          return <Switch
            key={record.key}
            loading={loadingSwitch}
            disabled={props.isClient}
            checkedChildren={<CheckOutlined/>}
            unCheckedChildren={<CloseOutlined/>}
            onChange={(isSelected) => {
              updateProductsElementaryOwner({
                clientId: props.clientId,
                policies_numbers: [record.policy_number],
                is_owner: isSelected
              }, true);
            }}
            checked={checked}
          />
        },
      }
    ];
    setColumns(columnsElementaryTable);
  }

  const loadDataSource = () => {
    setDataSource(props.policies.map((policy: any, index: number) => {
      return {
        key: index,
        manufacturer: {
          manufacturer_label: policy?.company || '-'
        },
        ...policy,
        isTitle: Object.keys(policy).length === 1
      }
    }))
  }

  const isAllPoliciesIsMy = () => {
    let isAllMy = true;
    props.policies.forEach((p: any) => {
      // when is not my and is not title
      if (!p.is_owner && !p.manufacturer) {
        isAllMy = false;
      }
    })
    return isAllMy;
  }

  const uploadPolicy = (data: { clientId: string; policy_number: string; file: RcFile; }) => {
    setUploadNewPolicyFile(true);
    uploadPolicyFile(data).then(response => {
      if (response.status === 'SUCCESS') {
        Messages.Success('המסמך נוצרה בהצלחה').then(() => {
        });
        if (props.reload) {
          props.reload();
        }
      }
      setUploadNewPolicyFile(false);
    }).catch((error) => {
      Messages.Error(error.displayMessage).then(() => {
      });
      setUploadNewPolicyFile(false);
    })
  }

  const getDocument = async (documentId: string) => {
    return await getPolicyDocument(documentId).then(response => {
      if(response.status === 'SUCCESS') {
        return response.document;
      }
      return undefined;
    }).catch((error) => {
      Message.Error(error.displayMessage).then(() => {
      });
      return undefined;
    })
  }

  const viewPolicyDocument = (documentId: string) => {
    getDocument(documentId).then(document => {
      if(document) {
        File.view(document.contentType, document.fileContent);
      }
    })
  }

  const downloadPolicyDocument = (documentId: string) => {
    getDocument(documentId).then(document => {
      if(document) {
        File.download(document.fileName, document.contentType, document.fileContent);
      }
    })
  }

  const deletePolicyDocumentModal = (documentId: string) => {
    getDocument(documentId).then(document => {
      if(document) {
        setDeletePolicyData(document);
        setVisibleDeletePolicyDocumentModal(true);
      }
    })
  }

  const deletePolicy = (documentId: string) => {
    deletePolicyDocument({documentId}).then(response => {
      if(response.status === 'SUCCESS') {
        if(props.reload) {
          props.reload();
        }
      }
      setVisibleDeletePolicyDocumentModal(false);
    }).catch((error) => {
      Message.Error(error.displayMessage).then(() => {
      });
      setVisibleDeletePolicyDocumentModal(false);
    })
  }

  useEffect(() => {
    initColumns();
    return () => {
    };
  }, [props.isClient])

  useEffect(() => {
    setAllOwner(isAllPoliciesIsMy());
    loadDataSource();
    return () => {
    };
  }, [props.policies])

  return (
    <>
      <Table
        rowKey='key'
        columns={columns}
        rowClassName={(record: any) => record.isTitle ? 'title-row-table' : ''}
        expandable={{
          expandedRowRender: (record: any) => {
            return <div id='policies' style={{padding: 25, backgroundColor: '#f8f9fa'}}>
              <div style={{paddingBottom: 10}}>
                <Typography.Text strong type='warning'>פירוט הפוליסה</Typography.Text>
              </div>
              <Table
                rowKey='key'
                className='sub-table'
                columns={subColumnsElementaryTable}
                dataSource={record.policies_details}
                pagination={false}/>

              {!props.isClient &&
                  <div style={{paddingTop: 40}}>
                      <Typography.Text strong type='warning' style={{marginLeft: 5}}>מסמכי הפוליסה</Typography.Text>
                      <List
                          style={{marginTop: 15}}
                          grid={{gutter: 16, column: 10}}
                          dataSource={record.documents}
                          renderItem={(item: any) => (
                            <ListItem style={{textAlign: "center"}}>

                              {item.type === 'add' ? (
                                <Card className='policy-file'>
                                  <div style={{height: 77}}>
                                    <Loader.LoadingContainer style={{height: 77}} loading={isUploadNewPolicyFile}
                                                             tip='מעלה מסמך'>
                                      <>
                                        {!isUploadNewPolicyFile &&
                                            <Upload
                                                maxCount={1}
                                                accept={'application/pdf'}
                                                showUploadList={false}
                                                beforeUpload={(file) => {
                                                  uploadPolicy({
                                                    clientId: props.clientId,
                                                    policy_number: record.policy_number,
                                                    file
                                                  })
                                                  return false;
                                                }}>
                                                <Row>
                                                    <Col xxl={24} xl={24} lg={24} md={24} xs={24} style={{marginTop: 5}}>
                                                        <PlusOutlined style={{fontSize: 45, color: '#10BB6A'}}/>
                                                    </Col>
                                                </Row>
                                                <Row style={{marginTop: 10}}>
                                                    <Col xxl={24} xl={24} lg={24} md={24} xs={24}>
                                                        <Typography.Text style={{fontSize: 12}}>{item.title}</Typography.Text>
                                                    </Col>
                                                </Row>
                                            </Upload>
                                        }
                                      </>
                                    </Loader.LoadingContainer>
                                  </div>
                                </Card>
                              ) : (
                                <Card>
                                  <div style={{height: 77}}>
                                    <Row>
                                      <Dropdown
                                        placement="bottomRight"
                                        dropdownRender={() => (
                                          <Row>
                                            <Col>
                                              <Button
                                                icon={<EyeOutlined />}
                                                onClick={() => {
                                                  console.log(item)
                                                  viewPolicyDocument(item._id)
                                                }}/>
                                              <Button
                                                style={{marginRight: 10, marginLeft: 10}}
                                                icon={<DownloadOutlined />}
                                                onClick={() => {
                                                  downloadPolicyDocument(item._id)
                                                }}/>
                                              <Button
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() => {
                                                  deletePolicyDocumentModal(item._id)
                                                }}/>
                                            </Col>
                                          </Row>)
                                        }>
                                        <Button
                                          style={{fontSize: 20, position: 'absolute', top: 7, right: 7}}
                                          onClick={(e) => e.stopPropagation()}
                                          type='link'
                                          icon={<MoreOutlined style={{fontSize: 20}}/>}>
                                        </Button>
                                      </Dropdown>

                                      <Col xxl={24} xl={24} lg={24} md={24} xs={24} style={{marginTop: 5}}>
                                        <FilePdfOutlined style={{fontSize: 45, color: '#af0000'}}/>
                                      </Col>
                                    </Row>
                                    <Row style={{marginTop: 10}}>
                                      <Col xxl={24} xl={24} lg={24} md={24} xs={24}>
                                        <Typography.Text style={{fontSize: 12}}>{item.fileName}</Typography.Text>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )}

                            </ListItem>
                          )}
                      />
                  </div>
              }

            </div>
          },
          rowExpandable: (record: any) => {
            return !record.isTitle;
          },
        }}
        dataSource={dataSource}
        pagination={false}/>

      <Modal
        open={visibleDeletePolicyDocumentModal}
        title='הסרת מסמך פוליסה'
        okText='הסר'
        onOk={() => deletePolicy(deletePolicyData._id)}
        okButtonProps={{
          danger: true
        }}
        cancelText='ביטול'
        onCancel={() => {
          setVisibleDeletePolicyDocumentModal(false);
        }}>
        <Typography.Text>האם ברצונך להסיר את המסמך - '{deletePolicyData?.fileName}'?</Typography.Text>
      </Modal>
    </>
  );
};

export default PoliciesElementary;
