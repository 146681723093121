import axios from '../axios';
import baseService from "../base";
export const getUsersList = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.getAllUsers;
  url = url.replace(':current', "current=" + (data && data.current ? data.current : ''))
    .replace(':pageSize', "pageSize=" + (data && data.pageSize ? data.pageSize : ''));
  return await axios.GET(url, {auth: true}).catch();
}
export const getUsersDetails = async () => {
  let url = baseService.baseUrl + baseService.endpoints.user.getUserDetails;
  return await axios.GET(url, {auth: true}).catch();
}
export const updateUsersDetails = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.updateUserDetails;
  return await axios.PUT(url, data, {auth: true}).catch();
}
export const uploadLogo = async (file: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.uploadLogo;
  const formData = new FormData();
  formData.append("files", file);
  return await axios.PUT(url, formData, {auth: true, formData: true}).catch();
}
export const getAgentList = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.getAgentByCompany;
  url = url.replace(':current', "current=" + (data && data.current ? data.current : ''))
    .replace(':pageSize', "pageSize=" + (data && data.pageSize ? data.pageSize : ''));
  return await axios.GET(url, {auth: true}).catch();
}
export const getUsersByType = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.getUsersType;
  url = url.replace(':type', "type=" + data.type)
  return await axios.GET(url, {auth: true}).catch();
}
export const getUser = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.getUser;
  url = url.replace(':userId', "userId=" + data.userId)
  return await axios.GET(url, {auth: true}).catch();
}
export const getUserType = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.getUserType;
  url = url.replace(':userId', "userId=" + data.userId)
  return await axios.GET(url).catch();
}
export const createUser = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.create;
  return await axios.POST(url, data, {auth: true}).catch();
}
export const updateUser = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.update;
  return await axios.PUT(url, data, {auth: true}).catch();
}
export const userRegister = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.register;
  return await axios.POST(url, data).catch();
}

export const registrationLinkClient = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.registrationLinkClient;
  return await axios.POST(url, data, {auth: true}).catch();
}
export const connectToPensionInformationFromMountainInsurance = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.getPensionInformationFromMountainInsurance;
  return await axios.POST(url, data, {auth: true}).catch();
}
export const updatePhoneCodeMountainInsurance = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.user.updatePhoneCodeMountainInsurance;
  return await axios.PUT(url, data, {auth: true}).catch();
}
