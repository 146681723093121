import baseService from "../base";
import axios from "../axios";
export const updateClient = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.signature.updateClient;
  return await axios.PUT(url, data).catch();
}

export const createClient = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.signature.createClient;
  return await axios.POST(url, data).catch();
}

export const sign = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.signature.sign;
  return await axios.POST(url, data).catch();
}
