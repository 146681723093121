import React, {useEffect, useState} from 'react';
import {Col, Form, Input, InputNumber, Modal, Row, Select, Typography} from "antd";
import {
  createManufacturerProduct, updateManufacturerProduct,
} from "../../../services/manufacturer";
import Messages from "../../../components/antDesign/Message";
import {getBanks, getBranchs} from "../../../services/bank";

const {Text} = Typography;

interface Props {
  visible: boolean;
  isNew: boolean;
  onClose: (refresh: boolean) => void;
  manufacturerProductDataModal: MANUFACTURER.CreateUpdateManufacturerProductParam;
}

const ManufacturerProductModal: React.FC<Props> = (props) => {

  const [banks, setBanks] = useState<BANK.bankParams[]>([]);
  const [branchs, setBranchs] = useState<BANK.BranchOptionParam[]>([]);
  const [isBankNumberRequired, setBankNumberRequired] = useState<boolean>(false);
  const [isBranchNumberRequired, setBranchNumberRequired] = useState<boolean>(false);
  const [isAccountNumberRequired, setAccountNumberRequired] = useState<boolean>(false);
  const [isBankNumberSelected, setBankNumberSelected] = useState<boolean>(false);
  const [isBranchNumberSelected, setBranchNumberSelected] = useState<boolean>(false);
  const [typeSelected, setTypeSelected] = useState<MANUFACTURER.TypeProduct>(undefined);

  const [form] = Form.useForm();

  const handleCreate = (values: MANUFACTURER.CreateUpdateManufacturerProductParam) => {
    createManufacturerProduct(values).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success('המוצר נוצרה בהצלחה');
        props.onClose(true);
      } else {
        Messages.Error('יצירת המוצר נכשלה');
      }
    }).catch((error) => {
      Messages.Error(error.displayMessage);
    })
  }

  const handleUpdate = (values: MANUFACTURER.CreateUpdateManufacturerProductParam) => {
    updateManufacturerProduct(values).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success('המוצר עודכן בהצלחה');
        props.onClose(true);
      } else {
        Messages.Error('עדכון המוצר נכשל');
      }
    }).catch((error) => {
      Messages.Error(error.displayMessage);
    })
  }

  const resetValues = () => {
    setBankNumberRequired(false);
    setBranchNumberRequired(false);
    setAccountNumberRequired(false);
    setBankNumberSelected(false);
    setBranchNumberSelected(false);
    form.resetFields();
  }

  const handleAfterClose = () => {
    resetValues()
  }

  const onModalOpen = () => {

    if(!props.isNew) {
      if(props.manufacturerProductDataModal?.bank_details?.bank_number) {
        setBankNumberSelected(true);
        setBankNumberRequired(true);
        getBranches(props.manufacturerProductDataModal?.bank_details?.bank_number);
      }

      if(props.manufacturerProductDataModal?.bank_details?.branch_number) {
        setBranchNumberSelected(true);
        setBranchNumberRequired(true);
      }

      if(props.manufacturerProductDataModal?.bank_details?.account_number) {
        setAccountNumberRequired(true);
      }
      setTypeSelected(props.manufacturerProductDataModal?.type);
    }else {
      const defaultType = 'INSURANCE';
      form.setFieldsValue({type: defaultType})
      setTypeSelected(defaultType);
    }
  }

  useEffect(() => {
    if (props.visible) {
      onModalOpen();
    }
    return () => {};
  }, [props.visible])

  useEffect(() => {
    getBanks().then(response => {
      if (response.status.toString() === 'SUCCESS') {
        setBanks(response.banks);
      }
    })
    return () => {};
  }, [])

  const getBranches = (bank: number) => {
    if (bank) {
      const body = {bankCode: bank};
      getBranchs(body)
        .then(response => {
          if (response.status.toString() === 'SUCCESS') {
            setBranchs(response.branchs);
          } else {
            setBranchs([]);
          }
        }).catch(() => {
        setBranchs([]);
      })
    }
  }

  return (
    <Modal
      title={props.isNew ? "הוספת מוצר" : "עריכת מוצר"}
      open={props.visible}
      afterClose={handleAfterClose}
      onCancel={() => props.onClose(false)}
      onOk={form.submit}
      maskClosable={false}
      destroyOnClose
      okText={props.isNew ? "הוסף" : "שמור"}
      cancelText="ביטול"
      width={1000}>

      <Form form={form}
            onValuesChange={(values) => {}}
            layout="vertical"
            onFinish={props.isNew ? handleCreate : handleUpdate}>

        {!props.isNew &&
          <Form.Item
              name='_id'
              initialValue={props.manufacturerProductDataModal?._id}
              hidden>
              <Input hidden/>
          </Form.Item>
        }

        <Form.Item
          name='manufacturer_id'
          initialValue={props.manufacturerProductDataModal?.manufacturer_id}
          hidden>
          <Input hidden/>
        </Form.Item>

        <Row gutter={[16, 16]}>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label='שם מוצר'
              initialValue={props.manufacturerProductDataModal?.product_name}
              rules={[{required: true, message: "שדה חובה"}]}
              name='product_name'>
              <Input placeholder='שם מוצר'/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label='סוג מוצר'
              initialValue={props.manufacturerProductDataModal?.type}
              rules={[{required: true, message: "שדה חובה"}]}
              name='type'>
              <Select options={[
                {label: 'ביטוח', value: 'INSURANCE'},
                {label: 'גמל, פנסיה, השתלמות', value: 'OTHERS'},
              ]}
              onChange={(value) => setTypeSelected(value)}/>
            </Form.Item>
          </Col>

          {typeSelected === 'OTHERS' &&
              <Col lg={8} md={12} sm={12} xs={12}>
                  <Form.Item
                      label='מספר באוצר (מ"ה)'
                      initialValue={props.manufacturerProductDataModal?.income_tax_certificate}
                      rules={[{required: true, message: "שדה חובה"}]}
                      name='income_tax_certificate'>
                      <InputNumber min={1} placeholder='מספר באוצר'/>
                  </Form.Item>
              </Col>
          }

          {typeSelected === 'INSURANCE' &&
              <Col lg={8} md={12} sm={12} xs={12} style={{marginTop: "auto", marginBottom: "auto"}}>
                  <Text strong style={{marginBottom: 10}}>מספר באוצר (מ"ה)</Text><br/>
                  <Text>{props.manufacturerProductDataModal?.business_number}</Text>
              </Col>
          }

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label='תיק ניכויים'
              initialValue={props.manufacturerProductDataModal?.deductions_portfolio}
              name='deductions_portfolio'>
              <InputNumber min={1} placeholder='תיק ניכויים'/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label='קוד מוסד'
              initialValue={props.manufacturerProductDataModal?.institution_code}
              name='institution_code'>
              <InputNumber min={1} placeholder='קוד מוסד'/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label='אימייל'
              initialValue={props.manufacturerProductDataModal?.email}
              rules={[{type: "email", message: "אימייל לא תקין"}]}
              name='email'>
              <Input placeholder='אימייל'/>
            </Form.Item>
          </Col>

          <Col lg={24} md={24} sm={24} xs={24} className="my-3">
            <Text strong type="warning">פרטי חשבון</Text>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="מספר בנק"
              initialValue={props.manufacturerProductDataModal?.bank_details?.bank_number}
              rules={[{required: isBankNumberRequired || isBranchNumberRequired || isAccountNumberRequired, message: "שדה חובה"}]}
              name={['bank_details', 'bank_number']}>
              <Select showSearch
                      allowClear
                      placeholder='מספר בנק'
                      onSelect={(value: any) => {
                        getBranches(value);
                        setBankNumberSelected(value);
                      }}
                      onClear={() => {
                        setBankNumberRequired(false);
                        setBankNumberSelected(false);
                        setBranchNumberRequired(false);
                        setBranchNumberSelected(false);
                        setAccountNumberRequired(false);
                        setBranchs([]);
                        form.setFieldsValue({bank_details: {branch_number: undefined, account_number: undefined}});
                      }}
                      onChange={(value) => {
                        setBranchs([]);
                        form.setFieldsValue({bank_details: {branch_number: undefined}});
                        setBankNumberRequired(!!value);
                      }}>
                {
                  banks.map((bank: any) => <Select.Option key={bank.bankCode}
                                                          value={bank.bankCode}>{bank.bankCode + " - " + bank.bankName}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label='מספר סניף'
              initialValue={props.manufacturerProductDataModal?.bank_details?.branch_number}
              rules={[{required: isBankNumberRequired || isBranchNumberRequired || isAccountNumberRequired, message: "שדה חובה"}]}
              name={['bank_details', 'branch_number']}>
              <Select showSearch
                      allowClear
                      placeholder='מספר סניף'
                      disabled={!isBankNumberSelected}
                      onSelect={() => {
                        setBranchNumberSelected(true)
                      }}
                      onClear={() => {
                        setBranchNumberRequired(false);
                        setBranchNumberSelected(false);
                        setAccountNumberRequired(false);
                        form.setFieldsValue({bank_details: {account_number: undefined}});
                      }}
                      onChange={(value) => {
                        setAccountNumberRequired(!!value);
                      }}>
                {
                  branchs.map((branch: BANK.BranchOptionParam) => <Select.Option key={branch.value}
                                                          value={branch.value}>{branch.label}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label='מספר חשבון'
              initialValue={props.manufacturerProductDataModal?.bank_details?.account_number}
              rules={[{required: isBankNumberRequired || isBranchNumberRequired || isAccountNumberRequired, message: "שדה חובה"}]}
              name={['bank_details', 'account_number']}>
              <InputNumber disabled={!isBankNumberSelected || !isBranchNumberSelected} min={1} onChange={(value) => setAccountNumberRequired(!!value)} placeholder='מספר חשבון'/>
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  );
};

export default ManufacturerProductModal;
