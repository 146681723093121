import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {getLoggedInUserId, logout} from "../../../services/auth";

class TopMenu extends Component {
    render() {
        return (
            <div className='mt-3'>
                <Link to="/client/details" className="waves-effect mx-3">
                    <span className="ml-1 font-size-18">מוצרים</span>
                </Link>

                <Link to="/conversation/client" className="waves-effect mx-3">
                    <span className="ml-1 font-size-18">התקשרות עם הסוכן</span>
                </Link>

                <Link to={"/login/client?cid=" + getLoggedInUserId()} onClick={() => logout()} className="waves-effect mx-3">
                    <span className="ml-1 font-size-18">התנתק</span>
                </Link>
            </div>
        );
    }
}

export default TopMenu;
