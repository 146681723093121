import React, {useState} from 'react';
import TasksPage from "../../../components/tables/Tasks";
import Breadcrumbs from "../../../components/antDesign/Breadcrumb";
import {Button, Card, Col, Row} from "antd";

const BreadcrumbsItems = {
  title: "רשימת המשימות",
  breadcrumbs: [{item: "רשימת המשימות"}, {item: "משימות"}]
}

const Tasks: React.FC = () => {

  const [addEditTaskModalShow, setAddEditTaskModalShow] = useState<boolean>(false);

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title={BreadcrumbsItems.title} breadcrumbs={BreadcrumbsItems.breadcrumbs}/>

        <Row>
          <Col lg={24}>
            <Card>
              <Button type="primary"
                      style={{height: 40}}
                      onClick={() => {
                        setAddEditTaskModalShow(true);
                      }}>
                <i className="mdi mdi-plus mr-2"/>צור משימה
              </Button>
            </Card>
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xs={24}>
            <TasksPage
              visibleAddEditTaskModal={addEditTaskModalShow}
              filter={true}
              onClose={() => setAddEditTaskModalShow(false)}
            />

          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Tasks;