import React, {useRef, useState} from 'react';
import {ActionType, ProColumns} from "@ant-design/pro-components";
import {Button, Card, Col, Row, Tag, Tooltip} from "antd";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import {getUsersList} from "../../../services/user";
import ViewUserModal from "./ViewUserModal";
import UserModal from "./UserModal";
import Breadcrumbs from "../../../components/antDesign/Breadcrumb";
import Table from "../../../components/antDesign/ProTable";

const BreadcrumbsItems = {
  title: "רשימת משתמשים",
  breadcrumbs: [{item: "רשימת משתמשים"}, {item: "משתמשים"}]
}

const UsersList: React.FC = () => {

  const [userViewModalShow, setUserViewModalShow] = useState<boolean>(false);
  const [userDataModal, setUserDataModal] = useState<any>({});
  const [userModalShow, setUserModalShow] = useState<boolean>(false);
  const [isNew, setNew] = useState<boolean>(false);

  const actionRef = useRef<ActionType>();

  const columns: ProColumns[] = [
    {
      align: 'right',
      sorter: (a: any, b: any) => a.companyIdNumber ? a.companyIdNumber.localeCompare(b.companyIdNumber) : false,
      title: "ת.ז / ח.פ",
      dataIndex: 'companyIdNumber',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.companyName ? a.companyName.localeCompare(b.companyName) : false,
      title: "חברה",
      dataIndex: 'companyName',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.firstName ? a.firstName.localeCompare(b.firstName) : false,
      title: "שם פרטי",
      dataIndex: 'firstName',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.lastName ? a.lastName.localeCompare(b.lastName) : false,
      title: "שם משפחה",
      dataIndex: 'lastName',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.email ? a.email.localeCompare(b.email) : false,
      title: "אימייל",
      dataIndex: 'email',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.phone ? a.phone.localeCompare(b.phone) : false,
      title: "טלפון",
      dataIndex: 'phone',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.type ? a.type.localeCompare(b.type) : false,
      title: "סוג משתמש",
      dataIndex: 'type',
      render: (_: any, record: any) => [
        <div key={record._id}>

          {
            (record.isAdmin || record.type === 'admin') &&
              <Tag color="#f50">אדמין</Tag>
          }

          {/*{*/}
          {/*  record.type === 'employee' &&*/}
          {/*    <Tag color="#87d068">עובד</Tag>*/}
          {/*}*/}

          {
            record.type === 'agent' &&
              <Tag color="#000000">סוכן</Tag>
          }

          {/*{*/}
          {/*  record.type === 'admiringFactor' &&*/}
          {/*    <Tag color="#fcb92c">גורם מתפעל</Tag>*/}
          {/*}*/}

          {
            record.type === 'admin-company' &&
              <Tag color="#fcb92c">אדמין חברה</Tag>
          }

          {
            record.type === 'treatment' &&
              <Tag color="#87d068">גורם מטפל</Tag>
          }
        </div>
      ]
    }, {
      align: 'right',
      title: "פעולות",
      dataIndex: 'actions',
      render: (_: any, record: any) => [
        <div key={record._id}>
          <Tooltip title='עריכה'>
            <Button
              type='link'
              onClick={() => {
                setUserDataModal(record);
                setNew(false);
                setUserModalShow(true);
              }}>
              <EditOutlined/>
            </Button>
          </Tooltip>

          <Tooltip title='צפייה'>
            <Button
              type='link'
              onClick={() => {
                setUserDataModal(record);
                setUserViewModalShow(true);
              }}>
              <EyeOutlined/>
            </Button>
          </Tooltip>
        </div>
      ]
    }
  ];

  const loadDataTable = async (params: any) => {
    return await getUsersList(params).then((response) => {
      const dataTable = response.list.map((user: any) => {
        return {
          _id: user._id,
          userId: user?.userId,
          companyName: user.company && user.company.name ? user.company.name : '',
          companyId: user.company && user.company._id ? user.company._id : '',
          companyIdNumber: user.company && user.company.idNumber ? user.company.idNumber : '',
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          type: user.type,
          phone: user.phone,
          agentLicense: user.agentLicense,
          createdAt: user.createdAt,
          isAdmin: user.isAdmin,
          isCompleteRegistration: user.isCompleteRegistration,
          updatedAt: user.updatedAt,
        }
      })
      return {
        status: response.status,
        current: response.current,
        total: response.total,
        pageSize: response.pageSize,
        data: dataTable,
      };
    })
  }

  const closeViewUserModal = () => {
    setUserViewModalShow(false);
    setNew(false);
  }

  const closeUserModal = (refresh: boolean) => {
    if (refresh) {
      actionRef.current?.reload()
    }
    setUserModalShow(false);
    setNew(false);
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title={BreadcrumbsItems.title} breadcrumbs={BreadcrumbsItems.breadcrumbs}/>

        <Row className='my-2'>
          <Col xs={24}>
            <Card>
              <Button type="primary"
                      style={{height: 40}}
                      onClick={() => {
                        setUserDataModal({type: 'agent'});
                        setNew(true);
                        setUserModalShow(true);
                      }}>
                <i className="mdi mdi-plus mr-2"/>צור משתמש
              </Button>
            </Card>
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xs={24}>
            <Table
              actionRef={actionRef}
              request={loadDataTable}
              // @ts-ignore
              columns={columns}
              totalText='משתמשים'
              showPagination={true}
            />
          </Col>
        </Row>
        <ViewUserModal visible={userViewModalShow}
                       userDataModal={userDataModal}
                       onClose={() => closeViewUserModal()}/>
        <UserModal visible={userModalShow}
                   userDataModal={userDataModal}
                   isNew={isNew}
                   onClose={(refresh: boolean) => closeUserModal(refresh)}/>
      </div>
    </React.Fragment>
  );
};

export default UsersList;
