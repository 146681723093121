import React from 'react';
import {Switch as SwitchAnt} from "antd";
import {SwitchChangeEventHandler, SwitchClickEventHandler, SwitchSize} from "antd/lib/switch";
interface Props {
  checkedChildren?: React.ReactNode;
  unCheckedChildren?: React.ReactNode;
  defaultChecked?: boolean;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  size?: SwitchSize;
  loading?: boolean;
  onChange?: SwitchChangeEventHandler;
  onClick?: SwitchClickEventHandler;
  prefixCls?: string;
  autoFocus?: boolean;
  title?: string;
  tabIndex?: number;
  id?: string;
  checked?: boolean;
}
const Switch: React.FC<Props> = (props) => {
  return (
    <SwitchAnt
      style={props.style}
      className={props.className}
      checked={props.checked}
      id={props.id}
      tabIndex={props.tabIndex}
      title={props.title}
      autoFocus={props.autoFocus}
      prefixCls={props.prefixCls}
      loading={props.loading}
      size={props.size}
      disabled={props.disabled}
      onClick={props.onClick}
      onChange={props.onChange}
      checkedChildren={props.checkedChildren}
      unCheckedChildren={props.unCheckedChildren}
      defaultChecked={props.defaultChecked}
    />
  );
};

export default Switch;
