import React from 'react';
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {Button} from "antd";

interface Props {
  collapsed: boolean;
}

const Collapse: React.FC<Props> = (props) => {
  return (<Button type='link' icon={props.collapsed ? (<DownOutlined
    style={{
      fontSize: 11,
      position: 'absolute',
      bottom: 0,
      right: 0,
    }}
  />) : (<UpOutlined
    style={{
      fontSize: 11,
      position: 'absolute',
      bottom: 0,
      right: 0,
    }}
  />)} size={"small"}>הצג עוד</Button>);
};

export default Collapse;
