import React from 'react';
import { Card as CardAnt } from 'antd';

interface Props {
  title?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}
const Card: React.FC<Props> = (props) => {
  return (
    <CardAnt
      style={props.style}
      className={props.className}
      title={props.title}>
      {props.children}
    </CardAnt>
  );
};

export default Card;
