const download = (fileName: string, type: string, source: string) => {
  const linkSource = `data:${type};base64,${source}`;
  const link = document.createElement('a');
  link.href = linkSource;
  link.download = fileName;
  link.click();
}

const view = (type: string, source: string) => {
  const binary = window.atob(source);
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  // create the blob object with content-type "application/pdf"
  const blob = new Blob( [view], { type });
  const blobURL = URL.createObjectURL(blob);
  window.open(blobURL, "_blank");
}

export default {download, view}
