import moment from "moment/moment";

const sortDate = (a: string, b: string, sortOrder: string | undefined | null) => {
  const minDate = moment.utc('01-01-1970', 'DD-MM-YYYY');
  const _a = moment.utc(a, 'DD-MM-YYYY');
  const _b = moment.utc(b, 'DD-MM-YYYY');

  if (_a.isValid() && _b.isValid()) {
    return _a.diff(_b);
  }

  if (!_a.isValid() && !_b.isValid())
    return -1;

  if (!_a.isValid()) {
    return minDate.diff(_b);
  }

  if (!_b.isValid())
    return _a.diff(minDate);

  return 0;
}

const sortText = (a: string, b: string, sortOrder: string | undefined | null) => {
  return a.localeCompare(b)
}

const sortNumber = (a: number, b: number, sortOrder: string | undefined | null) => {
  return a - b;
}

export default {sortDate, sortText, sortNumber}
