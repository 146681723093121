interface SetParam {
  key: string;
  value: string;
}

const getURLSearchParams = () => {
  return new URLSearchParams(window.location.search);
}

const getParams = (keys: string[]) => {
  const params = getURLSearchParams();
  const result: any = {};
  keys.forEach(key => {
    if(params.get(key))
      result[key] = params.get(key);
  })
  return result;
}

const setParams = (setParams: SetParam[]) => {
  const params = getURLSearchParams();
  setParams.forEach(p => {
    params.set(p.key, p.value);
  })
  return params;
}

const deleteParams = (keys: string[]) => {
  const params = getURLSearchParams();
  keys.forEach(key => {
    params.delete(key);
  })
}

const deleteAndSetParams = (setParams: SetParam[]) => {
  const params = getURLSearchParams();

  for (const key of params.keys()) {
    params.delete(key);
  }

  setParams.forEach(p => {
    params.set(p.key, p.value);
  })
  return params;
}

export default {getParams, setParams, deleteParams, deleteAndSetParams};
