import React, {useEffect, useState} from 'react';
import {getClientDetailsProductsElementary, sendPoliciesToInsuranceCompanies} from "../../../../services/client";
import PoliciesTable from "../../../tables/PoliciesElementary";
import {Card, Col, Row, Typography} from "antd";
import Button from "../../../antDesign/Button";
import {MailOutlined} from '@ant-design/icons';
import moment from "moment-timezone";
import Messages from "../../../antDesign/Message";
import Tabs from "../../../antDesign/Tabs";

const {Text} = Typography;

interface Props {
  clientId?: string | undefined;
  lastDate?: string;
  isClient?: boolean;
  isSigned: boolean;
}

const ProductsElementary: React.FC<Props> = (props) => {

  const [tabs, setTabs] = useState<any>([]);
  const [submittingSendPolicies, setSubmittingSendPolicies] = useState<boolean>(false);
  const [isAllOwner, setAllOwner] = useState<boolean>(false);
  const [isAllSent, setAllSent] = useState<boolean>(false);

  const getClientId = () => {
    const params = new URLSearchParams(window.location.search);
    const url = window.location.href.split('?');
    const cid = params.get('cid');
    // agent
    if (url.length > 0 && url[0].endsWith('client-details'))
      if (cid)
        return cid;

    return '';
  }

  const getProducts = (clientId: string) => {
    getClientDetailsProductsElementary({clientId}).then((data) => {
      const productType = {id: 0, name: "הכל"};
      const policiesList: any = [];
      setAllOwner(data.client.is_all_owner);
      setAllSent(data.client.is_all_sent);
      data.client.insuranceMountainProducts.forEach((product: any, index: number) => {
        policiesList.push({
          manufacturer: {key: index, manufacturer_label: product.productType.name}
        })
        product.policies.forEach((policy: any) => {
          policy.documents.push({
            title: 'העלאת מסמך',
            type: 'add'
          });
          policiesList.push({...policy, key: policy.policy_number, productType: product.productType});
        })
      })
      const productsWithAll = [{policies: policiesList, productType}].concat(data.client.insuranceMountainProducts);

      const tabsList: any = [{
        key: 0,
        label: "הכל",
        table: {rows: []}
      }].concat(data.table)

      for (let i = 0; i < tabsList.length; i++) {
        const p = productsWithAll.filter((product: any) => {
          return product?.productType?.id === tabsList[i].key;
        });
        tabsList[i].children = <PoliciesTable
          productType={p[0].productType}
          isClient={props.isClient}
          clientId={getClientId()}
          reload={() => {getProducts(props.clientId || getClientId());}}
          policies={p[0].policies}/>
      }
      setTabs(tabsList)
    })
  }

  const sendPolicies = () => {
    setSubmittingSendPolicies(true);
    sendPoliciesToInsuranceCompanies({clientId: props.clientId})
      .then(response => {
        if (response.status === 'SUCCESS') {
          getProducts(props.clientId || getClientId());
          Messages.Success('נשלח!').then(() => {
          });
        }
        setSubmittingSendPolicies(false);
      }).catch((error) => {
      setSubmittingSendPolicies(false);
      Messages.Error(error).then(() => {
      });
    })
  }

  useEffect(() => {
    if (!props.isClient)
      getProducts(getClientId());
    return () => {
    };
  }, [])

  useEffect(() => {
    if (props.isClient && props.clientId) {
      getProducts(props.clientId);
    }
    return () => {
    };
  }, [props.clientId])

  return (
    <>
      <Row className='mb-3'>
        {tabs && tabs.length > 1 &&
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{textAlign: 'left'}}>
                <Card>
                    <Row justify="end" align="middle" gutter={[16, 16]}>
                      {!props.isClient &&
                          <Col>
                              <Button type='primary' loading={submittingSendPolicies} disabled={!props.isSigned || isAllSent}
                                      onClick={() => sendPolicies()}
                                      icon={<MailOutlined/>}>שלח לחברות הביטוח</Button>
                          </Col>
                      }

                      {props.lastDate &&
                          <Col>
                              <Text strong>תאריך נכונות</Text>
                              <br/>
                              <Text
                                  strong>{moment(props.lastDate).tz('Asia/Tel_Aviv').format('DD/MM/YYYY HH:mm:ss')}</Text>
                          </Col>
                      }
                    </Row>
                </Card>
            </Col>
        }
      </Row>
      <Tabs
        className='mt-3 mb-5'
        items={tabs}
        type="card"
        destroyInactiveTabPane/>
    </>
  );
};

export default ProductsElementary;
