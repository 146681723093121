import axios from '../axios';
import baseService from "../base";

// get meetings
export const getMeetings = async (data: API.MeetingParam) => {
  let url = baseService.baseUrl + baseService.endpoints.meeting.get;
  url = url.replace(':start', "start=" + data.start)
    .replace(':end', "end=" + data.end)
    .replace(':timezone', "timezone=" + data.timeZone);
  return await axios.GET(url, {auth: true}).catch();
}

// get meetings
export const getMeetingDetails = async (data: API.MeetingDetailsParam) => {
  let url = baseService.baseUrl + baseService.endpoints.meeting.details;
  url = url.replace(':id', "id=" + data.id)
  return await axios.GET(url, {auth: true}).catch();
}

// create meeting
export const createMeeting = async (data: API.CreateMeetingParam) => {
  let url = baseService.baseUrl + baseService.endpoints.meeting.create;
  return await axios.POST(url, data, {auth: true}).catch();
}

// update meeting
export const updateMeeting = async (data: API.UpdateMeetingParam) => {
  let url = baseService.baseUrl + baseService.endpoints.meeting.update;
  return await axios.PUT(url, data, {auth: true}).catch();
}

// delete meeting
export const deleteMeeting = async (data: API.DeleteMeetingParam) => {
  let url = baseService.baseUrl + baseService.endpoints.meeting.delete;
  return await axios.DELETE(url, data, {auth: true}).catch();
}
