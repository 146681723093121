import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from "antd";
import SignaturePad from 'react-signature-canvas'
import {sign} from "../../../services/signature";

interface Props {
  client: any;
  onBeforeUpdateSignature: () => void;
  onAfterUpdateSignature: () => void;
}

const Signature: React.FC<Props> = (props) => {

  const [sigPad, setSigPad] = useState<any>(undefined);
  const [currentDoc, setCurrentDoc] = useState<number>(-1);
  const [signTitle, setSignTitle] = useState<any>('');
  const [totalDoc, setTotalDoc] = useState<number>(-1);

  const clearSignature = () => {
    if (sigPad)
      sigPad.clear();
  }

  const trimSignature = () => {
    if (sigPad && sigPad.isEmpty())
      return;

    props.onBeforeUpdateSignature();
    let signBase64 = sigPad.getTrimmedCanvas()
      .toDataURL('image/png');

    sign({
      signatureBase64: signBase64,
      appendixType: props.client.appendixDocuments[currentDoc].appendixType,
      clientId: props.client._id
    }).then(() => {
      props.onAfterUpdateSignature();
    });
  }

  const openDoc = (index: number) => {
    let arr = props.client.appendixDocuments[index].fileData.data;
    let byteArray = new Uint8Array(arr);
    let blob = new Blob([byteArray], {type: 'application/pdf'});
    let blobURL = URL.createObjectURL(blob);
    window.open(blobURL, "_blank");
  }

  const documentSelected = () => {
    if (props.client && Object.keys(props.client).length !== 0) {
      const appendixDocuments = props.client.appendixDocuments;

      let currentDocToSign = 0;

      let totalDoc = props.client.appendixDocuments.length;
      appendixDocuments.forEach((doc: any) => {
        if (doc.isSigned)
          currentDocToSign++;
      });
      let signTitle: any = '';
      if (currentDocToSign < totalDoc) {
        switch (appendixDocuments[currentDocToSign].appendixType) {
          case "appendixA":
            signTitle =
              <div>
                יש לחתום בריבוע מתחת, על מנת לאשר את
                <Button type="link"
                        style={{paddingRight: 5}}
                        onClick={() => openDoc(0)}>
                  טופס פנייה למסלקה הפנסיונית
                </Button>
              </div>;
            break;
          case "appendixB":
            signTitle =
              <div>
                יש לחתום בריבוע מתחת, על מנת לאשר את
                <Button type="link"
                        style={{paddingRight: 5}}
                        onClick={() => openDoc(1)}>
                  קבלת הפוליסות מחברות הביטוח
                </Button>
              </div>;
            break;
          case "appendixE":
            signTitle =
              <div>
                יש לחתום בריבוע מתחת, על מנת לאשר את
                <Button type="link"
                        style={{paddingRight: 5}}
                        onClick={() => openDoc(2)}>
                  קבלת המידע מהר הביטוח
                </Button>
              </div>;
            break;
          default:
            break;

        }
      }
      setCurrentDoc(currentDocToSign);
      setSignTitle(signTitle);
      setTotalDoc(totalDoc)
    }
  }

  useEffect(() => {
    if (props.client) {
      clearSignature();
      documentSelected();
    }
    return () => {
    };
  }, [props.client]);

  return (
    <div>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24} className="text-center">
          <span> חתימה {currentDoc + 1} מתוך {totalDoc}</span>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="text-center">
          {signTitle}
        </Col>
      </Row>
      <Row className='my-2'>
        <Col lg={24} md={24} sm={24} xs={24} className='text-center'>
          <SignaturePad
            canvasProps={{width: 360, height: 180, className: "signature-pad"}}
            ref={(ref) => {
              setSigPad(ref)
            }}/>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24} className="text-center mb-3">
          <Button type="link"
                  style={{paddingRight: 5}}
                  onClick={() => clearSignature()}>
            נקה
          </Button>
        </Col>
      </Row>
      <Button type="primary"
              className="custom-button"
              htmlType="submit"
              onClick={() => trimSignature()}>המשך</Button>
    </div>
  );
};

export default Signature;
