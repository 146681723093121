import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Col, ConfigProvider, DatePicker, Form, InputNumber, Row, Select, Typography} from "antd";
import InsuranceMountainUploader from "../../../components/Client/ClientsList/component/insuranceMountainUploader";
import {ActionType} from "@ant-design/pro-components";
import {ColumnsType, TablePaginationConfig} from "antd/es/table";
import Table from "../../../components/antDesign/Table";
import {getReportInsuranceMountain} from "../../../services/reports";

import type {FilterValue} from 'antd/es/table/interface';
import sorter from "../../../helpers/utils/sorter";
import {getClientsList} from "../../../services/client";
import {manufacturersOptions} from "../../../services/manufacturer";
import moment from "moment";
import 'moment/locale/he';
import locale from 'antd/es/locale/he_IL'
import {RangePickerProps} from "antd/es/date-picker";


const InsuranceMountainReport: React.FC = () => {
  const [form] = Form.useForm();
  const actionRef = useRef<ActionType>();

  const [clients, setClients] = useState<GLOBAL.OPTION[]>([]);
  const [manufacturers, setManufacturers] = useState<GLOBAL.OPTION[]>([]);
  const [filter, setFilter] = useState<{ client: string } | {}>({});
  const [showUploader, setShowUploader] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [clientsLoading, setClientsLoading] = useState<boolean>(true);
  const [manufacturersLoading, setManufacturersLoading] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<any>([]);

  const columns: ColumnsType = [
    {
      align: 'right',
      title: "ת.ז",
      dataIndex: 'clientId',
      key: 'clientId',
      sorter: {
        // @ts-ignore
        compare: (a: { clientId: number }, b: { clientId: number }, sortOrder) => {
          return sorter.sortNumber(a.clientId, b.clientId, sortOrder);
        }
      }
    },
    {
      align: 'right',
      title: "שם מלא",
      dataIndex: 'full_name',
      key: 'full_name',
      sorter: {
        // @ts-ignore
        compare: (a: { company: string }, b: { company: string }, sortOrder) => {
          return sorter.sortText(a.company, b.company, sortOrder);
        }
      }
    }, {
      align: 'right',
      title: "טלפון",
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        // @ts-ignore
        compare: (a: { phone: string }, b: { phone: string }, sortOrder) => {
          return sorter.sortText(a.phone, b.phone, sortOrder);
        }
      }
    }, {
      align: 'right',
      title: "שם יצרן",
      dataIndex: 'company',
      key: 'company',
      sorter: {
        // @ts-ignore
        compare: (a: { company: string }, b: { company: string }, sortOrder) => {
          return sorter.sortText(a.company, b.company, sortOrder);
        }
      },
    }, {
      align: 'right',
      title: "ענף ראשי",
      dataIndex: 'main_branch',
      key: 'main_branch',
      sorter: {
        // @ts-ignore
        compare: (a: { main_branch: string }, b: { main_branch: string }, sortOrder) => {
          return sorter.sortText(a.main_branch, b.main_branch, sortOrder);
        }
      },
    }, /*{
      align: 'right',
      title: "סוג פרמיה",
      dataIndex: 'premium_type',
      key: 'premium_type',
      sorter: {
        // @ts-ignore
        compare: (a: { premium_type: string }, b: { premium_type: string }, sortOrder) => {
          return sorter.sortText(a.premium_type, b.premium_type, sortOrder);
        }
      },
    },*/
    {
      align: 'right',
      title: "פרמיה חודשית",
      dataIndex: 'premium_total_month',
      key: 'premium_total_month',
      sorter: {
        // @ts-ignore
        compare: (a: { premium_total_month: number }, b: { premium_total_month: number }, sortOrder) => {
          return sorter.sortNumber(a.premium_total_month, b.premium_total_month, sortOrder);
        }
      },
    },{
      align: 'right',
      title: "פרמיה שנתית",
      dataIndex: 'premium_total_year',
      key: 'premium_total_year',
      sorter: {
        // @ts-ignore
        compare: (a: { premium_total_year: number }, b: { premium_total_year: number }, sortOrder) => {
          return sorter.sortNumber(a.premium_total_year, b.premium_total_year, sortOrder);
        }
      },
    }, {
      align: 'right',
      title: "פוליסה",
      dataIndex: 'policy_number',
      key: 'policy_number',
      sorter: {
        // @ts-ignore
        compare: (a: { policy_number: number }, b: { policy_number: number }, sortOrder) => {
          return sorter.sortNumber(a.policy_number, b.policy_number, sortOrder);
        }
      }
    }, {
      align: 'right',
      title: "תחילת הביטוח",
      dataIndex: 'start_policy_date',
      key: 'start_policy_date',
      sorter: {
        // @ts-ignore
        compare: (a: { start_policy_date: string }, b: { start_policy_date: string }, sortOrder) => {
          return sorter.sortDate(a.start_policy_date, b.start_policy_date, sortOrder);
        }
      }
    }, {
      align: 'right',
      title: "סיום הביטוח",
      dataIndex: 'end_policy_date',
      key: 'end_policy_date',
      sorter: {
        // @ts-ignore
        compare: (a: { end_policy_date: string }, b: { end_policy_date: string }, sortOrder) => {
          return sorter.sortDate(a.end_policy_date, b.end_policy_date, sortOrder);
        }
      }
    }, {
      align: 'right',
      title: "סיווג",
      dataIndex: 'program_classification',
      key: 'program_classification',
      sorter: {
        // @ts-ignore
        compare: (a: { program_classification: string }, b: { program_classification: string }, sortOrder) => {
          return sorter.sortText(a.program_classification, b.program_classification, sortOrder);
        }
      }
    }
  ];
  const subColumns: ColumnsType = [
    {
      align: 'right',
      title: "ענף (משני)",
      dataIndex: 'secondary_branch',
      key: 'secondary_branch',
    }, {
      align: 'right',
      title: "סוג מוצר",
      dataIndex: 'type_product',
      key: 'type_product',
    }, {
      align: 'right',
      title: "פרמיה",
      dataIndex: 'premium_in_NIS',
      render: (_: any, record: any) => {
        if (record.premium_in_NIS) {
          return <>{record.premium_in_NIS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</>
        }
        return <>0</>
      },
      key: 'premium_in_NIS',
    }, {
      align: 'right',
      title: "סוג פרמיה",
      dataIndex: 'premium_type',
      key: 'premium_type',
    }, {
      align: 'right',
      title: "פוליסה",
      dataIndex: 'policy_number',
      key: 'policy_number',
    }, {
      align: 'right',
      title: "תחילת הביטוח",
      dataIndex: 'start_policy_date',
      key: 'start_policy_date',
    }, {
      align: 'right',
      title: "סיום הביטוח",
      dataIndex: 'end_policy_date',
      key: 'end_policy_date',
    }, {
      align: 'right',
      title: "סיווג תכנית",
      dataIndex: 'program_classification',
      key: 'program_classification',
    }
  ];

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > moment().endOf('day');
  };

  const disabledEndDate: RangePickerProps['disabledDate'] = current => {
    const fromDate = form.getFieldValue('from_date');
    return current && current < moment(fromDate).startOf('day');
  };

  const loadDataSource = async (filter: any) => {
    setTableLoading(true);

    const response = await getReportInsuranceMountain(filter);
    setDataSource(response.status === 'SUCCESS' ? response.list : []);
    setTableLoading(false);
  }

  const loadClients = async () => {
    setClientsLoading(true);
    const _clients = await getClientsList();

    setClients(_clients.list.length === 0 ? [] :
      _clients.list.map((client: { _id: string; firstName: string; lastName: string; clientId: string; }) => {
        return {
          value: client._id,
          label: `${client.firstName} ${client.lastName} - ${client.clientId}`
        }
      }));

    setClientsLoading(false);
  }

  const loadManufacturers = async () => {
    setManufacturersLoading(true);
    const _manufacturers = await manufacturersOptions();

    setManufacturers(_manufacturers.status === "SUCCESS" ? _manufacturers.options : []);
    setManufacturersLoading(false);
  }

  const onFilter = async (values: any) => {
    setFilter(values);
    await loadDataSource(values);
  };

  const clearFilter = async () => {
    setFilter({});
    form.resetFields();
    await loadDataSource({});
  }

  useEffect(() => {
    (async () => {
      await loadDataSource(filter);
      await loadClients();
      await loadManufacturers();
    })();

    return () => {
    };
  }, []);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: any,
  ) => {

    /* setTableParams({
       pagination,
       filters,
       ...sorter,
     });*/
  };

  return (
    <React.Fragment>
      <ConfigProvider locale={locale} direction="rtl">
      <div className="page-content" id='insurance-mountain-report'>
        <Card>
          {<Button type="primary"
                   style={{height: 40}}
                   onClick={() => {
                     setShowUploader(!showUploader);
                   }}>
            <i className="mdi mdi-plus mr-2"/>טען קבצים
          </Button>}
        </Card>
        {showUploader && <Card>
          <InsuranceMountainUploader
            accept='.xlsx'
            maxCount={1}
            onUploaded={async (status) => {
              if (status) {
                await loadDataSource(filter);
                setShowUploader(false);
              }
            }}
          />
        </Card>}
        <Card className="filter">
          <Form
            form={form}
            onFinish={onFilter}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                <Form.Item label="לקוח" name="client">
                  <Select
                    onClear={async () => {
                      const _filter = {...filter, client: null}
                      setFilter(_filter);
                      // await loadDataSource(_filter);
                    }}

                    dropdownMatchSelectWidth
                    options={clients}
                    showSearch
                    allowClear
                    loading={clientsLoading}
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                <Form.Item label="יצרנית" name="manufacturer">
                  <Select
                    onClear={async () => {
                      const _filter = {...filter, manufacturer: null}
                      setFilter(_filter);
                      //await loadDataSource(_filter);
                    }}

                    dropdownMatchSelectWidth
                    options={manufacturers}
                    showSearch
                    allowClear
                    loading={clientsLoading}
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                <Form.Item label="ענף ראשי" name="main_branch">
                  <Select
                    allowClear
                    onClear={async () => {
                      const _filter = {...filter, main_branch: null}
                      setFilter(_filter);
                      // await loadDataSource(_filter);
                    }}

                    dropdownMatchSelectWidth
                    options={[
                      {value: 'אבדן כושר עבודה', label: 'אבדן כושר עבודה'},
                      {value: 'ביטוח בריאות', label: 'ביטוח בריאות'},
                      {value: 'ביטוח דירה', label: 'ביטוח דירה'},
                      {value: 'ביטוח עסק', label: 'ביטוח עסק'},
                      {value: 'ביטוח רכב', label: 'ביטוח רכב'},
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                <Form.Item label="סוג פרמיה" name="premium_type">
                  <Select
                    allowClear
                    onClear={async () => {
                      const _filter = {...filter, premium_type: null}
                      setFilter(_filter);
                      //  await loadDataSource(_filter);
                    }}

                    dropdownMatchSelectWidth
                    options={[
                      {value: 'שנתית', label: 'שנתית'},
                      {value: 'חודשית', label: 'חודשית'},
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6} style={{display: "flex"}}>
                <Form.Item label="סכום פרמיה (ח)" name="premium_sum_from">
                  <InputNumber min={0}  placeholder={" - מ"}/>
                </Form.Item>
                <Form.Item label="" name="premium_sum_to">
                  <InputNumber min={0} width={130}  placeholder={" - עד"}/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6} >
                <Form.Item label="תחילת ביטוח" name="from_date">
                  <DatePicker
                    format='DD-MM-YYYY'
                    disabledDate={disabledDate}
                    onChange={() => form.validateFields(['end_date'])}
                  />
                </Form.Item>

              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6} >
                <Form.Item name="end_date" label="סיום ביטוח">
                  <DatePicker
                    format='DD-MM-YYYY'
                    disabledDate={disabledEndDate}/>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={16} xl={6} xxl={6} style={{textAlign: 'left'}}>
                <Form.Item>
                  <Button type="primary" htmlType='submit' className="submit-btn">סנן</Button>
                  <Button type="default" onClick={clearFilter}>איפוס</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card style={{minHeight: 'calc(100vh - 190px)'}}>
          <Table
            style={{minHeight: 'calc(100vh - 220px)'}}
            rowKey='key'
            columns={columns}
            rowClassName={(record: any) => record.isTitle ? 'title-row-table' : ''}
            expandable={{
              expandedRowRender: (record: any) => {
                return <div id='policies' style={{paddingBottom: 16, backgroundColor: '#f8f9fa'}}>
                  <div style={{paddingBottom: 10}}>
                    <Typography.Text strong type='warning'>פירוט הפוליסה</Typography.Text>
                  </div>
                  <Table
                    rowKey='key'
                    className='sub-table'
                    columns={subColumns}
                    dataSource={record.rows}
                    pagination={false}
                    bordered
                  />
                </div>
              }
            }}
            dataSource={dataSource}
            pagination={{position: ['bottomRight']}}
            loading={tableLoading}
            scroll={{y: 484}}
          />
        </Card>
      </div>
      </ConfigProvider>
    </React.Fragment>
  );
};

export default InsuranceMountainReport;
