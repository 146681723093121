import React, {useEffect, useState} from 'react';
import {createClientTask, getClientsList, getClientWithoutSignature, updateClientTask} from "../../services/client";

// Editor
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import {getUsersByType} from "../../services/user";
import {getLoggedInUser, getLoggedInUserId} from "../../services/auth";
import {getTaskProperties} from "../../services/task";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Tooltip,
  Typography,
  Upload
} from "antd";
import Messages from "../antDesign/Message";

import {DeleteOutlined, DownloadOutlined, UploadOutlined} from '@ant-design/icons';

import moment from "moment";
import Loader from "../antDesign/Loader";
import {statuses} from "../../pages/Tasks/statuses";

const {Text} = Typography;
const AddEditTaskModal: React.FC<any> = (props) => {
  const [form] = Form.useForm();
  const agentId = getLoggedInUserId();

  const [clientId, setClientId] = useState<string | undefined>(undefined);
  const [client, setClient] = useState<any | undefined>(undefined);
  const [property, setProperty] = useState<string | undefined>(undefined);
  const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMsg, setLoadingMsg] = useState<string>('טוען');

  // select
  const [selectOptionAgents, setSelectOptionAgents] = useState<any>([]);
  const [selectOptionTreatments, setSelectOptionTreatments] = useState<any>([]);
  const [selectOptionTasks, setSelectOptionTasks] = useState<any>([]);
  const [selectOptionSubTasks, setSelectOptionSubTasks] = useState<any>([]);
  const [selectOptionProducts, setSelectOptionProducts] = useState<any>([]);
  const [selectOptionPolicies, setSelectOptionPolicies] = useState<any>([]);
  const [selectOptionClients, setSelectOptionClients] = useState<any>([]);

  // Dynamic Fields
  const [dynamicFields, setDynamicFields] = useState<any>([]);

  const startLoading = (msg: string) => {
    setLoadingMsg(msg);
    setLoading(true);
  }

  const stopLoading = () => {
    setLoading(false);
    setLoadingMsg('');
  }

  const createRequestBody = (values: any) => {
    const fields: any = [];
    const files: any = [];
    dynamicFields.forEach((field: any) => {
      if (field.type === 'checkbox') {
        fields.push({
          name: field.name,
          value: Boolean(values.dynamic_fields[field.name])
        })
      } else if (field.type === 'file') {
        fields.push({
          name: field.name,
          fileName: values.dynamic_fields[field.name][0].originFileObj.name,
          contentType: values.dynamic_fields[field.name][0].originFileObj.type,
          fileContent: values.dynamic_fields[field.name][0].originFileObj
        });
        files.push(values.dynamic_fields[field.name][0].originFileObj)
      } else {
        if (values.dynamic_fields[field.name]) {
          fields.push({
            name: field.name,
            value: values.dynamic_fields[field.name]
          })
        }
      }
    })

    if (clientId) {
      values.client = clientId;
    }

    values.body = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    values.dynamic_fields = fields;
    values.property = property
    return {body: values, files}
  }

  const handleCreateTask = (values: any) => {
    startLoading('יוצר משימה...');
    debugger
    values.agentId = getLoggedInUserId();
    const req = createRequestBody(values);
    createClientTask(req.body, req.files).then((response: any) => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success("המשימה נוצרה בהצלחה")
        if (props.onOk)
          props.onOk()
      } else
        Messages.Error("יצירת המשימה נכשלה");
      stopLoading();
    }).catch(() => {
      Messages.Error("יצירת המשימה נכשלה");
      stopLoading();
    })
  }

  const handleUpdateTask = (values: any) => {
    startLoading('מעדכן משימה...')
    values.agentId = getLoggedInUserId();
    const req: any = createRequestBody(values);
    req.body.id = props.task._id;
    req.body.status = props.task.status;

    updateClientTask(req.body).then((response: any) => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success("המשימה עודכנה בהצלחה")
        if (props.onOk)
          props.onOk()
      } else
        Messages.Error("עדכון המשימה נכשל");
      stopLoading();
    }).catch(() => {
      Messages.Error("עדכון המשימה נכשל");
      stopLoading();
    })
  }

  const loadData = () => {
    getAgents();
    getTreatments();
    if (!props.clientId)
      getClients();
    else {
      getClient();
      setClientId(props.clientId);
    }
    createTemplate();
  }

  const createTemplate = () => {
    createTemplateProductsOptions(props.task, props.isNew)
    if (!props.isNew) {
      createTemplatePoliciesOptions(props.task.product)

      getTaskType().then((properties: any) => {
        createTemplateTasksOptions(properties)
        getTaskType({type: props.task.properties.type}).then((properties: any) => {
          createTemplateSubTasksOptions(properties)
          displayProperties();
          setLoading(false)
        })
      })
      setEditorState(EditorState.createWithContent(
        convertFromRaw(JSON.parse(props.task.body))))
    } else {
      if (props.isSelected)
        createTemplatePoliciesOptions(props.task.policy)

      getTaskType().then((properties: any) => {
        createTemplateTasksOptions(properties)
      });

      setEditorState(EditorState.createEmpty())
      setLoading(false)
    }
  }

  const getTaskType = async (data: any | undefined = undefined) => {
    console.log(data);
    return await getTaskProperties(data).then(response => {
      if (response.status.toString() === 'SUCCESS')
        return response.properties;
      return [];
    })
  }

  const createTemplateProductsOptions = (products: any, isNew: boolean) => {
    const options = []
    if (isNew && !props.isSelected && products.length > 0) {
      products.forEach((product: any) => {
        options.push({
          label: product.productType.name,
          value: product.productType.id,
        })
      })
    } else if (products.productType && products.productType.name && products.productType.id) {
      options.push({
        label: products.productType.name,
        value: products.productType.id,
      })
      form.setFieldsValue({product: products.productType.id})
    }
    setSelectOptionProducts(options)
  }

  const displayProperties = () => {

    let dynamic_fields: any = {};
    let fields = props.task.properties && props.task.properties.fields ?
      props.task.properties.fields.filter((field: any) => field.active) : [];

    if (fields.length > 0) {
      setDynamicFields(fields)
      fields.forEach((field: any) => {
        if (field.type !== 'select' && field.type !== 'file' && field.type !== 'date') {
          // if is regular fields
          const fieldSelected = props.task.fields_values.filter((fieldValues: any) => fieldValues.name === field.name)[0];
          dynamic_fields[fieldSelected['name']] = fieldSelected.value;
        } else {
          // if is special fields
          if (field.type === 'date') {
            const fieldSelected = props.task.fields_values.filter((fieldValues: any) => fieldValues.name === field.name)[0];
            dynamic_fields[fieldSelected['name']] = moment(fieldSelected.value);
          }

          if (field.type === 'select') {
            const optionSelected = props.task.fields_values.filter((fieldValues: any) => fieldValues.name === field.name);
            if (optionSelected.length > 0) {
              const item = field.items.filter((item: any) => item.id === optionSelected[0].value) // get full item for label
              if (item.length > 0) {
                dynamic_fields[field.name] = item[0].id;
              }
            }
          }

          if (field.type === 'file') {
            const file = props.task.fields_values.filter((fieldValues: any) => fieldValues.name === field.name);
            if (file.length > 0) {
              // dynamic_fields[field.name] = JSON.parse(file[0].fileContent);
            }
          }
        }
      })
    }

    form.setFieldsValue({
      task: props.task.properties.type,
      subtask: props.task.properties.subtype,
      treatment: props.task.treatment_id,
      dynamic_fields
    })
    setProperty(props.task.properties_id)
  }

  const createTemplateTasksOptions = (properties: any) => {
    if (properties.length > 0) {
      const options: any = []
      properties.forEach((property: any) => {
        options.push({
          id: property._id,
          label: property.type_label,
          value: property.type
        })
      })
      setSelectOptionTasks(options)
    }
  }

  const createTemplatePoliciesOptions = (policies: any) => {
    const options = []
    if (props.isNew && !props.isSelected && policies.length > 0) {
      policies.forEach((policy: any) => {
        options.push({
          label: policy.productName + " - " + policy.policyNumberOrAccountNumber,
          value: policy.policyNumberOrAccountNumber,
        })
      })
    } else if (policies && policies.policyNumberOrAccountNumber && policies.productName) {
      options.push({
        label: policies.productName + " - " + policies.policyNumberOrAccountNumber,
        value: policies.policyNumberOrAccountNumber,
      })
      form.setFieldsValue({policy: policies.policyNumberOrAccountNumber})
    }
    setSelectOptionPolicies(options)
  }

  const getClient = () => {
    getClientWithoutSignature({clientId: props.clientId}).then((response: any) => {
      setClient(response.client)
    })
  }

  const getClients = () => {
    getClientsList().then((response: any) => {
      createTemplateClientsOptions(response.list);
    })
  }

  const createTemplateClientsOptions = (clients: any) => {
    if (clients.length > 0) {
      const options: any = []
      clients.forEach((client: any) => {
        options.push({
          label: client.firstName + ' ' + client.lastName + ' - ' + client.clientId,
          value: client._id,
          client: client
        })
      })
      setSelectOptionClients(options)
    }
  }

  const getAgents = () => {
    getUsersByType({type: 'agent'}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        createTemplateAgentsOptions(response.users)
        let user = [];
        if (props.isNew) {
          if (getLoggedInUser().type === 'agent') {
            user = response.users.filter((u: any) => u._id.toString() === getLoggedInUserId());
          }
        } else
          user = response.users.filter((u: any) => u._id === props.task.agent_id);
        if (user.length > 0) {
          form.setFieldsValue({agentId: user[0]._id})
        }
      }
    })
  }

  const createTemplateAgentsOptions = (agents: any) => {
    if (agents.length > 0) {
      const options: any = []
      agents.forEach((agent: any) => {
        options.push({
          label: agent.firstName + ' ' + agent.lastName,
          value: agent._id
        })
      })
      setSelectOptionAgents(options)
    }
  }

  const getTreatments = () => {
    getUsersByType({type: 'treatment'}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        createTemplateTreatmentOptions(response.users)
        let user = [];

        if (props.isNew) {
          if (getLoggedInUser().type === 'treatment')
            user = response.users.filter((u: any) => u._id.toString() === getLoggedInUserId());
          else {
            if (response.users.length === 1) {
              user.push(response.users[0]);
            }
          }
        } else
          user = response.users.filter((u: any) => u._id === props.task.treatment_id);

        if (user.length > 0) {
          form.setFieldsValue({treatment: user[0]._id})
        }
      }
    })
  }

  const createTemplateTreatmentOptions = (treatments: any) => {
    if (treatments.length > 0) {
      const options: any = []
      treatments.forEach((treatment: any) => {
        options.push({
          label: treatment.firstName + ' ' + treatment.lastName,
          value: treatment._id
        })
      })
      setSelectOptionTreatments(options)
    }
  }

  const createTemplateSubTasksOptions = (properties: any) => {
    const options: any = []
    if (properties.length > 0) {
      properties.forEach((property: any) => {
        if (property.subtype && property.active)
          options.push({
            id: property._id,
            label: property.subtype_label,
            value: property.subtype
          })
      })
      if (options.length === 1)
        form.setFieldsValue({subtask: options.subtype})
    }
    setSelectOptionSubTasks(options);

    // only type without subtype
    if (properties.length === 1 && !properties[0].subtype) {
      setProperty(properties[0]._id)
    }
  }

  const handleAfterClose = () => {
    resetValues();
    if (props.afterClose)
      props.afterClose()
  }

  // reset all values
  const resetValues = () => {
    setClientId(undefined);
    setClient(undefined);
    setSelectOptionAgents([])
    setSelectOptionTreatments([])
    setSelectOptionTasks([])
    setSelectOptionSubTasks([])
    setSelectOptionProducts([])
    setSelectOptionPolicies([])
    setSelectOptionClients([])
    setDynamicFields([]);
    setEditorState(EditorState.createEmpty());
    form.resetFields();
  }

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState)
  }

  const displayInputText = (field: any) => {
    return (
      <>
        <Form.Item
          name={["dynamic_fields", field.name]}
          label={field.label}
          rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
          <Input/>
        </Form.Item>
      </>
    )
  }

  const displayInputEmail = (field: any) => {
    return (
      <>
        <Form.Item
          name={["dynamic_fields", field.name]}
          label={field.label}
          rules={field.required ? [{type: 'email', message: 'אימייל לא חוקי'}, {
            required: true,
            message: 'שדה חובה'
          }] : [{type: 'email', message: 'אימייל לא חוקי'}]}>
          <Input/>
        </Form.Item>
      </>
    )
  }

  const displayInputNumber = (field: any) => {
    return (
      <>
        <Form.Item
          name={["dynamic_fields", field.name]}
          label={field.label}
          rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
          <InputNumber/>
        </Form.Item>
      </>
    )
  }

  const displayDate = (field: any) => {
    return (
      <>
        <Form.Item
          name={["dynamic_fields", field.name]}
          label={field.label}
          rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
          <DatePicker placeholder='' format={'DD/MM/YYYY'}/>
        </Form.Item>
      </>
    )
  }

  const displayInputTel = (field: any) => {
    return (
      <>
        <Form.Item
          name={["dynamic_fields", field.name]}
          label={field.label}
          rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
          <Input/>
        </Form.Item>
      </>
    )
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onDownloadFilePressed = (doc: any) => {
    // if(doc.originFileObj) {
    //     const byteArray = new Uint8Array(doc.originFileObj);
    //     const blob = new Blob([byteArray], {type: doc.originFileObj.type || 'application/pdf'});
    //     const link = document.createElement('a');
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = doc.originFileObj.name;
    //     link.click();
    // }
  }

  const displayFile = (field: any) => {
    return (
      <Form.Item
        label={field.label}
        rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}
        valuePropName="fileList"
        name={["dynamic_fields", field.name]}
        getValueFromEvent={normFile}>
        <Upload
          name="files"
          maxCount={1}
          onDownload={(file) => {
            onDownloadFilePressed(form.getFieldValue('dynamic_fields')[field.name][0])
          }}
          onRemove={() => {
            const dynamic_fields = form.getFieldValue('dynamic_fields');
            delete dynamic_fields[field.name]
            form.setFieldsValue({dynamic_fields})
          }}
          itemRender={(originNode, file, currFileList, actions) => {
            return <Row className='mt-3'>
              <Col lg={18}>
                <Text strong>{file.name}</Text>
              </Col>
              <Col lg={6}>
                <Tooltip title="הורדה">
                  <DownloadOutlined
                    style={{color: '#10bb69'}}
                    className='mx-3'
                    onClick={() => actions.download()}/>
                </Tooltip>
                <Tooltip title="מחיקה">
                  <DeleteOutlined
                    style={{color: 'red'}}
                    onClick={() => actions.remove()}
                  />
                </Tooltip>
              </Col>
            </Row>
          }}
          beforeUpload={(file) => {
            return false;
          }}>
          <Button icon={<UploadOutlined/>}>בחר קובץ</Button>
        </Upload>
      </Form.Item>
    )
  }

  const displayRadio = (field: any) => {
    return (
      <Form.Item
        name={["dynamic_fields", field.name]}
        label={field.label}
        rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
        <Radio.Group>
          {
            field.items.map((item: any) => (
              <Radio key={item.id} value={item.id}>{item.label}</Radio>
            ))
          }
        </Radio.Group>
      </Form.Item>
    )
  }

  const displayCheckbox = (field: any) => {
    return (
      <Form.Item
        name={["dynamic_fields", field.name]}
        valuePropName="checked"
        rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
        <Checkbox>
          {field.label}
        </Checkbox>
      </Form.Item>
    )
  }

  const displaySelect = (field: any) => {
    return (
      <Form.Item
        name={["dynamic_fields", field.name]}
        label={field.label}
        rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
        <Select>
          {
            field.items.map((item: any) => (
              <Select.Option key={item.id} value={item.id}>{item.label}</Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    )
  }

  const handleSelectGroupClient = (value: string) => {
    const client = selectOptionClients.filter((c: any) => c.value === value)[0].client;
    setClient(client)
    if (client.products.length > 0)
      createTemplateProductsOptions(client.products, true);
    else
      setSelectOptionProducts([]);
  }

  const handleSelectGroupProducts = (value: string) => {
    let product: any = {};
    if (props.clientId)
      product = props.task.filter((product: any) => value === product.productType.id)[0];
    else
      product = client?.products.filter((product: any) => value === product.productType.id)[0];
    createTemplatePoliciesOptions(product.policies);
  }

  const handleSelectGroupTask = (value: string) => {
    getTaskType({type: value}).then((properties: any) => {

      // check if subtype exist
      if (properties.length > 0 && properties[0].subtype) {
        createTemplateSubTasksOptions(properties);
      } else {
        // if subtype not exist - get dynamic fields of type
        if (properties.length > 0) {
          setDynamicFields(properties[0].fields.filter((property: any) => property.active));
          setProperty(properties[0]._id);
        }
      }
    })
  }

  const handleSelectGroupSubTask = (value: string) => {
    getTaskType({
      type: form.getFieldValue('task'),
      subtype: value
    }).then(properties => {
      setDynamicFields(properties.fields.filter((property: any) => property.active))
      setProperty(properties._id)
    })
  }

  const onOpened = () => {
    loadData();
  }

  useEffect(() => {
    if (props.visible)
      onOpened();
    return () => {
    }
  }, [props.visible])

  return (
    <Modal
      title={props.isNew ? "צור משימה" : "עדכן משימה"}
      open={props.visible}
      afterClose={handleAfterClose}
      onCancel={() => props.onCancel()}
      onOk={form.submit}
      maskClosable={false}
      destroyOnClose
      okText={props.isNew ? "צור" : "עדכן"}
      cancelText="ביטול"
      okButtonProps={{disabled: loading}}
      cancelButtonProps={{disabled: false}}
      width={1000}>

      <Loader.LoadingContainer loading={loading} tip={loadingMsg}>
        <Form form={form}
              onValuesChange={(values: any) => {
                if (values.client) {
                  form.resetFields(["product", "policy"]);
                  handleSelectGroupClient(values.client)
                }

                if (values.task) {
                  form.resetFields(["dynamic_fields", "subtask"]);
                  setDynamicFields([]);
                  setSelectOptionSubTasks([])
                  handleSelectGroupTask(values.task)
                }

                if (values.subtask) {
                  handleSelectGroupSubTask(values.subtask)
                  form.resetFields(["dynamic_fields"]);
                }

                if (values.product) {
                  form.resetFields(["policy"]);
                  handleSelectGroupProducts(values.product)
                }
              }}
              layout="vertical"
              onFinish={props.isNew ? handleCreateTask : handleUpdateTask}>

          <Row gutter={[16, 16]}>
            {/*   <Col sm={8}>
              <Form.Item name="agentId"
                         label='סוכן'
                         rules={!props.isNew || getLoggedInUser().type === 'agent' ? [] : [{
                           required: true,
                           message: 'שדה חובה'
                         }]}>
                <Select disabled={!props.isNew || getLoggedInUser().type === 'agent'}>
                  {selectOptionAgents && selectOptionAgents.length > 0 && selectOptionAgents.map((agent: any, index: any) => (
                      <Select.Option key={index} value={agent.value}>{agent.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>*/}

            {selectOptionTreatments && selectOptionTreatments.length > 0 &&
              <Col sm={8}>
                <Form.Item name="treatment"
                           label='גורם מטפל'
                           rules={[{required: true, message: 'שדה חובה'}]}>
                  <Select>
                    {
                      selectOptionTreatments && selectOptionTreatments.length > 0 && selectOptionTreatments.map((treatment: any, index: any) => (
                        <Select.Option key={index}
                                       value={treatment.value}>{treatment.label}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            }

            {!clientId &&
              <Col sm={8}>
                <Form.Item
                  name="client"
                  label='לקוח'>
                  <Select>
                    {
                      selectOptionClients && selectOptionClients.length > 0 && selectOptionClients.map((client: any, index: any) => (
                        <Select.Option key={index}
                                       value={client.value}>{client.label}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            }

            <Col sm={8}>
              <Form.Item name="product" label='מוצר'>
                <Select disabled={!form.getFieldValue('client') &&
                  (!props.isNew || props.isSelected || !clientId)}>
                  {
                    selectOptionProducts && selectOptionProducts.length > 0 && selectOptionProducts.map((product: any, index: any) => (
                      <Select.Option key={index} value={product.value}>{product.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item name="policy" label='פוליסה'>
                <Select disabled={!form.getFieldValue('product') || !props.isNew || props.isSelected}>
                  {
                    selectOptionPolicies && selectOptionPolicies.length > 0 && selectOptionPolicies.map((policy: any, index: any) => (
                      <Select.Option key={index} value={policy.value}>{policy.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item
                name="task"
                label='משימה'
                rules={[{required: true, message: 'שדה חובה'}]}>
                <Select disabled={!props.isNew}>
                  {
                    selectOptionTasks && selectOptionTasks.length > 0 && selectOptionTasks.map((task: any, index: any) => (
                      <Select.Option key={index} value={task.value}>{task.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            {selectOptionSubTasks && selectOptionSubTasks.length > 0 && <Col sm={8}>
              <Form.Item
                name="subtask"
                label='תת משימה'
                rules={!form.getFieldValue('task') || selectOptionSubTasks.length === 0 ? [] : [{
                  required: true,
                  message: 'שדה חובה'
                }]}>
                <Select
                  disabled={!props.isNew || !form.getFieldValue('task') || selectOptionSubTasks.length === 0}>
                  {selectOptionSubTasks.map((subtask: any, index: any) => (
                    <Select.Option key={index} value={subtask.value}>{subtask.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>}
            <Col sm={8}>
              <Form.Item
                name="status"
                label='מצב משימה'
                rules={!form.getFieldValue('task') || selectOptionSubTasks.length === 0 ? [] : [{
                  required: true,
                  message: 'שדה חובה'
                }]}>
                <Select defaultValue={props.task.status}
                        style={{width: '100%'}}
                        options={statuses}>
                </Select>
              </Form.Item>
            </Col>

          </Row>

          <Row gutter={[16, 16]}>
            {dynamicFields && dynamicFields.length > 0 &&
              <>
                <Col lg={24}>
                  <Text type="warning">שדות</Text>
                </Col>
                {dynamicFields.map((field: any, index: number) => (
                  <Col key={index} lg={8}>
                    {field.type === 'text' && displayInputText(field)}
                    {field.type === 'email' && displayInputEmail(field)}
                    {field.type === 'number' && displayInputNumber(field)}
                    {field.type === 'tel' && displayInputTel(field)}
                    {field.type === 'date' && displayDate(field)}
                    {field.type === 'file' && displayFile(field)}
                    {field.type === 'radio' && displayRadio(field)}
                    {field.type === 'checkbox' && displayCheckbox(field)}
                    {field.type === 'select' && displaySelect(field)}
                  </Col>
                ))}
              </>
            }
          </Row>

          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              blockType: {
                className: 'icon-side',
              },
              fontSize: {
                className: 'icon-side',
              },
              fontFamily: {
                className: 'icon-side',
              },
            }}
          />
        </Form>
      </Loader.LoadingContainer>
    </Modal>
  );

};

export default AddEditTaskModal;
