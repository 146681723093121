import React, {useRef, useState} from 'react';
import {Button, Card, Col, Row, Tooltip} from "antd";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import {getCompanies} from "../../../services/company";
import {ActionType, ProColumns} from "@ant-design/pro-components";
import ViewCompanyModal from "./ViewCompanyModal";
import CompanyModal from "./CompanyModal";
import {isAdmin} from "../../../services/auth";
import {Redirect} from "react-router-dom";
import Breadcrumbs from "../../../components/antDesign/Breadcrumb";
import Table from "../../../components/antDesign/ProTable";

const BreadcrumbsItems = {
  title: "רשימת חברות",
  breadcrumbs: [{item: "רשימת חברות"}, {item: "חברות"}]
}

const CompaniesList: React.FC = () => {

  const [companyViewModalShow, setCompanyViewModalShow] = useState<boolean>(false);
  const [companyDataModal, setCompanyDataModal] = useState<any>({});
  const [companyModalShow, setCompanyModalShow] = useState<boolean>(false);
  const [isNew, setNew] = useState<boolean>(false);

  const actionRef = useRef<ActionType>();

  const columns: ProColumns[] = [
    {
      align: 'right',
      sorter: (a: any, b: any) => a.idType ? a.idType.localeCompare(b.idType) : false,
      title: "סוג מזהה",
      dataIndex: 'idType',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.idTypeName ? a.idTypeName.localeCompare(b.idTypeName) : false,
      title: "שם מזהה",
      dataIndex: 'idTypeName',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.idNumber ? a.idNumber.localeCompare(b.idNumber) : false,
      title: "מזהה",
      dataIndex: 'idNumber',
    },
    {
      align: 'right',
      sorter: (a: any, b: any) => a.name ? a.name.localeCompare(b.name) : false,
      title: "שם",
      dataIndex: 'name',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.contactFullName ? a.contactFullName.localeCompare(b.contactFullName) : false,
      title: "איש קשר",
      dataIndex: 'contactFullName',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.contactMobile ? a.contactMobile.localeCompare(b.contactMobile) : false,
      title: "טלפון",
      dataIndex: 'contactMobile',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.contactEmail ? a.contactEmail.localeCompare(b.contactEmail) : false,
      title: "אימייל",
      dataIndex: 'contactEmail',
    }, {
      align: 'right',
      title: "פעולות",
      dataIndex: 'actions',
      render: (_: any, record: any) => [
        <div key={record._id}>
          <Tooltip title='עריכה'>
            <Button
              type='link'
              onClick={() => {
                setCompanyDataModal(record);
                setNew(false);
                setCompanyModalShow(true);
              }}>
              <EditOutlined/>
            </Button>
          </Tooltip>

          <Tooltip title='צפייה'>
            <Button
              type='link'
              onClick={() => {
                console.log(record)
                setCompanyDataModal(record);
                setCompanyViewModalShow(true);
              }}>
              <EyeOutlined/>
            </Button>
          </Tooltip>
        </div>
      ]
    }
  ];

  const loadDataTable = async (params: any) => {

    if (!isAdmin()) {
      return (<Redirect to={{pathname: "/pages-401"}}/>);
    }

    return await getCompanies(params).then((response) => {
      const dataTable = response.list.map((company: any) => {
        return {
          _id: company._id,
          idType: company.idType,
          idTypeName: company.idType === 1 ? 'ע.מ' : 'ח.פ',
          idNumber: company.idNumber,
          name: company.name,
          contactFullName: company.contactFirstName + ' ' + company.contactLastName,
          contactMobile: company.contactMobile,
          contactEmail: company.contactEmail,
          address: company.address,
          city: company.city,
          contactFirstName: company.contactFirstName,
          contactLastName: company.contactLastName,
          contactPhone: company.contactPhone,
          createdAt: company.createdAt,
          updatedAt: company.updatedAt,
          zipCode: company.zipCode,
        }
      })
      return {
        status: response.status,
        current: response.current,
        total: response.total,
        pageSize: response.pageSize,
        data: dataTable,
      };
    })
  }

  const closeViewCompanyModal = () => {
    setCompanyViewModalShow(false);
    setNew(false);
  }

  const closeCompanyModal = (refresh: boolean) => {
    if (refresh) {
      actionRef.current?.reload();
    }

    setCompanyModalShow(false);
    setNew(false);
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title={BreadcrumbsItems.title} breadcrumbs={BreadcrumbsItems.breadcrumbs}/>

        <Row>
          <Col xs={24}>
            <Card>
              <Button
                type="primary"
                style={{height: 40}}
                onClick={() => {
                  setCompanyModalShow(true);
                  setNew(true);
                  setCompanyDataModal({type: 'agent'});
                }}>
                <i className="mdi mdi-plus mr-2"/>
                צור חברה</Button>
            </Card>
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xs={24}>
            <Table
              actionRef={actionRef}
              request={loadDataTable}
              // @ts-ignore
              columns={columns}
              totalText='חברות'
              showPagination={true}
            />
          </Col>
        </Row>

        <ViewCompanyModal visible={companyViewModalShow} companyDataModal={companyDataModal}
                          onClose={() => closeViewCompanyModal()}/>
        <CompanyModal visible={companyModalShow}
                      companyDataModal={companyDataModal} isNew={isNew}
                      onClose={(refresh: boolean) => closeCompanyModal(refresh)}/>
      </div>
    </React.Fragment>
  );
};

export default CompaniesList;
