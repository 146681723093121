import React, {Component} from 'react';

import {
    Row,
    Col,
    Button,
    Container, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, CustomInput
} from "reactstrap";
import {withNamespaces} from "react-i18next";
import {getClientWithoutSignature} from "../../../services/client";
import Loader from "../../../components/Common/Loader";
import {addMessage, getMessages, readMessages} from '../../../services/conversation';
import SimpleBar from "simplebar-react";
import {Editor} from "react-draft-wysiwyg";
import EditorState from "draft-js/lib/EditorState";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {convertFromRaw, convertToRaw} from "draft-js";
import {getLoggedInUser} from "../../../services/auth";
import TopMenu from "../../../components/VerticalLayout/Client/TopMenu";
import {Buffer} from 'buffer';

const defaultEditorText = {
    "blocks": [{
        "key": "8b44",
        "text": "",
        "type": "unstyled",
        "depth": 0,
        "inlineStyleRanges": [],
        "entityRanges": [],
        "data": {}
    }], "entityMap": {}
};

class ConversationClient extends Component {

    constructor(props) {
        super(props);
        this.user = getLoggedInUser();

        this.state = {
            initErrorMsg: this.props.t('SIGNATURE.TABS.TAB0.ERROR'),
            client: {
                agent: {}
            },
            preLoader: this.user !== null,
            loader: false,
            totalDoc: 0,
            issueIdDate: undefined,
            verifyType: 'EMAIL',
            errorCodeMsg: '',
            conversation: { // single client and messages
                messages: [],
            },
            Chat_Box_Username: "", // top bar name of client
            curMessage: "", // regular text message
            editorState: EditorState.createEmpty(),
            addSettings: { // button near send button
                isEditorActive: false
            },
            loading: false,
            tokenTemporary: undefined,
            uploadFiles: []
        };
    }

    async getClientData(clientId) {
        let self = this;

        await getClientWithoutSignature({clientId}).then((data) => {
            self.setState({client: Object(data.client)})
        }).catch(err => {
            let _state = self.state;

            if (err[0] === 404) {
                _state.initErrorMsg = 'לינק לא תקין! <br/> פנה לסוכן לקבלת לינק חדש';
            }
            if (err[0] === 500) {
                _state.initErrorMsg = 'אירעה שגיאה <br/> נא נסה שנית מאוחר יותר';
            }
            self.setState(_state);
        });
    }

    componentDidMount() {
        if (this.user !== null && this.user.type === 'client') {
            this.getClientData(this.user.clientId).then(() => {
                this.loadMessages(this.user.clientId)
                this.setState({preLoader: false})
            });
        } else {
            this.props.history.push('/login/client');
        }
    }

    startLoading = () => {
        this.setState({loading: true});
    }

    stopLoading = () => {
        this.setState({loading: false});
    }

    loadMessages = (client_id) => {
        this.startLoading();
        getMessages({client_id}).then(response => {
            if (response.status.toString() === 'SUCCESS') {
                this.readAgentMessages(this.user.clientId);
                this.setState({
                    conversation: response.conversations.length === 0 ?
                        {messages: []} : response.conversations
                }, () => {
                })
            }
            this.stopLoading();
        })
    }

    insertMessage = () => {
        if (this.state.curMessage || this.state.editorState.getCurrentContent().hasText()) {
            let text = {...defaultEditorText};
            text.blocks[0].text = this.state.curMessage;
            text = JSON.stringify(text);

            const files = [];
            this.state.uploadFiles.forEach(file => {
                files.push(file.file);
            })

            const editorText = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
            const body = {
                client_id: this.user.clientId,
                sender: 'CLIENT',
                data: this.state.addSettings.isEditorActive ?
                    editorText : text,
                files: files
            }
            addMessage(body).then(response => {
                if (response.status.toString() === 'SUCCESS') {
                    const conversation = this.state.conversation;

                    conversation.messages.push(response.message);

                    this.readAgentMessages(this.user.clientId);
                    this.setState({
                        conversation,
                        curMessage: '',
                        editorState: EditorState.createEmpty(),
                        uploadFiles: []
                    })
                }
            })
        }
    }

    readAgentMessages = (client_id) => {
        readMessages({client_id: client_id, reader: 'CLIENT'})
            .then((response) => {
                console.log(response)
            })
    }

    onEditorStateChange = (editorState) => {
        this.setState({editorState: editorState});
    }

    removeFile = (index) => {
        let uploadFiles = this.state.uploadFiles;
        uploadFiles.splice(index, 1);
        this.setState({uploadFiles});
    }

    displayFile = (file) => {
        const fileContentBase64 = new Buffer.from(JSON.parse(file.fileContent)).toString("base64")
        return `data:${file.contentType};base64,${fileContentBase64}`
    }

    downloadFile = (file) => {
        const arr = JSON.parse(file.fileContent).data;
        const byteArray = new Uint8Array(arr);
        const blob = new Blob([byteArray], {type: file.contentType});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file.fileName;
        link.click();
    }

    render() {
        let form = this.state.client;

        if (this.state.preLoader) {
            return (<Loader/>)
        }

        return (
            <React.Fragment>
                <TopMenu/>

                <CustomInput id='file'
                             name='file'
                             type='file'
                             hidden
                             onChange={(event) => {
                                 const uploadFiles = this.state.uploadFiles;
                                 if (event.target.files.length > 0) {
                                     const file = event.target.files[0];
                                     uploadFiles.push({
                                         file,
                                         type: file.type,
                                         url: URL.createObjectURL(file)
                                     })
                                     this.setState({uploadFiles})
                                 }
                             }}
                             className="custom-file-input"/>

                <div>
                    <Container fluid className="p-0">
                        <Row className="no-gutters d-flex align-items-center min-vh-100">

                            <Col lg={12} className='px-4'>
                                <div className="text-center mb-5">
                                    <h4 className='text-primary'>התקשרות עם סוכן הביטוח</h4>
                                </div>
                                <div id="basic-pills-wizard" className="twitter-bs-wizard">

                                    <div className="w-100 user-chat mt-4 mt-sm-0">
                                        <div className="p-3 px-lg-4 user-chat-border">
                                            <Row>
                                                <Col md={4} xs={6}>
                                                    <h5 className="font-size-15 mb-1 text-truncate">סוכן: {form.agent.firstName + " " + form.agent.lastName}</h5>
                                                </Col>
                                            </Row>
                                        </div>

                                        {/*All Messages*/}
                                        <div className="px-lg-2">
                                            <div
                                                className="chat-conversation p-3 chat-conversation-height">
                                                <ul className="list-unstyled mb-0 pr-3">
                                                    <SimpleBar ref={simpleBar => {
                                                        this.simpleBar = simpleBar
                                                    }} style={{maxHeight: "450px"}}>

                                                        {
                                                            this.state.loading && (
                                                                <div style={{
                                                                    width: '0%',
                                                                    margin: '0 auto'
                                                                }}>
                                                                    <Spinner color="primary"/>
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            !this.state.loading && this.state.conversation.messages.map((message, key) =>
                                                                <div key={key}>
                                                                    {
                                                                        (key === 0) &&
                                                                        <li className='text-center'>
                                                                            <div
                                                                                style={{padding: '10px'}}>
                                                                                <label>{message.createdAt}</label>
                                                                            </div>
                                                                        </li>
                                                                    }
                                                                    {

                                                                        (key !== 0 && this.state.conversation.messages[key - 1].createdAt !== message.createdAt) &&

                                                                        <li className='text-center'>
                                                                            <div
                                                                                style={{padding: '10px'}}>
                                                                                <label>{message.createdAt}</label>
                                                                            </div>
                                                                        </li>
                                                                    }

                                                                    {/*display files*/}
                                                                    {message.attach.map((file, key) =>
                                                                        <div key={key}>
                                                                            <li className={message.sender === 'CLIENT' ? "right" : ""}
                                                                                style={{paddingRight: "20px"}}>
                                                                                <div className="conversation-list">
                                                                                    {
                                                                                        message.sender === 'AGENT' ?
                                                                                            <div className="chat-avatar">
                                                                                                <span
                                                                                                    className="avatar-title rounded-circle bg-light text-body"
                                                                                                    style={{padding: '8px'}}>
                                                                                                    {
                                                                                                        form.agent.firstName.substring(0, 1) + "" +
                                                                                                        form.agent.lastName.substring(0, 1)
                                                                                                    }
                                                                                                </span>
                                                                                            </div> : null
                                                                                    }

                                                                                    <div className="ctext-wrap">
                                                                                        <div
                                                                                            className="conversation-name">{message.sender === 'AGENT' ? '' : this.state.Chat_Box_Username}</div>
                                                                                        <div className="ctext-wrap-content"
                                                                                             style={
                                                                                                 !file.contentType.startsWith('image') ?
                                                                                                     {
                                                                                                         minWidth: 85,
                                                                                                         padding: '5px 5px',
                                                                                                         width: 200,
                                                                                                         height: 250
                                                                                                     } : {
                                                                                                         minWidth: 85,
                                                                                                         padding: '5px 5px'
                                                                                                     }}>
                                                                                            <div
                                                                                                style={{whiteSpace: "pre-line"}}
                                                                                                className="mb-0">

                                                                                                {/*display image files*/}
                                                                                                {
                                                                                                    file.contentType.startsWith('image') &&
                                                                                                    <div
                                                                                                        className="position-relative">
                                                                                                        <div
                                                                                                            className="position-absolute"
                                                                                                            style={message.sender === 'AGENT' ?
                                                                                                                {
                                                                                                                    left: 5,
                                                                                                                    top: 5
                                                                                                                } : {
                                                                                                                    right: 5,
                                                                                                                    top: 5
                                                                                                                }}>
                                                                                                            <Button
                                                                                                                color="light"
                                                                                                                className="btn-rounded waves-effect mr-1"
                                                                                                                onClick={() => this.downloadFile(file)}>
                                                                                                                <i className="mdi mdi-download cursor-pointer"/>
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                        <img
                                                                                                            src={this.displayFile(file)}
                                                                                                            width='200px'
                                                                                                            height='250px'
                                                                                                            alt={file.fileName}/>
                                                                                                    </div>
                                                                                                }

                                                                                                {/*display another files*/}
                                                                                                {
                                                                                                    !file.contentType.startsWith('image') &&
                                                                                                    <div
                                                                                                        className="position-relative"
                                                                                                        style={{textAlign: 'center'}}>
                                                                                                        <div
                                                                                                            className="position-absolute"
                                                                                                            style={message.sender === 'AGENT' ?
                                                                                                                {
                                                                                                                    left: 5,
                                                                                                                    top: 5
                                                                                                                } : {
                                                                                                                    right: 5,
                                                                                                                    top: 5
                                                                                                                }}>
                                                                                                            <Button
                                                                                                                color="light"
                                                                                                                className="btn-rounded waves-effect mr-1"
                                                                                                                onClick={() => this.downloadFile(file)}>
                                                                                                                <i className="mdi mdi-download cursor-pointer"/>
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                        <i className={"mdi " +
                                                                                                            (file.contentType.endsWith('pdf') ?
                                                                                                                "mdi-file-pdf" : "mdi-file-document")}
                                                                                                           style={{fontSize: 120}}></i>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>

                                                                                        </div>

                                                                                        {
                                                                                            !file.contentType.startsWith('image') &&
                                                                                            <p style={{
                                                                                                fontSize: 12,
                                                                                                width: 200
                                                                                            }}>{file.fileName}</p>
                                                                                        }

                                                                                        <Row>
                                                                                            <Col lg='auto' xl='auto'
                                                                                                 md='auto' xs='auto'
                                                                                                 className={message.sender === 'CLIENT' ? 'ml-auto' : 'mr-auto'}>
                                                                                                <p className="chat-time mb-0">
                                                                                                    <i
                                                                                                        className="mdi mdi-clock-outline align-middle mr-1"/>
                                                                                                    {
                                                                                                        message.sent_before.time
                                                                                                    }
                                                                                                </p>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </div>
                                                                    )}

                                                                    <li className={message.sender === 'CLIENT' ? "right" : ""}
                                                                        style={{paddingRight: "20px"}}>
                                                                        <div
                                                                            className="conversation-list">
                                                                            {
                                                                                message.sender === 'AGENT' ?
                                                                                    <div className="chat-avatar">
                                                                                       <span
                                                                                           className="avatar-title rounded-circle bg-light text-body"
                                                                                           style={{padding: '8px'}}>
                                                                                        {
                                                                                            form.agent.firstName.substring(0, 1) + "" +
                                                                                            form.agent.lastName.substring(0, 1)
                                                                                        }
                                                                                        </span>
                                                                                    </div> : null
                                                                            }

                                                                            <div
                                                                                className="ctext-wrap">
                                                                                <div
                                                                                    className="conversation-name">{message.sender === 'AGENT' ? '' : this.state.Chat_Box_Username}</div>
                                                                                <div
                                                                                    className="ctext-wrap-content">
                                                                                    <div
                                                                                        style={{whiteSpace: "pre-line"}}
                                                                                        className="mb-0">

                                                                                        <Editor
                                                                                            editorState={EditorState.createWithContent(
                                                                                                convertFromRaw(JSON.parse(message.data)))}
                                                                                            readOnly={true}
                                                                                            editorClassName="editor-message"
                                                                                            wrapperClassName="editor-wrapper"
                                                                                            toolbarHidden
                                                                                        />
                                                                                    </div>

                                                                                </div>
                                                                                <p className="chat-time mb-0">
                                                                                    <i
                                                                                        className="mdi mdi-clock-outline align-middle mr-1"/>
                                                                                    {
                                                                                        message.sent_before.time
                                                                                    }
                                                                                </p>
                                                                            </div>

                                                                        </div>
                                                                    </li>
                                                                </div>
                                                            )
                                                        }
                                                    </SimpleBar>
                                                </ul>
                                            </div>

                                        </div>

                                        {/*Send Message*/}
                                        <div className="px-lg-3">
                                            <div className="p-3 chat-input-section ">

                                                {/*add files*/}
                                                {
                                                    this.state.uploadFiles.length > 0 &&
                                                    <Row className='border mb-2' style={{height: 80}}>
                                                        {this.state.uploadFiles.map((file, index) => (
                                                            <Col key={index} lg='auto' xl='auto' md='auto' xs='auto'
                                                                 className='my-auto'>
                                                                <div className="position-relative">
                                                                    {file.type.startsWith('image') &&
                                                                        <>
                                                                            <div className="position-absolute"
                                                                                 style={{left: -15, top: -10}}>

                                                                                <Button
                                                                                    color="light"
                                                                                    className="btn-rounded waves-effect mr-1"
                                                                                    style={{padding: '3px 10px'}}
                                                                                    onClick={() => this.removeFile(index)}>
                                                                                    <i className="mdi mdi-close cursor-pointer"
                                                                                       style={{
                                                                                           color: 'red',
                                                                                           fontSize: 10
                                                                                       }}/>
                                                                                </Button>
                                                                            </div>
                                                                            <img src={file.url} width='70px'
                                                                                 height='70px' alt=""/>
                                                                        </>
                                                                    }

                                                                    {!file.type.startsWith('image') &&
                                                                        <>
                                                                            <div
                                                                                className="position-absolute"
                                                                                style={{left: -15, top: -10}}>
                                                                                <Button
                                                                                    color="light"
                                                                                    className="btn-rounded waves-effect mr-1"
                                                                                    style={{padding: '3px 10px'}}
                                                                                    onClick={() => this.removeFile(index)}>
                                                                                    <i className="mdi mdi-close cursor-pointer"
                                                                                       style={{
                                                                                           color: 'red',
                                                                                           fontSize: 10
                                                                                       }}/>
                                                                                </Button>
                                                                            </div>
                                                                            <i className={"mdi " +
                                                                                (file.type.endsWith('pdf') ?
                                                                                    "mdi-file-pdf" : "mdi-file-document")}
                                                                               style={{fontSize: 50}}></i>
                                                                        </>
                                                                    }
                                                                </div>

                                                            </Col>
                                                        ))
                                                        }
                                                    </Row>
                                                }

                                                <Row>
                                                    <Col>
                                                        <div className="position-relative">
                                                            {
                                                                !this.state.addSettings.isEditorActive &&
                                                                <Input type="text"
                                                                       value={this.state.curMessage}
                                                                       onChange={(e) => {
                                                                           this.setState({curMessage: e.target.value})
                                                                       }}
                                                                       className="form-control chat-input"
                                                                       style={{padding: "0.47rem 0.75rem"}}
                                                                       placeholder={this.props.t("CONVERSATION_PAGES.ENTER_MESSAGE") + "..."}/>

                                                            }

                                                            {
                                                                this.state.addSettings.isEditorActive &&
                                                                <Editor
                                                                    toolbarClassName="toolbarClassName"
                                                                    wrapperClassName="wrapperClassName"
                                                                    editorClassName="editorClassName"
                                                                    editorState={this.state.editorState}
                                                                    onEditorStateChange={this.onEditorStateChange}
                                                                />
                                                            }
                                                        </div>
                                                    </Col>

                                                    <Col xs={{size: 'auto'}}
                                                         style={{marginTop: 'auto'}}>
                                                        <Dropdown
                                                            isOpen={this.state.singlebtn1}
                                                            toggle={() =>
                                                                this.setState({singlebtn1: !this.state.singlebtn1})
                                                            }
                                                        >
                                                            <DropdownToggle color="light" caret
                                                                            style={{borderRadius: '50%'}}>
                                                                <i className="mdi mdi-plus"/>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem
                                                                    onClick={() => this.setState(
                                                                        {
                                                                            addSettings: {
                                                                                isEditorActive:
                                                                                    !this.state.addSettings.isEditorActive
                                                                            }
                                                                        })}>{this.props.t('CONVERSATION_CLIENT_PAGES.TEXT_BOX')}
                                                                </DropdownItem>
                                                                <DropdownItem onClick={() => {
                                                                    document.getElementById('file').click()
                                                                }}>{this.props.t('CONVERSATION_CLIENT_PAGES.ADD_FILE')}</DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </Col>

                                                    <Col xs={{size: 'auto'}}
                                                         style={{marginTop: 'auto'}}>

                                                        <Button
                                                            onClick={() => this.insertMessage()}
                                                            type="submit" color="primary"
                                                            className="chat-send w-md waves-effect waves-light">

                                                            <i className="mdi mdi-send"/>
                                                            <span
                                                                className="d-none d-sm-inline-block ml-2">
                                                        {this.props.t("CONVERSATION_PAGES.SENT")}</span></Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(ConversationClient);
