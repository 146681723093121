import jwt_decode from "jwt-decode";
import baseService from "../base";
import axios from "../axios";
const authorizedType: {
  AGENT: string;
  ADMIN: string;
  ADMIN_COMPANY: string;
  TREATMENT: string;
  CLIENT: string;
} = {
  AGENT: 'agent',
  ADMIN: 'admin',
  ADMIN_COMPANY: 'admin-company',
  TREATMENT: 'treatment',
  CLIENT: 'client',
}
const authorized = (types: API.AuthorizedType[]) => {
  const user: any = getLoggedInUser();

  if (!user)
    return false;

  if (user.isAdmin && types.includes(authorizedType.ADMIN as API.AuthorizedType))
    return true;

  return types.includes(user.type);
}
const getDecodeUserToken = (authUser: string) => {
  return jwt_decode(JSON.parse(authUser).user.token);
}
// ############### auth user ######################
const setAuthUser = (token: any) => {
  localStorage.setItem('authUser', JSON.stringify(token));
}
const logout = () => {
  localStorage.removeItem('authUser');
}
// Gets the logged in user data from local session
const getLoggedInUser = (): any => {
  const user = localStorage.getItem('authUser');

  if (user) {
    return getDecodeUserToken(user);
  }

  return null;
}
const getUserId = (): any => {
  const user = localStorage.getItem('authUser');

  if (user) {
    const decoded: any = getDecodeUserToken(user);
    return decoded.userId || undefined;
  }

  return null;
}
const getLoggedInUserId = () => {
  const user = localStorage.getItem('authUser');

  if (user) {
    const decoded: any = getDecodeUserToken(user);

    // if type is client the id of user is clientId
    if (decoded.type === "client")
      return decoded.clientId;

    return decoded.id;
  }

  return null;
}
//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
}
//is admin
const isAdmin = () => {
  const user = localStorage.getItem('authUser');

  if (user) {
    const decoded: any = getDecodeUserToken(user);
    return decoded.isAdmin;
  }

  return false;
}
//get user type -> employee/agent
const getUserType = () => {
  const user = localStorage.getItem('authUser');

  if (user) {
    const decoded: any = getDecodeUserToken(user);
    return decoded.type;
  }

  return null;
}
//get user token
const getUserToken = () => {
  const user = localStorage.getItem('authUser');

  if (user) {
    return JSON.parse(user).user.token;
  }

  return null;
}
// ############### end auth user ######################
// Login Method
const postLogin = async (data: API.LoginParam) => {
  let url = baseService.baseUrl + baseService.endpoints.user.login;
  return await axios.POST(url, data).catch();
}
export {
  setAuthUser, logout, getLoggedInUser, isUserAuthenticated, postLogin,
  getUserType, isAdmin, getUserToken, getLoggedInUserId, getUserId,
  authorized, authorizedType
}
