import React, {Component} from "react";
import {Row, Col, Card, Form, CardBody, Button, Progress} from "reactstrap";
import Dropzone from "react-dropzone";

//i18n
import {withNamespaces} from 'react-i18next';
import {uploadFiles} from "../../../services/admiringFactor/filesService";

class Uploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: []
        };

        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
                percentage: 0,
                responseMsg: '',
                responseStatus: null
            })
        );

        this.setState({selectedFiles: files});
    };

    removeFile = index => {
        let selectedFiles = this.state.selectedFiles.filter((file, i) => {
            return i !== index;
        });

        this.setState({selectedFiles: selectedFiles.length === 0 ? [] : selectedFiles});
    }

    uploadFiles = async () => {
        const selectedFiles = this.state.selectedFiles;

        this.setState({
            selectedFiles: selectedFiles.map((file) => {
                const items = [25, 50, 75];
                file.percentage = items[Math.floor(Math.random() * items.length)];
                return file;
            })
        });

        try {
            const response = await uploadFiles({files: this.state.selectedFiles});

            this.setState({
                selectedFiles: selectedFiles.map((file) => {
                    file.percentage = 100;

                    const resFileStatus = response.data.filter((res) => {
                        return res.file.originalname === file.path;
                    })[0];
                    file.responseMsg = resFileStatus.data.displayMessage;
                    file.responseStatus = resFileStatus.status;
                    return file;
                })
            });
        } catch (error) {
        }
    }

    clear = () => this.setState({selectedFiles: []});

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    render() {
        return (
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <Form>
                                {this.state.selectedFiles.length === 0 &&
                                    <Dropzone
                                        onDrop={acceptedFiles => {
                                            const allowFormat = ["dat", "xml"];
                                            const disallowFormat = acceptedFiles.filter(file => {
                                                const ext = file.name.split('.').pop().toLowerCase().trim();
                                                return !allowFormat.includes(ext);
                                            })

                                            if (disallowFormat.length > 0) {
                                                this.props.showErrorMessage(this.props.t("ADMIRING_FACTOR.FILES.ERROR.ACCEPT_TYPE"));
                                                return;
                                            }

                                            this.handleAcceptedFiles(acceptedFiles)
                                        }
                                        }
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <div className="dropzone">
                                                <div
                                                    className="dz-message needsclick mt-2"
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    <div className="mb-3">
                                                        <i className="display-4 text-muted ri-upload-cloud-2-line"/>
                                                    </div>
                                                    <h4>{this.props.t("ADMIRING_FACTOR.FILES.DROPZONE_TEXT")}</h4>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                }
                                <div
                                    className="dropzone-previews mt-3"
                                    id="file-previews"
                                >
                                    {this.state.selectedFiles.map((file, index) => {
                                        let processorBarColor = file.responseStatus === null ? 'warning' :
                                            (file.responseStatus === 'success' ? 'success' : 'danger');
                                        return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={index + "-file"}
                                            >
                                                <div className="p-2">
                                                    <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                            <i className="ri-attachment-line"/>
                                                        </Col>
                                                        <Col className="col-3">
                                                            <label className="text-muted font-weight-bold mb-0">
                                                                {file.name}
                                                            </label>
                                                            <p className="mb-0">
                                                                <strong>{file.formattedSize}</strong>
                                                            </p>
                                                        </Col>
                                                        <Col className="col-auto">
                                                            <i className="ri-delete-bin-2-fill text-danger"
                                                               onClick={() => this.removeFile(index)}/>
                                                        </Col>
                                                        {file.percentage > 0 &&
                                                            <Col className="col-4">
                                                                <Progress color={processorBarColor}
                                                                          value={file.percentage}></Progress>
                                                            </Col>
                                                        }
                                                        {file.responseStatus &&
                                                            <Col className="col-auto">
                                                                <i className={
                                                                    file.responseStatus === 'success' ?
                                                                        "ri-check-fill text-success font-size-24" : "ri-close-fill text-danger font-size-24"
                                                                }
                                                                />
                                                            </Col>
                                                        }
                                                        <Col className="col-auto">
                                                            <label className={
                                                                file.responseStatus === 'success' ?
                                                                    "text-success font-weight-bold mb-0" : "text-danger font-weight-bold mb-0"
                                                            }>

                                                                {file.responseMsg}
                                                            </label>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                        );
                                    })}
                                </div>
                            </Form>

                            <div className="text-right mt-4">
                                {this.state.selectedFiles.length > 0 &&
                                    <Button color="light" type="button"
                                            className="waves-effect waves-light mr-lg-2 w-md"
                                            onClick={() => this.clear()}>{this.props.t("BUTTON.CLEAR")}</Button>
                                }
                                {this.state.selectedFiles.length > 0 &&
                                    <Button color="primary" type="button" className="waves-effect waves-light w-md"
                                            disabled={this.state.selectedFiles[0].percentage > 0}
                                            onClick={() => this.uploadFiles()}>{this.props.t("BUTTON.UPLOAD")}</Button>
                                }

                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default withNamespaces()(Uploader);
