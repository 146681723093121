import React from 'react';
import {Col, Modal, Row, Typography} from "antd";

const {Text} = Typography;

interface Props {
  title: string;
  message: string;
  visible: boolean;
  okText: string;
  onOk: () => void;
  cancelText: string;
  onCancel: () => void;
}

const DeleteModal: React.FC<Props> = (props) => {
  return (
    <Modal
      title={props.title}
      open={props.visible}
      onCancel={() => props.onCancel()}
      onOk={() => props.onOk()}
      maskClosable={false}
      destroyOnClose
      okText={props.okText}
      cancelText={props.cancelText}
      width={700}>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Text>{props.message}</Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default DeleteModal;
