import React from 'react';
import {Col, Modal, Row, Typography} from "antd";

const {Text} = Typography;

interface Props {
  visible: boolean;
  manufacturerDataModal: MANUFACTURER.ManufacturerParam;
  onClose: () => void;
}

const ViewManufacturerModal: React.FC<Props> = (props) => {
  return (
    <Modal
      title={"פרטי יצרן"}
      open={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      maskClosable={false}
      cancelButtonProps={{style: {display: 'none'}}}
      destroyOnClose
      okText="סגור"
      width={1000}>

      <Row gutter={[16, 16]} className='my-3'>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Text strong type="warning">כללי</Text>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12}>
          <div><Text strong>יצרנית</Text></div>
          <div><Text>{props.manufacturerDataModal?.manufacturer_name || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12}>
          <div><Text strong>ח.פ</Text></div>
          <div><Text>{props.manufacturerDataModal?.business_number || '-'}</Text></div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewManufacturerModal;
