import React, {useEffect, useState} from 'react';
import {getClientWithoutSignature} from "../../../../services/client";
import {Col, Row, Typography} from "antd";
import {Link} from "react-router-dom";
import logo from "../../../../assets/images/logo.png";
import Loader from "../../../../components/antDesign/Loader";
import Tabs from "../../../../components/antDesign/Tabs";
import Result from "./components/Result";
import SendCode from "./tabs/SendCode";
import VerifyCode from "./tabs/VerifyCode";
import Messages from "../../../../components/antDesign/Message";

const {Text} = Typography;

const Login: React.FC = () => {

  const [clientId, setClientId] = useState<string>('');
  const [client, setClient] = useState<any>({});
  const [tokenTemporary, setTokenTemporary] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(1);

  const getClientId = () => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('cid');
    if (id) {
      setClientId(id);
    }
  }

  const getClientData = async () => {
    getClientWithoutSignature({clientId}).then((data) => {
      if (!data ||
        Object.keys(data).length === 0 ||
        Object.keys(data.client).length === 0) {
        setTab(0);
        setLoading(false);
        return;
      }

      setClient(Object(data.client))
      setLoading(false);
    }).catch(err => {
      setTab(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    getClientId();
    return () => {
    };
  }, [])

  useEffect(() => {
    if (clientId) {
      getClientData().then(() => {
        setLoading(false);
      });
    }
  }, [clientId])

  const items = [
    { label: <></>, key: '0', children: <Result type='error'/> },
    { label: <></>, key: '1', children: <SendCode
        client={client}
        beforeSendCode={() => setLoading(true)}
        afterSendCode={(tokenTemporary) => {
          setTokenTemporary(tokenTemporary);
          setTab(2);
          setLoading(false);
        }}
        onFailed={() => Messages.Error('שליחת קוד האימות נכשלה').then(() => {
        })}/> },
    { label: <></>, key: '2', children: <VerifyCode
        client={client}
        tokenTemporary={tokenTemporary || ''}/> },
    { label: <></>, key: '3', children: 'Content of Tab Pane 3' },
  ]

  return (
    <React.Fragment>
      <Loader.LoadingContainer loading={loading} tip={'טוען...'}>
        <Row
          style={{height: '100vh', width: '30%'}}
          className='mx-auto'
          justify="space-around"
          align="middle">
          <Col lg={24} md={24} sm={24} xs={24} className='text-center'>
            <Row>
              <Col lg={24} md={24} sm={24} xs={24}>
                <div>
                  <Link to="/" className="logo"><img src={logo}
                                                     alt="logo"/></Link>
                </div>
                <h4 className="font-size-18 mt-4">היי, טוב לראות אותך</h4>
                <p className="text-muted">התחבר כדי להמשיך למערכת קליק אג׳נט</p>
              </Col>

              <Col lg={24} md={24} sm={24} xs={24}>
                <Tabs activeKey={tab.toString()} items={items} />
              </Col>

              <Col lg={24} md={24} sm={24} xs={24} className='mt-3'>
                <div><Text>© כל הזכויות שמורות לקליק אג׳נט</Text></div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Loader.LoadingContainer>
    </React.Fragment>
  );
};

export default Login;
