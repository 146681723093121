import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Modal, Result, Row, Spin, Typography} from "antd";
import {
  connectToPensionInformationFromMountainInsurance,
  updatePhoneCodeMountainInsurance
} from "../../../../../services/user";
import {LoadingOutlined} from "@ant-design/icons";
import AlertMessage from "../../../../antDesign/Alert";
import SocketIO from "../../../../../services/socketIO";
import PATTERNS from "../../../../../helpers/Patterns";

const {Text} = Typography;

interface Props {
  visible: boolean;
  userId: string | undefined;
  client: CLIENT.ClientMountainInsurance;
  onClose: (refresh: boolean) => void;
}

const ConnectMountainInsurance: React.FC<Props> = (props) => {

  const [step, setStep] = useState<number>(1);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>('');

  const [form] = Form.useForm();

  const handleConnect = (values: any) => {
    setError(undefined);
    if (step === 1) {
      setLoadingText('מתחבר לשירות...');
      setLoading(true);
      connectToPensionInformationFromMountainInsurance(values)
        .then(response => {
          if (response.status.toString() === 'SUCCESS') {
            setStep(3);
          } else {
            setError(response.msg);
          }
          setLoading(false);
        }).catch(() => {
        setError('משהו השתבש, אנא נסה שוב מאוחר יותר');
        setLoading(false);
      });
    } else if (step === 2) {
      setLoadingText('אימות הקוד והורדת המידע הפנסיוני של הלקוח...');
      setLoading(true);
      updatePhoneCodeMountainInsurance(values)
        .then(response => {
          if (response.status.toString() === 'ERROR') {
            setError(response.msg);
            setLoading(false);
          }
        }).catch(() => {
          setError('משהו השתבש, אנא נסה שוב מאוחר יותר');
          setLoading(false);
      });
    }
  }

  const resetValues = () => {
    setError(undefined);
    setLoading(false);
    setStep(1);
    form.setFieldsValue({
      idNumber: undefined,
      password: undefined,
    })
  }

  const handleAfterClose = () => {
    resetValues()
  }

  const onModalOpen = () => {
    form.setFieldsValue({
      idNumber: props.userId,
      password: undefined,
    })
  }

  useEffect(() => {
    if (props.visible) {
      onModalOpen();
    }
    return () => {
    };
  }, [props.visible])

  // socket
  useEffect(() => {
    SocketIO.subscribeToInsertPhoneCodeMountainInsurance(async (err) => {
      if (err) return;
      console.log('subscribeToInsertPhoneCodeMountainInsurance');
      setStep(2);
      setLoading(false);
    })
    return () => {
    };
  }, [])

  return (
    <Modal
      title={"קבלת מידע פנסיוני מהר הביטוח"}
      open={props.visible}
      afterClose={handleAfterClose}
      onCancel={() => props.onClose(false)}
      onOk={form.submit}
      maskClosable={false}
      forceRender
      destroyOnClose
      footer={step === 3 ? [
        <Button type='primary' key="complete" onClick={() => {
          props.onClose(true);
        }}>
          סיום
        </Button>,
      ] : [
        <Button type='primary' disabled={loading} key="next" onClick={() => {
          form.submit();
        }}>
          המשך
        </Button>,
      ]}
      width={1000}>

      <Form form={form}
            autoComplete='off'
            layout="vertical"
            onFinish={(values) => {
              switch (step) {
                case 1: // id and password
                  handleConnect({idNumber: values.idNumber, password: values.password});
                  break;
                case 2: // verify
                  handleConnect({phoneCode: values.phoneCode})
                  break;
              }
            }}>

        <Row gutter={[16, 16]}>
          {loading &&
              <>
                  <Col lg={24} md={24} sm={24} xs={24}
                       style={{textAlign: "center", paddingRight: 50, paddingLeft: 50, paddingTop: 50}}>
                      <Spin indicator={<LoadingOutlined style={{fontSize: 40}} spin/>}/>
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24} style={{textAlign: "center", paddingTop: 20}}>
                      <Text type="secondary">{loadingText}</Text>
                  </Col>
              </>
          }

          {!loading &&
              <>
                {step === 1 &&
                    <>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                label={"מס׳ זהות"}
                                rules={[
                                  {required: true, message: "שדה חובה"},
                                  {pattern: PATTERNS.NUMBER_ID, message: "ת.ז צריכה להכיל 9 ספרות"}]}
                                name='idNumber'>
                                <Input placeholder="מס׳ זהות"/>
                            </Form.Item>
                        </Col>

                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="סיסמה"
                                rules={[{required: true, message: "שדה חובה"}]}
                                name='password'>
                                <Input.Password placeholder="סיסמה"/>
                            </Form.Item>
                        </Col>
                    </>
                }

                {step === 2 &&
                    <>
                        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
                            <Text>ברגעים אלו נשלח אליך קוד אימות לנייד</Text>
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                label="קוד אימות"
                                rules={[
                                  {required: true, message: "שדה חובה"},
                                  {min: 6, message: 'קוד האימות צריך להכיל 6 ספרות'},
                                  {max: 6, message: 'קוד האימות צריך להכיל 6 ספרות'}]}
                                name='phoneCode'>
                                <Input placeholder="קוד אימות"/>
                            </Form.Item>
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Text type="secondary">קוד האימות תקף ל - 6 דקות</Text>
                        </Col>
                    </>
                }
              </>
          }

          {step === 3 &&
            <>
                <Col lg={24} md={24} sm={24} xs={24} className="my-3" style={{textAlign: "center"}}>
                    <Result
                        status="success"
                        title="המידע הפנסיוני נקלט בהצלחה"
                        subTitle={"המידע הפנסיוני של הלקוח - " + props.client?.firstName + " " + props.client?.lastName + " נקלט בהצלחה"}/>

                </Col>
            </>
          }
          {error &&
              <Col lg={24} md={24} sm={24} xs={24}>
                  <AlertMessage type='error' message={error || ''} showIcon/>
              </Col>
          }

        </Row>

      </Form>
    </Modal>
  );
};

export default ConnectMountainInsurance;
