import React, {useEffect, useState} from 'react';
import {isUserAuthenticated, setAuthUser} from "../../../services/auth";
import {getUser, userRegister} from "../../../services/user";
import {useHistory} from "react-router-dom";
import {Button, Col, Form, Input, Row} from "antd";
import Main from "./components/Main";
import AlertMessage from "../../../components/antDesign/Alert";
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import {LockOutlined, UserOutlined} from "@ant-design/icons";

const Register: React.FC = () => {

  const [name, setName] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');

  const [form] = Form.useForm();
  const {isMobile} = useDeviceDetect();
  const history = useHistory();

  const getUserParams = () => {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('id');
    const email = params.get('email');
    const name = params.get('name');

    form.setFieldsValue({email, userId});
    setName(name || '')
  }

  const getUserDetails = () => {
    getUser({userId: form.getFieldValue('userId')}).then((data) => {
      if (data.user.isCompleteRegistration === true) {
        if (isUserAuthenticated()) {
          history.push('/dashboard');
        } else {
          history.push('/login');
        }
      }
    });
  }

  const handleSubmit = (values: any) => {
    setError(false);
    userRegister({user: values}).then(response => {
      if (response.status &&
        response.status.toString() === 'SUCCESS') {
        setAuthUser({user: response.user})
        history.push('/dashboard');
      } else {
        setError(true);
      }
    });
  }

  useEffect(() => {
    if (form.getFieldValue('userId')) {
      getUserDetails();
    }
    return () => {
    };
  }, [form.getFieldValue('userId')])

  useEffect(() => {
    getUserParams();
    return () => {
    }
  }, [])

  const validatePassword = async (rule: any, value: any, callback: any) => {
    if (value && value !== newPassword) {
      throw new Error('Something wrong!');
    }
  };

  return (
    <Main>
      <div className="text-center">
        <h4 className="font-size-18 mt-4">{"היי, " + name}</h4>
        <p className="text-muted">בפעם הראשונה לכניסה למערכת עלייך להזין
          את הסיסמה הזמנית שקיבלת במייל ולהחליפה לסיסמה אישית שלך</p>
      </div>

      {
        error &&
          <Row className='mt-4 mb-3'
               style={!isMobile ? {
                 marginRight: 31,
                 marginLeft: 31,
               } : {
                 marginRight: 8,
                 marginLeft: 8,
               }}>
              <Col xl={24} lg={24} md={24} sm={24} xs={24} className='text-left'>
                  <AlertMessage type="error" message='פעולה נכשלה' showIcon/>
              </Col>
          </Row>
      }

      <div className={"p-2 text-left " + (!error ? 'mt-5' : '')}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}>

          <Form.Item
            name='userId'
            hidden>
            <Input hidden/>
          </Form.Item>

          <Row className={!isMobile ? 'mx-4' : ''}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                name='email'>
                <Input placeholder="שם משתמש"
                       disabled={true}
                       prefix={<UserOutlined className="site-form-item-icon"/>}/>
              </Form.Item>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                rules={[{required: true, message: 'אנא הכנס סיסמה זמנית'}]}
                name='tempPassword'>
                <Input type="password" placeholder="סיסמה זמנית"
                       prefix={<LockOutlined className="site-form-item-icon"/>}/>
              </Form.Item>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                rules={[{required: true, message: 'אנא הכנס סיסמה חדשה'}]}
                name='password'>
                <Input type="password"
                       placeholder="סיסמה חדשה"
                       onChange={(event) => setNewPassword(event.target.value)}
                       prefix={<LockOutlined className="site-form-item-icon"/>}/>
              </Form.Item>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                rules={
                  [
                    {required: true, message: 'אנא הכנס אימות סיסמה חדשה'},
                    {validator: validatePassword, message: 'סיסמה ואימות סיסמה לא שווים'}
                  ]
                }
                name='rePassword'>
                <Input type="password" placeholder="אימות סיסמא חדשה"
                       prefix={<LockOutlined className="site-form-item-icon"/>}/>
              </Form.Item>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="mt-4 text-center">
                <Button type="primary"
                        className='px-4'
                        htmlType="submit">כניסה</Button>
              </div>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="mt-5 text-center">
                <p>© כל הזכויות שמורות לקליק אג׳נט</p>
              </div>
            </Col>
          </Row>
        </Form>
      </div>

    </Main>
  );
};

export default Register;
