import baseService from "../base";
import axios from "../axios";

export const getManufacturersList = async (data: MANUFACTURER.ManufacturerTableParam) => {
  let url = baseService.baseUrl + baseService.endpoints.manufacturers.getAll;
  url = url.replace(':current', "current=" + (data && data.current ? data.current : ''))
    .replace(':pageSize', "pageSize=" + (data && data.pageSize ? data.pageSize : ''))
    .replace(':manufacturer_name', "manufacturer_name=" + (data && data.manufacturer_name ? data.manufacturer_name : ''))
    .replace(':business_number', "business_number=" + (data && data.business_number ? data.business_number : ''))
    .replace(':product_name', "product_name=" + (data && data.product_name ? data.product_name : ''))
    .replace(':deductions_portfolio', "deductions_portfolio=" + (data && data.deductions_portfolio ? data.deductions_portfolio : ''));
  return await axios.GET(url, {auth: true}).catch();
}

export const manufacturersOptions = async () => {
  let url = baseService.baseUrl + baseService.endpoints.manufacturers.manufacturersOptions;
  return await axios.GET(url, {auth: true}).catch();
}

export const createManufacturer = async (data: MANUFACTURER.CreateManufacturerParam) => {
  let url = baseService.baseUrl + baseService.endpoints.manufacturers.create;
  return await axios.POST(url, data, {auth: true}).catch();
}

export const updateManufacturer = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.manufacturers.update;
  return await axios.PUT(url, data, {auth: true}).catch();
}

export const createManufacturerProduct = async (data: MANUFACTURER.CreateUpdateManufacturerProductParam) => {
  let url = baseService.baseUrl + baseService.endpoints.manufacturerProduct.create;
  return await axios.POST(url, data, {auth: true}).catch();
}

export const updateManufacturerProduct = async (data: MANUFACTURER.CreateUpdateManufacturerProductParam) => {
  let url = baseService.baseUrl + baseService.endpoints.manufacturerProduct.update;
  return await axios.PUT(url, data, {auth: true}).catch();
}
