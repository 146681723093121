import axios from '../axios';
import baseService from "../base";

export const getLastFileList = async () => {
    let url = baseService.baseUrl + baseService.endpoints.admiringFactor.file.lastFiles;
    return await axios.GET(url, {auth: true}).catch();
}
export const uploadFiles = async (data: any) => {
    let url = baseService.baseUrl + baseService.endpoints.admiringFactor.file.uploadFiles;
    const formData = new FormData();
    data.files.forEach((file: any) => {
        formData.append("files", file);
    });
    return await axios.POST(url, formData, {auth: true, formData: true}).catch();
}
