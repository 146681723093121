import baseService from "../base";
import axios from "../axios";
// ############# Agent ###############
export const getClients = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.conversation.getClients;
  url = url.replace(':type', "type=" + (data && data.type ? data.type : ''))
  return await axios.GET(url, {auth: true}).catch();
}

export const getMessages = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.conversation.getMessages;
  url = url.replace(':client_id', "client_id=" + (data && data.client_id ? data.client_id : ''))
  return await axios.GET(url, {auth: true}).catch();
}

export const readMessages = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.conversation.readMessages;
  return await axios.POST(url, data, {auth: true}).catch();
}

export const addMessage = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.conversation.addMessage;
  const formData = new FormData();
  formData.append('client_id', data.client_id);
  formData.append('sender', data.sender);
  formData.append('data', data.data);
  data.files.forEach((file: any) => {
    formData.append("files", file);
  });
  return await axios.POST(url, formData, {auth: true, formData: true}).catch();
}

// ############# End Agent ###############

// ############# Client ###############
export const send = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.conversation.sendCode;
  return await axios.POST(url, data).catch();
}
export const verifyCode = async (data: any, token: any) => {
  let url = baseService.baseUrl + baseService.endpoints.conversation.verifyCode;
  return await axios.POST(url, data, {auth: true, anotherToken: token}).catch();
}
// ############# End Client ###############
