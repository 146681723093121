import React, {useRef, useState} from 'react';
import Breadcrumbs from "../../../components/antDesign/Breadcrumb";
import { Button, Card, Col, Row, Tag, Tooltip} from "antd";
import {ActionType, ProColumns} from "@ant-design/pro-components";
import {EditOutlined, EyeOutlined, FileExcelOutlined} from "@ant-design/icons";
import {getManufacturersList} from "../../../services/manufacturer";
import ViewManufacturersModal from "./ViewManufacturerModal";
import ManufacturersModal from "./ManufacturerModal";
import ManufacturerProductModal from "./ManufacturerProductModal";
import { CSVLink } from "react-csv";
import Table from "../../../components/antDesign/ProTable";
import ViewManufacturerProductModal from "./ViewManufacturerProductModal";
import Messages from "../../../components/antDesign/Message";

const BreadcrumbsItems = {
  title: "רשימת יצרניות",
  breadcrumbs: [{item: "רשימת יצרניות"}, {item: "יצרניות"}]
}

const ManufacturersList = () => {
  const [manufacturerViewModalShow, setManufacturerViewModalShow] = useState<boolean>(false);
  const [manufacturerProductViewModalShow, setManufacturerProductViewModalShow] = useState<boolean>(false);
  const [manufacturerModalShow, setManufacturerModalShow] = useState<boolean>(false);
  const [isNew, setNew] = useState<boolean>(false);
  const [manufacturerDataModal, setManufacturerDataModal] = useState<MANUFACTURER.ManufacturerParam>(undefined);
  const [manufacturerProductModalShow, setManufacturerProductModalShow] = useState<boolean>(false);
  const [manufacturers, setManufacturers] = useState<any>([]);
  const [manufacturerProductDataModal, setManufacturerProductDataModal] = useState<MANUFACTURER.CreateUpdateManufacturerProductParam>({manufacturer_id: '', business_number: 0});

  const actionRef = useRef<ActionType>();

  const closeViewManufacturerModal = () => {
    setManufacturerViewModalShow(false);
    setNew(false)
  }

  const closeManufacturerModal = (refresh: boolean) => {
    if (refresh) {
      actionRef.current?.reload()
    }

    setManufacturerModalShow(false);
    setNew(false);
  }

  const closeManufacturerProductModal = (refresh: boolean) => {
    if(refresh) {
      actionRef.current?.reload();
    }
    setManufacturerProductModalShow(false);
  }

  const columns: ProColumns[] = [
    {
      align: 'right',
      title: "יצרנית",
      // @ts-ignore
      sorter: (a: MANUFACTURER.ManufacturerParam, b: MANUFACTURER.ManufacturerParam) => a?.manufacturer_name ? a.manufacturer_name.localeCompare(b?.manufacturer_name ?? '') : false,
      fieldProps: {
        placeholder: "יצרנית"
      },
      dataIndex: 'manufacturer_name',
    }, {
      align: 'right',
      title: "ח.פ",
      // @ts-ignore
      sorter: (a: MANUFACTURER.ManufacturerParam, b: MANUFACTURER.ManufacturerParam) => a?.business_number ? a.business_number - (b?.business_number ?? -1) : false,
      valueType: "text",
      fieldProps: {
        placeholder: "ח.פ"
      },
      dataIndex: 'business_number',
    }, {
      align: 'right',
      title: "מוצר",
      fieldProps: {
        placeholder: "מוצר"
      },
      hideInTable: true,
      dataIndex: 'product_name',
    }, {
      align: 'right',
      title: "תיק ניכויים",
      fieldProps: {
        placeholder: "תיק ניכויים"
      },
      hideInTable: true,
      dataIndex: 'deductions_portfolio',
    }, {
      align: 'right',
      title: "פעולות",
      dataIndex: 'actions',
      hideInSearch: true,
      render: (_: any, record: any) => [
        <div key={record._id}>
          <Tooltip title='עריכה'>
            <Button
              type='link'
              onClick={() => {
                setManufacturerModalShow(true);
                setNew(false);
                setManufacturerDataModal(record);
              }}>
              <EditOutlined/>
            </Button>
          </Tooltip>

          <Tooltip title='צפייה'>
            <Button
              type='link'
              onClick={() => {
                setManufacturerViewModalShow(true);
                setManufacturerDataModal(record);
              }}>
              <EyeOutlined/>
            </Button>
          </Tooltip>
        </div>
      ]
    }
  ];

  const subtableColumns: ProColumns[] = [
    {
      align: 'right',
      // @ts-ignore
      sorter: (a: MANUFACTURER.ManufacturerProductParam, b: MANUFACTURER.ManufacturerProductParam) =>
        a?.product_name ? a.product_name.localeCompare(b?.product_name ?? '') : false,
      title: "מוצר",
      dataIndex: 'product_name',
    }, {
      align: 'right',
      // @ts-ignore
      sorter: (a: MANUFACTURER.ManufacturerProductParam, b: MANUFACTURER.ManufacturerProductParam) =>
        a?.type ? a.type.localeCompare(b?.type ?? '') : false,
      title: "סוג",
      dataIndex: 'type',
      render: (_:any, record: MANUFACTURER.ManufacturerProductParam) => [
        <div key={record?._id}>
          {record?.type === 'INSURANCE' &&
              <Tag color="blue">ביטוח</Tag>
          }
          {record?.type === 'OTHERS' &&
              <Tag color="magenta">גמל, פנסיה, השתלמות</Tag>
          }
        </div>
      ],
    }, {
      align: 'right',
      // @ts-ignore
      sorter: (a: MANUFACTURER.ManufacturerProductParam, b: MANUFACTURER.ManufacturerProductParam) =>
        a?.income_tax_certificate ? a.income_tax_certificate.localeCompare(b?.income_tax_certificate ?? '') : false,
      title: "מספר באוצר (מ\"ה)",
      dataIndex: 'income_tax_certificate',
      render: (_:any, record: MANUFACTURER.ManufacturerProductParam) => [
        <div key={record?._id}>
          {record?.type === 'INSURANCE' &&
              <>{record.business_number}</>
          }
          {record?.type === 'OTHERS' &&
              <>{record.income_tax_certificate}</>
          }
        </div>
      ],
    }, {
      align: 'right',
      // @ts-ignore
      sorter: (a: MANUFACTURER.ManufacturerProductParam, b: MANUFACTURER.ManufacturerProductParam) =>
        a?.deductions_portfolio ? a.deductions_portfolio.localeCompare(b?.deductions_portfolio ?? '') : false,
      title: "תיק ניכויים",
      dataIndex: 'deductions_portfolio',
    }, {
      align: 'right',
      // @ts-ignore
      sorter: (a: MANUFACTURER.ManufacturerProductParam, b: MANUFACTURER.ManufacturerProductParam) =>
        a?.institution_code ? a.institution_code > (b?.institution_code || 0) : false,
      title: "קוד מוסד",
      dataIndex: 'institution_code',
    }, {
      align: 'right',
      // @ts-ignore
      sorter: (a: MANUFACTURER.ManufacturerProductParam, b: MANUFACTURER.ManufacturerProductParam) =>
        a?.email ? a.email.localeCompare(b?.email ?? '') : false,
      title: "אימייל",
      dataIndex: 'email',
    }, {
      align: 'right',
      title: "פעולות",
      dataIndex: 'actions',
      render: (_: any, record: any) => [
        <div key={record?._id}>
          <Tooltip title='עריכה'>
            <Button
              type='link'
              onClick={() => {
                setManufacturerProductModalShow(true);
                setNew(false);
                setManufacturerProductDataModal(record);
              }}>
              <EditOutlined/>
            </Button>
          </Tooltip>

          <Tooltip title='צפייה'>
            <Button
              type='link'
              onClick={() => {
                setManufacturerProductViewModalShow(true);
                setManufacturerProductDataModal(record);
              }}>
              <EyeOutlined/>
            </Button>
          </Tooltip>
        </div>
      ]
    }
  ];

  const expandedRowRender = (manufacturer: MANUFACTURER.ManufacturerParam) => {
    return (
      <div key={manufacturer?._id}>
        <Row>
          <Col xs={24}>
            <Card>
              <Button type="primary"
                      size='small'
                      style={{height: 34}}
                      onClick={() => {
                        if(manufacturer) {
                          setManufacturerProductModalShow(true);
                          setNew(true);
                          setManufacturerProductDataModal({manufacturer_id: manufacturer?._id, business_number: manufacturer.business_number});
                        }
                      }}>
                <i className="mdi mdi-plus mr-2"/>צור מוצר
              </Button>
            </Card>
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xs={24}>
            <Table
              key={manufacturer?._id}
              // @ts-ignore
              request={loadDataProductsTable}
              params={manufacturer}
              // @ts-ignore
              columns={subtableColumns}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const loadDataTable = async (params: MANUFACTURER.ManufacturerTableParam) => {
    return await getManufacturersList(params).then((response) => {
      setManufacturers(response.list);
      return {
        status: response.status,
        current: response.current,
        total: response.total,
        pageSize: response.pageSize,
        data: response.list,
      };
    }).catch((error) => {
      Messages.Error(error.displayMessage);
      return {
        status: 'SUCCESS',
        data: [],
      };
    })
  }

  const loadDataProductsTable = async (params: MANUFACTURER.ManufacturerParam) => {
    const products = params?.products.map(product => {
      return {
        ...product,
        business_number: params?.business_number
      }
    })
    return {data: products}
  }

  const exportToCSV = () => {
    const list = [['מס׳ סידורי', 'יצרנית', 'ח.פ', 'מוצר', 'סוג', 'מס׳ אישור מס הכנסה', 'תיק ניכויים', 'מס׳ בנק', 'מס׳ סניף', 'מס׳ חשבון']];
    let index = 1;
    manufacturers.forEach((manufacturer: any) => {
      manufacturer.products.forEach((product: any) => {
        const type = product.type === 'INSURANCE' ? 'ביטוח' : 'גמל, פנסיה, השתלמות';
        const income_tax_certificate = product?.income_tax_certificate || '';
        const deductions_portfolio = product?.deductions_portfolio || '';
        const bank_number = product?.bank_details?.bank_number || '';
        const branch_number = product?.bank_details?.branch_number || '';
        const account_number = product?.bank_details?.account_number || '';
        list.push([index++, manufacturer.manufacturer_name, manufacturer.business_number,
          product.product_name, type, income_tax_certificate, deductions_portfolio,
          bank_number, branch_number, account_number]);
      })
    })
    return list;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title={BreadcrumbsItems.title} breadcrumbs={BreadcrumbsItems.breadcrumbs}/>

        <Row>
          <Col xs={24}>
            <Card>
              <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Button type="primary"
                          style={{height: 40}}
                          onClick={() => {
                            setManufacturerModalShow(true);
                            setNew(true);
                            setManufacturerDataModal(undefined)
                          }}>
                    <i className="mdi mdi-plus mr-2"/>צור יצרן
                  </Button>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign: "left"}}>
                  <Tooltip title={'יצא לאקסל'}>

                    <CSVLink filename={"manufacturers.csv"} data={exportToCSV()}>
                      <Button icon={<FileExcelOutlined />}></Button>
                    </CSVLink>
                  </Tooltip>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xs={24}>
            <Table
              actionRef={actionRef}
              // @ts-ignore
              expandable={{expandedRowRender}}
              request={loadDataTable}
              columns={columns}
              showFilter
              totalText='יצרניות'
              showPagination={true}
            />
          </Col>
        </Row>

        <ViewManufacturersModal visible={manufacturerViewModalShow}
                                manufacturerDataModal={manufacturerDataModal}
                                onClose={() => closeViewManufacturerModal()}/>
        <ViewManufacturerProductModal
          visible={manufacturerProductViewModalShow}
          manufacturerProductDataModal={{
            manufacturer_id: manufacturerProductDataModal.manufacturer_id,
            product_name: manufacturerProductDataModal.product_name,
            business_number: manufacturerProductDataModal.business_number || undefined,
            type: manufacturerProductDataModal.type,
            email: manufacturerProductDataModal.email,
            income_tax_certificate: manufacturerProductDataModal.income_tax_certificate,
            deductions_portfolio: manufacturerProductDataModal.deductions_portfolio,
            institution_code: manufacturerProductDataModal.institution_code || undefined,
            bank_details: manufacturerProductDataModal.bank_details,
          }}
          onClose={() => setManufacturerProductViewModalShow(false)}/>
        <ManufacturersModal visible={manufacturerModalShow}
                            manufacturerDataModal={manufacturerDataModal} isNew={isNew}
                            onClose={(refresh: boolean) => closeManufacturerModal(refresh)}/>
        <ManufacturerProductModal visible={manufacturerProductModalShow}
                                  isNew={isNew}
                                  onClose={(refresh: boolean) => closeManufacturerProductModal(refresh)}
                                  manufacturerProductDataModal={manufacturerProductDataModal}/>
      </div>
    </React.Fragment>
  );
};

export default ManufacturersList;
