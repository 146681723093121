import React, {useState} from 'react';
import {Col, Form, Input, InputNumber, Modal, Row, Select, Typography} from "antd";
import {createCompany, updateCompany} from "../../../services/company";
import Messages from "../../../components/antDesign/Message";

const {Text} = Typography;

interface Props {
  visible: boolean;
  isNew: boolean;
  onClose: (refresh: boolean) => void;
  companyDataModal: any;
}

const CompanyModal: React.FC<Props> = (props) => {

  const [form] = Form.useForm();
  const [idType, setIdType] = useState<number>(1);

  const createBodyRequest = (values: any) => {
    values.idTypeName = values.idType === 1 ? 'ID' : 'BN'
    return values;
  }

  const handleCreate = (values: any) => {
    values = createBodyRequest(values);
    createCompany({company: values}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success('החברה נוצרה בהצלחה').then(() => {});
        props.onClose(true);
      } else {
        Messages.Error('יצירת החברה נכשלה').then(() => {});
      }
    }).catch(error => {
      Messages.Error(error.displayMessage).then(() => {});
    });
  }

  const handleUpdate = (values: any) => {
    values = createBodyRequest(values);
    updateCompany({company: values}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success('החברה עודכנה בהצלחה').then(() => {});
        props.onClose(true);
      } else {
        Messages.Error('עדכון החברה נכשל').then(() => {});
      }
    }).catch(error => {
      Messages.Error(error.displayMessage).then(() => {});
    });
  }

  const resetValues = () => {
    setIdType(1);
    form.resetFields();
  }

  const handleAfterClose = () => {
    resetValues()
  }

  return (
    <Modal
      title={props.isNew ? "הוספת חברה" : "עריכת חברה"}
      open={props.visible}
      afterClose={handleAfterClose}
      onCancel={() => props.onClose(false)}
      onOk={form.submit}
      maskClosable={false}
      destroyOnClose
      okText={props.isNew ? "הוסף" : "שמור"}
      cancelText="ביטול"
      width={1000}>

      <Form form={form}
            onValuesChange={(values) => {
              if (values.idType)
                setIdType(values.idType);
            }}
            layout="vertical"
            onFinish={props.isNew ? handleCreate : handleUpdate}>

        <Form.Item
          name='companyId'
          initialValue={props.companyDataModal && props.companyDataModal._id ? props.companyDataModal._id : ''}
          hidden>
          <Input hidden/>
        </Form.Item>

        <Row gutter={[16, 16]}>

          <Col lg={8} md={12} sm={12} xs={12}>

            <Form.Item
              label="חברה"
              initialValue={props.companyDataModal && props.companyDataModal.idType ? props.companyDataModal.idType : ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='idType'>
              <Select disabled={!props.isNew} placeholder='בחר'>
                <Select.Option value={1}>ע.מ</Select.Option>
                <Select.Option value={2}>ח.פ</Select.Option>
              </Select>
            </Form.Item>

          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>

            <Form.Item
              label={idType === 2 ? "מס׳ חברה" : "מס׳ עוסק מורשה"}
              initialValue={props.companyDataModal && props.companyDataModal.idNumber ? props.companyDataModal.idNumber : ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='idNumber'>
              <Input disabled={!props.isNew} placeholder={idType === 2 ? "מס׳ חברה" : "מס׳ עוסק מורשה"}/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="שם חברה"
              initialValue={props.companyDataModal && props.companyDataModal.name ? props.companyDataModal.name : ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='name'>
              <Input placeholder="שם חברה"/>
            </Form.Item>
          </Col>

          <Col lg={24} md={24} sm={24} xs={24} className="my-3">
            <Text strong type="warning">פרטי התקשרות</Text>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="שם פרטי"
              initialValue={props.companyDataModal && props.companyDataModal.contactFirstName ? props.companyDataModal.contactFirstName : ''}
              name='contactFirstName'>
              <Input placeholder="שם פרטי"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="שם משפחה"
              initialValue={props.companyDataModal && props.companyDataModal.contactLastName ? props.companyDataModal.contactLastName : ''}
              name='contactLastName'>
              <Input placeholder="שם משפחה"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="סלולרי"
              initialValue={props.companyDataModal && props.companyDataModal.contactMobile ? props.companyDataModal.contactMobile : ''}
              name='contactMobile'>
              <Input placeholder="סלולרי"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="טלפון נייח"
              initialValue={props.companyDataModal && props.companyDataModal.contactPhone ? props.companyDataModal.contactPhone : ''}
              name='contactPhone'>
              <Input placeholder="טלפון נייח"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="אימייל"
              initialValue={props.companyDataModal && props.companyDataModal.contactEmail ? props.companyDataModal.contactEmail : ''}
              rules={[{type: 'email', message: 'אימייל לא תקין'}]}
              name='contactEmail'>
              <Input placeholder="אימייל"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="כתובת"
              initialValue={props.companyDataModal && props.companyDataModal.address ? props.companyDataModal.address : ''}
              name='address'>
              <Input placeholder="כתובת"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="עיר"
              initialValue={props.companyDataModal && props.companyDataModal.city ? props.companyDataModal.city : ''}
              name='city'>
              <Input placeholder="עיר"/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="מיקוד"
              initialValue={props.companyDataModal && props.companyDataModal.zipCode ? props.companyDataModal.zipCode : ''}
              name='zipCode'>
              <InputNumber placeholder="מיקוד"/>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Modal>
  );
};

export default CompanyModal;
