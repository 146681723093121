import React, {useEffect, useState} from 'react';
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  Alert,
  Switch,
  Radio,
  Divider,
  Typography,
  Tooltip
} from 'antd';
import {Link} from "react-router-dom";
import {getCompaniesList} from "../../services/company";
import {createProperty, getPropertiesCompanyOrDefault, updateProperty} from "../../services/task";
import {authorized} from "../../services/auth";
import Message from "../antDesign/Message";

const {Text} = Typography;
const AddEditPropertiesModal: React.FC<Properties.AddEditProperties> = (props) => {

  const [propertyType, setPropertyType] = useState<string>('NEW');
  const [properties, setProperties] = useState<any>([]);
  const [companies, setCompanies] = useState<Properties.CompaniesSelect[]>([]);
  const [numberFieldsCanNotEdit, setNumberFieldsCanNotEdit] = useState<number>(0);

  const [form] = Form.useForm();

  const handleCreate = (values: any) => {
    create(values).then((isCreated: boolean) => {
      if (isCreated)
        props.onConfirm();
    });
  }

  const handleUpdate = (values: any) => {
    values._id = props.data._id;
    update(values).then((isUpdate: boolean) => {
      if (isUpdate)
        props.onConfirm();
    });
  }

  const create = async (values: any) => {
    return await createProperty(values).then((response: any) => {
      if (response.status.toString() === 'SUCCESS') {
        Message.Success('הפעולה בוצעה בהצלחה')
        return true;
      }
      Message.Error('הפעולה נכשלה')
      return false;
    })
  }

  const update = async (values: any) => {
    return await updateProperty(values).then((response: any) => {
      if (response.status.toString() === 'SUCCESS') {
        Message.Success('הפעולה בוצעה בהצלחה')
        return true;
      }
      Message.Error('הפעולה נכשלה')
      return false
    })
  }

  const handleAfterClose = () => {
    resetValues()
  }

  const resetValues = () => {
    setCompanies([]);
    setPropertyType('NEW');
    form.resetFields();
  }

  const getCompanies = () => {
    if (authorized(['admin'])) {
      getCompaniesList().then(response => {
        setCompanies(response.list.map((company: any) => {
          return {
            label: company.name,
            value: company._id,
          }
        }))
      })
    }
  }

  const getPropertiesType = (company: string | undefined = undefined) => {
    if (authorized(['admin', 'admin-company'])) {
      // @ts-ignore
      getPropertiesCompanyOrDefault({company}).then(response => {
        if (response.status.toString() === 'SUCCESS') {
          setProperties(response.list.map((property: any) => {
            return {
              label: property.type_label + (authorized(['admin']) ?
                property.company_id ? " (חברה)" : " (כללי)" : ''),
              value: property.type
            }
          }))
        }
      })
    }
  }

  const handleCompanyChange = (selectedCompany: any) => {
    if (propertyType === 'EXIST')
      getPropertiesType(selectedCompany === 'none' ? undefined : selectedCompany);
    else
      getPropertiesType();
  }

  const onModalOpen = () => {
    getCompanies();
    getPropertiesType();

    if (!props.isNew) {
      if (props.data.fields && props.data.fields.length > 0) {
        for (const field of props.data.fields) {
          if (field.type === 'select' || field.type === 'radio') {
            if (field.items) {
              field.items_selected = field.items.map((item: any) => {
                return item.label
              }).join();
            }
          }
        }
      }
      form.setFieldsValue({fields: props.data.fields, active: props.data.active})
      setNumberFieldsCanNotEdit(props.data.fields.length - 1);
      getPropertiesType(props.data && props.data.company_id ? props.data.company_id : undefined);
    }
  }

  useEffect(() => {
    if (props.visible)
      onModalOpen();
    return () => {
    }
  }, [props.visible])

  return (
    <Modal
      title={props.isNew ? "הוספת תכונה" : "עריכת תכונה"}
      open={props.visible}
      afterClose={handleAfterClose}
      onCancel={() => props.onClose()}
      onOk={form.submit}
      maskClosable={false}
      destroyOnClose
      okText={props.isNew ? "הוסף" : "שמור"}
      cancelText="ביטול"
      width={1000}>

      <Form form={form}
            onValuesChange={(values) => {
              const formValues = {...form.getFieldsValue()};
              if (values.fields)
                form.setFieldsValue({...formValues.fields[values.length - 1], ...values.fields})
              else
                form.setFieldsValue({...form.getFieldsValue(), ...values})
            }}
            layout="vertical"
            onFinish={props.isNew ? handleCreate : handleUpdate}>
        <Row gutter={[16, 16]} className='mb-4'>
          <Col sm={24}>
            <Alert message={
              <Row justify="space-around" align="middle">
                <Col sm={23}>
                  <p className='mb-0'>במידה ולא פעיל לא יהיה ניתן ליצור סוג משימה זו בממשק סוכנים</p>
                </Col>
                <Col sm={1}>
                  <Form.Item
                    name='active'
                    className='mb-0 pb-0'
                    valuePropName="checked"
                    shouldUpdate={(prevValues, curValues) => prevValues.active !== curValues.active}
                  >
                    <Switch
                      size='small'/>
                  </Form.Item>
                </Col>
              </Row>} type="info" showIcon/>
          </Col>
        </Row>

        {
          props.isNew &&
            <Row justify="center" gutter={[16, 16]}>
                <Col>
                    <Radio.Group
                        defaultValue="NEW"
                        buttonStyle="solid"
                        onChange={(e) => setPropertyType(e.target.value)}>
                        <Radio.Button value="NEW">תכונה חדשה</Radio.Button>
                        <Radio.Button value="EXIST">תכונה קיימת</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
        }

        <Row>
          <Col sm={24}>
            <Divider/>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className='my-2'>

          {
            authorized(['admin']) &&
              <Col sm={8}>
                  <Form.Item
                      label="חברה"
                      tooltip='יצירה של תכונה תוצג לכולם או לחברה בודדת'
                      initialValue={props.data && props.data.company_id ? props.data.company_id : ''}
                      name='company'>
                      <Select disabled={!props.isNew} onChange={handleCompanyChange}>
                          <Select.Option value="">לכולם</Select.Option>
                        {
                          companies && companies.length > 0 && companies.map((company, index: number) => (
                            <Select.Option key={index} value={company.value}>{company.label}</Select.Option>
                          ))
                        }
                      </Select>
                  </Form.Item>
              </Col>
          }

          <Col sm={8}>
            {
              (propertyType === 'NEW' || !props.isNew) &&
                <Form.Item
                    label="תכונה"
                    name='type_label'
                    initialValue={props.data && props.data.type_label ? props.data.type_label : ''}
                    rules={[{required: true, message: "שדה חובה"}]}>
                    <Input placeholder="תכונה"/>
                </Form.Item>
            }

            {
              (propertyType === 'EXIST' && props.isNew) &&
                <Form.Item
                    label="תכונה"
                    name='type'
                    rules={[{required: true, message: "שדה חובה"}]}>
                    <Select>
                      {
                        properties && properties.length > 0 && properties.map((property: any, index: number) => (
                          <Select.Option key={index}
                                         value={property.value}>{property.label}</Select.Option>
                        ))
                      }
                    </Select>
                </Form.Item>
            }
          </Col>
          <Col sm={8}>
            <Form.Item
              label="תת תכונה"
              initialValue={props.data && props.data.subtype_label ? props.data.subtype_label : ''}
              name='subtype_label'>
              <Input placeholder="תת תכונה"/>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm={24}>
            <Divider/>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={24}>
            <Typography.Title type='warning' level={5} style={{margin: 0}}>
              שדות
            </Typography.Title>
          </Col>
        </Row>

        <Row justify="space-around" align="middle" gutter={[16, 16]}>
          <Col sm={8}>
            <Text strong><Text type="danger">*</Text> סוג שדה</Text>
          </Col>

          <Col sm={8}>
            <Text strong><Text type="danger">*</Text> שם שדה</Text>
          </Col>

          <Col sm={2}>
            <Text strong>חובה</Text>
          </Col>

          <Col sm={2}>
            <Text strong>פעיל</Text>
          </Col>

          <Col sm={2}>
            <Text strong>פעולות</Text>
          </Col>
        </Row>

        <Form.List name="fields">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({key, name, ...restField}) => (
                <Row key={key} className={key === 0 ? 'mt-4' : 'mt-2'} justify="space-around"
                     align="middle" gutter={[16, 16]}>

                  <Col sm={8}>
                    <Form.Item
                      {...restField}
                      rules={[{required: true, message: "שדה חובה"}]}
                      name={[name, 'type']}>
                      <Select disabled={!props.isNew && key <= numberFieldsCanNotEdit}
                              placeholder="בחר">
                        <Select.Option value="text">טקסט</Select.Option>
                        <Select.Option value="number">מספר</Select.Option>
                        <Select.Option value="email">אימייל</Select.Option>
                        <Select.Option value="tel">טלפון</Select.Option>
                        <Select.Option value="checkbox">תיבת סימון</Select.Option>\
                        <Select.Option value="date">תאריך</Select.Option>
                        <Select.Option value="radio">כפתור בחירה</Select.Option>
                        <Select.Option value="select">בחירה מרובה</Select.Option>
                        <Select.Option value="file">קובץ</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col sm={8}>
                    <Form.Item
                      {...restField}
                      rules={[{required: true, message: "שדה חובה"}]}
                      name={[name, 'label']}>
                      <Input placeholder="שם השדה"/>
                    </Form.Item>
                  </Col>

                  <Col sm={2}>
                    <Form.Item {...restField} name={[name, 'required']}
                               valuePropName="checked"
                               shouldUpdate={(prevValues, curValues) =>
                                 prevValues.fields[key] && prevValues.fields[key].required && prevValues.fields[key].required !== curValues.fields[key].required}>
                      <Switch checkedChildren="כן"
                              unCheckedChildren="לא"/>
                    </Form.Item>
                  </Col>

                  <Col sm={2}>
                    <Tooltip title='במידה ופעיל השדה יוצג לסוכנים'>
                      <Form.Item {...restField} name={[name, 'active']}
                                 valuePropName="checked"
                                 shouldUpdate={(prevValues, curValues) =>
                                   prevValues.fields[key] && prevValues.fields[key].active && prevValues.fields[key].active !== curValues.fields[key].active
                                 }>
                        <Switch checkedChildren="פעיל"
                                unCheckedChildren="כבוי"/>
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col sm={2}>
                    {
                      (props.isNew || (!props.isNew && key > numberFieldsCanNotEdit)) &&

                        <Tooltip title='מחיקת השדה'>
                            <Link
                                to={location => location}
                                className=" waves-effect"
                                style={{marginBottom: 15}}
                                onClick={() => remove(name)}>
                                <i className="mdi mdi-delete-outline mt-2"
                                   style={{fontSize: 25, color: "red"}}/>
                            </Link>
                        </Tooltip>
                    }
                  </Col>

                  {
                    form.getFieldsValue() && form.getFieldsValue().fields &&
                    (form.getFieldsValue().fields[key]?.type === 'select' ||
                      form.getFieldsValue().fields[key]?.type === 'radio') &&
                      <Col sm={24}>
                          <Row gutter={[16, 16]}>
                              <Col sm={17}>
                                  <Form.Item
                                    {...restField}
                                    label="אפשרויות בחירה"
                                    tooltip="כדי להציג אפשרויות בחירה, יש לרשום אפשרות בחירה ולאחריו פסיק. לדוגמא: בחירה ראשונה,בחירה שניה,בחירה שלישית"
                                    rules={[{required: true, message: "שדה חובה"}]}
                                    initialValue={form.getFieldsValue().fields[key]?.items_selected}
                                    name={!form.getFieldsValue().fields[key]?.items_selected ? [name, 'items'] : [name, 'items_selected']}>
                                      <Input
                                          disabled={!!form.getFieldsValue().fields[key]?.items_selected}
                                          placeholder="בחירה ראשונה,בחירה שניה,בחירה שלישית"/>
                                  </Form.Item>
                              </Col>
                              <Col sm={9}/>
                          </Row>
                      </Col>
                  }

                </Row>
              ))}
              <Row justify="center" className='mt-3'>
                <Col>
                  <Form.Item>
                    <Button color="link"
                            className="waves-effect"
                            onClick={() => add()}>
                      הוסף שדה
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default AddEditPropertiesModal;
