import React from 'react';
import {Col, Modal, Row, Tag, Typography} from "antd";

const {Text} = Typography;

interface Props {
  visible: boolean;
  userDataModal: any;
  onClose: () => void;
}

const ViewUserModal: React.FC<Props> = (props) => {
  return (
    <Modal
      title={"פרטי משתמש"}
      open={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      maskClosable={false}
      cancelButtonProps={{style: {display: 'none'}}}
      destroyOnClose
      okText="סגור"
      width={1000}>

      <Row>
        <Col lg={24} md={24} sm={24} xs={24} className="mb-3">
          <Text strong type="warning">כללי</Text>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>שם פרטי</Text></div>
          <div><Text>{props.userDataModal.firstName || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>שם משפחה</Text></div>
          <div><Text>{props.userDataModal.lastName || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>ת.ז</Text></div>
          <div><Text>{props.userDataModal.userId || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>טלפון</Text></div>
          <div><Text>{props.userDataModal.phone || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>אימייל</Text></div>
          <div><Text>{props.userDataModal.email || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>סוג משתמש</Text></div>
          <div>
            {
              props.userDataModal.type === 'admin' && props.userDataModal.isAdmin &&
                <Tag color="#f50">אדמין</Tag>
            }

            {
              props.userDataModal.type === 'agent' &&
                <Tag color="#000000">סוכן</Tag>
            }

            {/*{*/}
            {/*  props.userDataModal.type === 'admiringFactor' &&*/}
            {/*    <Tag color="#fcb92c">גורם מתפעל</Tag>*/}
            {/*}*/}

            {
              props.userDataModal.type === 'admin-company' &&
                <Tag color="#fcb92c">אדמין חברה</Tag>
            }

            {
              props.userDataModal.type === 'treatment' &&
                <Tag color="#87d068">גורם מטפל</Tag>
            }
          </div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>חברה</Text></div>
          <div>
            <Text>{props.userDataModal.companyName || '-'}</Text>
          </div>
        </Col>
      </Row>
      {
        props.userDataModal.type === 'agent' &&
          <Row>
              <Col lg={24} md={24} sm={24} xs={24} className="mt-4 mb-3">
                  <Text strong type="warning">פרטי התקשרות</Text>
              </Col>

              <Col lg={8} md={12} sm={12} xs={12} className="my-2">
                  <div><Text strong>רשיון סוכן</Text></div>
                  <div><Text>{props.userDataModal.agentLicense || '-'}</Text></div>
              </Col>
          </Row>
      }

    </Modal>
  );
};

export default ViewUserModal;
