import React, {useEffect, useState} from 'react';
import {Col, Form, Input, InputNumber, Modal, Row, Select} from "antd";
import {createManufacturer, updateManufacturer} from "../../../services/manufacturer";
import Messages from "../../../components/antDesign/Message";
import AlertMessage from "../../../components/antDesign/Alert";

interface Props {
  visible: boolean;
  isNew: boolean;
  onClose: (refresh: boolean) => void;
  manufacturerDataModal: MANUFACTURER.CreateManufacturerParam;
}

const ManufacturerModal: React.FC<Props> = (props) => {

  const [isBusinessNumberExist, setBusinessNumberExist] = useState<boolean>(false);

  const [form] = Form.useForm();

  const handleCreate = (values: MANUFACTURER.CreateManufacturerParam) => {
    setBusinessNumberExist(false);
    createManufacturer(values).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success('יצרן נוצרה בהצלחה')
        props.onClose(true)
      } else {
        Messages.Error('יצירת היצרן נכשלה')
      }
    }).catch((error) => {
      Messages.Error(error.displayMessage);
    })
  }

  const handleUpdate = (values: MANUFACTURER.CreateManufacturerParam) => {
    setBusinessNumberExist(false);
    updateManufacturer(values).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success('היצרן עודכן בהצלחה')
        props.onClose(true)
      } else {
        Messages.Error('עדכון היצרן נכשל')
      }
    }).catch((error) => {
      Messages.Error(error.displayMessage);
    })
  }

  const resetValues = () => {
    setBusinessNumberExist(false);
    form.resetFields();
  }

  const handleAfterClose = () => {
    resetValues()
  }

  const onModalOpen = () => {
  }

  useEffect(() => {

    if (props.visible) {
      onModalOpen();
    }

  }, [props.visible])

  return (
    <Modal
      title={props.isNew ? "הוספת יצרן" : "עריכת יצרן"}
      open={props.visible}
      afterClose={handleAfterClose}
      onCancel={() => props.onClose(false)}
      onOk={form.submit}
      maskClosable={false}
      destroyOnClose
      okText={props.isNew ? "הוסף" : "שמור"}
      cancelText="ביטול"
      width={1000}>

      <Form form={form}
            layout="vertical"
            onFinish={props.isNew ? handleCreate : handleUpdate}>

        {isBusinessNumberExist &&
            <Row className='mb-4'>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <AlertMessage type={"error"} message='ח.פ כבר קיים במערכת' showIcon/>
                </Col>
            </Row>
        }

        <Form.Item
          name='id'
          initialValue={props.manufacturerDataModal?._id || ''}
          hidden>
          <Input hidden/>
        </Form.Item>

        <Row gutter={[16, 16]}>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label="יצרנית"
              initialValue={props.manufacturerDataModal?.manufacturer_name || ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='manufacturer_name'>
              <Input placeholder='יצרנית'/>
            </Form.Item>
          </Col>

          <Col lg={8} md={12} sm={12} xs={12}>
            <Form.Item
              label='ח.פ'
              initialValue={props.manufacturerDataModal?.business_number || ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='business_number'>
              <InputNumber min={1} placeholder='ח.פ'/>
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  );
};

export default ManufacturerModal;
