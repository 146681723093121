import React, {MouseEventHandler} from 'react';
import {Tabs as TabsAnt} from "antd";
import {Tab} from "rc-tabs/lib/interface";
import {TabsType} from "antd/lib/tabs";
interface Props {
  items?: Tab[];
  activeKey?: string;
  defaultActiveKey?: string;
  type?: TabsType;
  destroyInactiveTabPane?: boolean;
  onClick?: MouseEventHandler<any> | undefined;
  onChange?: (activeKey: string) => void;
  style?: React.CSSProperties;
  className?: string;
}
const Tabs: React.FC<Props> = (props) => {
  return (
    <TabsAnt
      activeKey={props.activeKey}
      items={props.items}
      defaultActiveKey={props.defaultActiveKey}
      type={props.type}
      destroyInactiveTabPane={props.destroyInactiveTabPane}
      onClick={props.onClick}
      onChange={props.onChange}
      style={props.style}
      className={props.className}/>
  );
};

export default Tabs;
