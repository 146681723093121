import React from 'react';
import {Modal as ModalAnt} from "antd";
import {ButtonProps} from "antd/lib/button/button";
interface Props {
  open?: boolean;
  title?: React.ReactNode;
  okText?: React.ReactNode;
  okButtonProps?: ButtonProps;
  onOk?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  cancelText?: React.ReactNode;
  cancelButtonProps?: ButtonProps;
  onCancel?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  footer?: React.ReactNode;
  destroyOnClose?: boolean | undefined;
  afterClose?: (() => void) | undefined;
  children: React.ReactNode;
}
const Modal: React.FC<Props> = (props) => {
  return (
    <ModalAnt
      title={props.title}
      okText={props.okText}
      okButtonProps={props.okButtonProps}
      onOk={props.onOk}
      cancelText={props.cancelText}
      cancelButtonProps={props.cancelButtonProps}
      onCancel={props.onCancel}
      footer={props.footer}
      destroyOnClose={props.destroyOnClose}
      afterClose={props.afterClose}
      open={props.open}>
      {props.children}
    </ModalAnt>
  );
};

export default Modal;
