import React, {useEffect, useState} from 'react';
import {getClientDetailsProducts} from "../../../../services/client";
import PoliciesTable from "../../../tables/Policies";
import Tabs from "../../../antDesign/Tabs";

interface Props {
  clientId?: string | undefined;
  isClient?: boolean;
}

const Products: React.FC<Props> = (props) => {

  const [tabs, setTabs] = useState<any>([]);

  const getClientId = () => {
    const params = new URLSearchParams(window.location.search);
    const url = window.location.href.split('?');
    const cid = params.get('cid');
    // agent
    if (url.length > 0 && url[0].endsWith('client-details'))
      if (cid)
        return cid;

    return '';
  }

  const getProducts = (clientId: string) => {
    getClientDetailsProducts({clientId}).then((data) => {

      const productType = {id: '0', name: "הכל"};
      const policiesList: any = [];
      data.client.products.forEach((product: any) => {
        policiesList.push({
          manufacturer: {manufacturer_name: product.productType.name}
        })
        product.policies.forEach((policy: any) => {
          policiesList.push({...policy, productType: product.productType});
        })
      })
      const productsWithAll = [{policies: policiesList, productType}].concat(data.client.products);

      const tabsList: any = [{
        key: '0',
        label: "הכל",
        table: {rows: [], columns: []}
      }].concat(data.table)

      for (let i = 0; i < tabsList.length; i++) {
        const p = productsWithAll.filter((product: any) => {
          if(product?.productType?.id === undefined || tabsList[i].key === undefined) {
            console.log(product);
            console.log(tabsList[i]);
          }

          return product?.productType?.id.toString() === tabsList[i].key.toString();
        });
        tabsList[i].children = <PoliciesTable
          productType={p[0].productType}
          isClient={props.isClient}
          clientId={getClientId()}
          policies={p[0].policies}/>
      }
      setTabs(tabsList)
    })
    return () => {
    }
  }

  useEffect(() => {
    if (!props.isClient)
      getProducts(getClientId());
  }, [])

  useEffect(() => {
    if (props.isClient && props.clientId) {
      getProducts(props.clientId);
    }
  }, [props.clientId])

  return (
    <Tabs
      className='mt-3 mb-5'
      items={tabs}
      type="card"
      />
  );
};

export default Products;
