import React, {Component} from 'react';
import {Card, CardBody, Col, Media, Row, Container, Badge, UncontrolledTooltip} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {withNamespaces} from "react-i18next";
import {Link} from "react-router-dom";
import moment from "moment";

class PolicyDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: this.props.t("BREADCRUMB.POLICY_DETAILS.MAIN"), link: "#"},
                {title: this.props.t("BREADCRUMB.POLICY_DETAILS.LIST"), link: "#"},
            ],
            reports: [
                {
                    icon: "ri-stack-line",
                    title: "Number of Sales",
                    value: "1452",
                    rate: "2.4%",
                    desc: "From previous period"
                },
                {
                    icon: "ri-store-2-line",
                    title: "Sales Revenue",
                    value: "$ 38452",
                    rate: "2.4%",
                    desc: "From previous period"
                },
                {
                    icon: "ri-briefcase-4-line",
                    title: "Average Price",
                    value: "$ 15.4",
                    rate: "2.4%",
                    desc: "From previous period"
                },
            ]
        }
    }

    initTable() {
        let state = this.state;


        if (state.products && state.products.length > 0)
            state.products.policies.map(value => {
                let status;

                switch (value.statusPolicyOrAccount.id) {
                    case "1":
                        status = <Badge color="primary" className="mr-1">{value.statusPolicyOrAccount.name}</Badge>
                        break;
                    case "2":
                        status = <Badge color="warning" className="mr-1">{value.statusPolicyOrAccount.name}</Badge>
                        break;
                    case "3":
                        status = <Badge color="danger" className="mr-1">{value.statusPolicyOrAccount.name}</Badge>
                        break;
                    case "4":
                        status = <Badge color="info" className="mr-1">{value.statusPolicyOrAccount.name}</Badge>
                        break;
                    case "6":
                        status = <Badge color="dark" className="mr-1">{value.statusPolicyOrAccount.name}</Badge>
                        break;
                    case "7":
                        status = <Badge color="dark" className="mr-1">{value.statusPolicyOrAccount.name}</Badge>
                        break;
                    case "8":
                        status = <Badge color="dark" className="mr-1">{value.statusPolicyOrAccount.name}</Badge>
                        break;
                    case "9":
                        status = <Badge color="dark" className="mr-1">{value.statusPolicyOrAccount.name}</Badge>
                        break;
                    case "10":
                        status = <Badge color="dark" className="mr-1">{value.statusPolicyOrAccount.name}</Badge>
                        break;
                    default:
                        status = <></>
                }
                let actions = <>
                    <Link to={window.location.pathname + "?cid=" + this.clientId}
                          onClick={() => {
                              this.setState({
                                  task: state.products.filter((p) => p.policyNumberOrAccountNumber === value.policyNumberOrAccountNumber),
                                  addEditTaskClientModalShow: true
                              })
                          }}
                          className="mr-3 text-primary"
                          id="createTask"><i className="mdi mdi-file-plus-outline font-size-18"/></Link>
                    <UncontrolledTooltip placement="top" target="createTask">
                        {this.props.t("CLIENT_DETAILS_PAGES.TABLES.PRODUCTS.CREATE_TASK")}
                    </UncontrolledTooltip>

                    <Link to={"/policy-details?pid=" + value.policyNumberOrAccountNumber}
                          className="mr-3 text-primary"
                          id="showPolicy"><i className="mdi mdi-card-account-details-outline font-size-18"/></Link>
                    <UncontrolledTooltip placement="top" target="showPolicy">
                        {this.props.t("CLIENT_DETAILS_PAGES.TABLES.PRODUCTS.SHOW_POLICY")}
                    </UncontrolledTooltip>
                </>

                const policyNumber = <Link to={"/policy-details?pid=" + value.policyNumberOrAccountNumber}
                                           className="waves-effect">
                    <span className="ml-1">{value.policyNumberOrAccountNumber}</span>
                </Link>

                value.correctnessDate = !value.correctnessDate ? undefined : moment(value.correctnessDate).format("DD/MM/YYYY");

                return {
                    manufacturerName: value.manufacturer && value.manufacturer.manufacturerName ? value.manufacturer.manufacturerName : '',
                    productName: value.productName,
                    policyNumberOrAccountNumber: policyNumber,
                    statusPolicyOrAccount: status,
                    programTypeOrAccount: value.programTypeOrAccount.name,
                    correctnessDate: value.correctnessDate,
                    actions: actions
                }
            })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("BREADCRUMB.POLICY_DETAILS.LIST")}
                                     breadcrumbItems={this.state.breadcrumbItems}/>
                        <Row>
                            {
                                this.state.reports.map((report, key) =>
                                    <Col key={key} md={4}>
                                        <Card>
                                            <CardBody>
                                                <Media>
                                                    <Media body className="overflow-hidden">
                                                        <p className="text-truncate font-size-14 mb-2">{report.title}</p>
                                                        <h4 className="mb-0">{report.value}</h4>
                                                    </Media>
                                                    <div className="text-primary">
                                                        <i className={report.icon + " font-size-24"}/>
                                                    </div>
                                                </Media>
                                            </CardBody>

                                            <CardBody className="border-top py-3">
                                                <div className="text-truncate">
                                            <span className="badge badge-soft-success font-size-11 mr-1"><i
                                                className="mdi mdi-menu-up"> </i> {report.rate}</span>
                                                    <span className="text-muted ml-2">{report.desc}</span>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            }
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(PolicyDetails);