import React from "react";
import {Redirect} from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/User/Login";
import LoginClient from "../pages/Authentication/Client/Login";
import Register from "../pages/Authentication/User/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";


// Admin
import AdminUsersList from "../pages/Admin/Users/UsersList";
import AdminCompaniesList from "../pages/Admin/Companies/CompaniesList";
import AdminManufacturersList from "../pages/Admin/manufacturers/ManufacturersList";
import AdminPropertiesList from "../pages/Admin/Properties";

// Reports
import InsuranceMountain from "../pages/Reports/insuranceMountain";

//Clients
import ClientsList from "../components/Client/ClientsList";
import ClientDetails from "../components/Client/ClientDetails";

//signature
import Signature from "../pages/Signature";

import ConversationClient from '../pages/Client/ConversationClient/ConversationClient'

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Tasks
import Tasks from "../pages/Tasks/Tasks";

// Tasks
import TreatmentTasks from "../pages/Treatment/Tasks/Tasks";

// Policy
import PolicyDetails from "../pages/Policy/PolicyDetails";

// Conversation
import Conversation from "../pages/Conversation/Conversation";
import Profile from "../pages/Profile";

// agents: permission for admin-company
import Agents from "../pages/AdminCompany/Users/UsersList";
import AdminCompanyPropertiesList from "../pages/AdminCompany/Properties/PropertiesList";

//--------- Admiring Factor --------
import AdmiringFactorUploadFile from "../pages/AdmiringFactor/ImportFiles"
import AdmiringFactorEmployers from "../pages/AdmiringFactor/Employers/EmployersList"

// Calendar
// import Calendar from "../pages/Calendar/Calendar";
import Calendar from "../components/Calendar";

const authProtectedRoutes = [
  // Admin Company
  {path: "/admin-company/user-list", component: Agents},
  {path: "/admin-company/properties-list", component: AdminCompanyPropertiesList},

  // Admin
  {path: "/user-list", component: AdminUsersList},
  {path: "/companies-list", component: AdminCompaniesList},
  {path: "/manufacturers-list", component: AdminManufacturersList},
  {path: "/properties-list", component: AdminPropertiesList},

  // Profile
  {path: "/profile", component: Profile},

  //Client
  {path: "/clients-list", component: ClientsList},
  {path: "/client-details", component: ClientDetails},

  // Policy
  {path: "/policy-details", component: PolicyDetails},

  // Admiring Factor
  {path: "/admiring-factor/upload-files", component: AdmiringFactorUploadFile},
  {path: "/admiring-factor/employers", component: AdmiringFactorEmployers},

  // Dashboard
  {path: "/dashboard", component: Dashboard},

  // Tasks
  {path: "/treatment/tasks", component: TreatmentTasks},
  {path: "/tasks", component: Tasks},


  {path: "/reports/insurancemountain", component: InsuranceMountain},

  // Conversation
  {path: "/conversation", component: Conversation},

  {path: "/calendar", component: Calendar},

  // this route should be at the end of all other routes
  {path: "/", exact: true, component: () => <Redirect to="/reports/insurancemountain"/>}
];

const publicRoutes = [
  {path: "/signature", component: Signature},
  {path: "/conversation/client", component: ConversationClient},
  {path: "/login/client", component: LoginClient},
  {path: "/login", component: Login},
  {path: "/forgot-password", component: ForgetPwd},
  {path: "/register", component: Register},
  {path: "/auth-lock-screen", component: AuthLockScreen},
  {path: "/client/details", component: ClientDetails},
];

export {authProtectedRoutes, publicRoutes};
