import React, {useState} from 'react';
import {Button, Col, Form, Input, Row} from "antd";
import {verifyCode} from "../../../../../services/conversation";
import {useHistory} from "react-router-dom";
import AlertMessage from "../../../../../components/antDesign/Alert";

interface Props {
  client: any;
  tokenTemporary: string;
}

const VerifyCode: React.FC<Props> = (props) => {

  const [code, setCode] = useState<string>('');
  const [errorCode, setErrorCode] = useState<boolean>(false);

  const [form] = Form.useForm();
  let history = useHistory();

  const handleVerifyCode = (values: any) => {
    verifyCode(values, props.tokenTemporary).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        localStorage.setItem("authUser", JSON.stringify({
          user: {
            token: response.token
          }
        }));

        history.push({pathname: '/client/details'});
      } else {
        setErrorCode(true);
      }
    })
  }

  return (
    <Row>
      <Col lg={24} md={24} sm={24} xs={24}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleVerifyCode}>
          <Row className='text-left'>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                label="קוד האימות"
                name='code'>
                <Input placeholder="קוד האימות" onChange={(event) => setCode(event.target.value)}/>
              </Form.Item>
            </Col>

            {
              errorCode &&
                <Col lg={24} md={24} sm={24} xs={24} className='mb-4'>
                    <AlertMessage
                        type={'error'}
                        message={'קוד אימות לא תקין'}
                        showIcon/>
                </Col>
            }

            <Col lg={24} md={24} sm={24} xs={24} className='text-center'>
              <Form.Item>
                <Button
                  type="primary"
                  disabled={code.length <= 0}
                  htmlType="submit">
                  אימות הקוד
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default VerifyCode;
