import React from 'react';
import {Col, Modal, Row, Typography} from "antd";

const {Text} = Typography;

interface Props {
  visible: boolean;
  onClose: () => void;
  companyDataModal: any;
}

const ViewCompanyModal: React.FC<Props> = (props) => {
  return (
    <Modal
      title={"פרטי חברה"}
      open={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      maskClosable={false}
      cancelButtonProps={{style: {display: 'none'}}}
      destroyOnClose
      okText="סגור"
      width={1000}>

      <Row>
        <Col lg={24} md={24} sm={24} xs={24} className="mb-3">
          <Text strong type="warning">כללי</Text>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>סוג</Text></div>
          <div><Text>{props.companyDataModal.idType === 1 ? 'ע.מ' : 'ח.פ'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>{props.companyDataModal.idType === 1 ? 'מס׳ עוסק מורשה' : 'מס׳ חברה'}</Text></div>
          <div><Text>{props.companyDataModal.idNumber || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>שם חברה</Text></div>
          <div><Text>{props.companyDataModal.name || '-'}</Text></div>
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} className="mt-4 mb-3">
          <Text strong type="warning">פרטי התקשרות</Text>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>שם פרטי</Text></div>
          <div><Text>{props.companyDataModal.contactFirstName || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>שם משפחה</Text></div>
          <div><Text>{props.companyDataModal.contactLastName || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>סלולרי</Text></div>
          <div><Text>{props.companyDataModal.contactMobile || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>טלפון נייח</Text></div>
          <div><Text>{props.companyDataModal.contactPhone || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>אימייל</Text></div>
          <div><Text>{props.companyDataModal.contactEmail || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>כתובת</Text></div>
          <div><Text>{props.companyDataModal.address || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>עיר</Text></div>
          <div><Text>{props.companyDataModal.city || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>מיקוד</Text></div>
          <div><Text>{props.companyDataModal.zipCode || '-'}</Text></div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewCompanyModal;
