import React, {Component} from 'react';
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {getEmployersList} from '../../../services/admiringFactor/employersService';

//i18n
import {withNamespaces} from 'react-i18next';

import {MDBDataTable} from "mdbreact";
import EmployerModal from "../Employers/EmployerModal";
import ViewEmployerModal from "./ViewEmployerModal";

class EmployersListPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems: [
                {title: this.props.t("BREADCRUMB.ADMIRING_FACTOR.EMPLOYERS.MAIN"), link: "#"},
                {title: this.props.t("BREADCRUMB.ADMIRING_FACTOR.EMPLOYERS.LIST"), link: "#"}
            ],
            employerViewModalShow: false,
            employerDataModal: {
                type: {},
                contact: {},
                user: {}
            },

            EmployerModalShow: false,
            isNew: false,
            data: {}
        }
    }

    componentDidMount() {
        this.getData().then(() => {
        });
    }

    async getData() {
        let _self = this;
        const response = await getEmployersList();
        let state = this.state;
        state.list = response.list;
        state.data = {
            columns: [
                {
                    label: this.props.t("ADMIRING_FACTOR.EMPLOYERS.LIST.TABLE.HEADER.ID_TYPE"),
                    field: "idType",
                    sort: "asc"
                },
                {
                    label: this.props.t("ADMIRING_FACTOR.EMPLOYERS.LIST.TABLE.HEADER.ID_TYPE_NAME"),
                    field: "idTypeName",
                    sort: "asc"
                },
                {
                    label: this.props.t("ADMIRING_FACTOR.EMPLOYERS.LIST.TABLE.HEADER.ID_NUMBER"),
                    field: "idNumber",
                    sort: "asc"
                },
                {
                    label: this.props.t("ADMIRING_FACTOR.EMPLOYERS.LIST.TABLE.HEADER.NAME"),
                    field: "name",
                    sort: "asc"
                },
                {
                    label: this.props.t("ADMIRING_FACTOR.EMPLOYERS.LIST.TABLE.HEADER.CONTACT_NAME"),
                    field: "contactFullName",
                    sort: "asc"
                },
                {
                    label: this.props.t("ADMIRING_FACTOR.EMPLOYERS.LIST.TABLE.HEADER.CONTACT_MOBILE"),
                    field: "contactMobile",
                    sort: "asc"
                }, {
                    label: this.props.t("ADMIRING_FACTOR.EMPLOYERS.LIST.TABLE.HEADER.CONTACT_EMAIL"),
                    field: "contactEmail",
                    sort: "asc"
                },
                {
                    label: this.props.t("ADMIRING_FACTOR.EMPLOYERS.LIST.TABLE.HEADER.ACTIONS"),
                    field: "actions",
                    sort: "disabled"
                }
            ],
            rows: []
        }

        state.data.rows = state.list.map(function (value) {
            //action buttons
            let actionButtons;
            actionButtons = <>
                <Button
                    onClick={() => _self.setState({EmployerModalShow: true, isNew: false, employerDataModal: value})}
                    className="btn-rounded ml-2" size="sm"
                    color="primary">{_self.props.t("USER_PAGES.LIST.TABLE.ACTIONS.EDIT")} <i
                    className="mdi mdi-account-edit-outline ml-2"/></Button>
                <Button onClick={() => _self.setState({employerViewModalShow: true, employerDataModal: value})}
                        className="btn-rounded ml-2" size="sm"
                        color="outline-primary">{_self.props.t("USER_PAGES.LIST.TABLE.ACTIONS.VIEW")} <i
                    className="mdi mdi-eye-outline ml-2"/></Button>
            </>

            return {
                idType: value.type.id,
                idTypeName: value.type.name,
                idNumber: value.idNumber,
                name: value.name,
                contactFullName: value.contact.firstName + ' ' + value.contact.lastName,
                contactMobile: value.contact.mobile,
                contactEmail: value.contact.email,
                actions: actionButtons
            }
        });
        this.setState(state);
    }

    closeViewEmployerModal() {
        this.setState({
            employerViewModalShow: false,
            isNew: false
        });
    }

    closeEmployerModal(refresh) {
        if (refresh) {
            this.getData().then(() => {
            });
        }

        this.setState({
            EmployerModalShow: false,
            isNew: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("BREADCRUMB.ADMIRING_FACTOR.EMPLOYERS.LIST")}
                                     breadcrumbItems={this.state.breadcrumbItems}/>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            <Link to="#" onClick={() => this.setState({
                                                EmployerModalShow: true,
                                                isNew: true,
                                                employerDataModal: {
                                                    type: {},
                                                    contact: {},
                                                    user: {}
                                                }
                                            })} className="btn btn-primary mb-2">
                                                <i className="mdi mdi-plus mr-2"/>
                                                {this.props.t("ADMIRING_FACTOR.EMPLOYERS.LIST.TABLE.ACTIONS.ADD_NEW")}
                                            </Link>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <MDBDataTable responsive striped bordered data={this.state.data}
                                                      displayEntries={false}
                                                      infoLabel={
                                                          [
                                                              this.props.t("TABLES.INFO_LABEL.SHOWING"),
                                                              this.props.t("TABLES.INFO_LABEL.TO"),
                                                              this.props.t("TABLES.INFO_LABEL.OF"),
                                                              this.props.t("TABLES.INFO_LABEL.ENTRIES")
                                                          ]
                                                      }
                                                      searchLabel={this.props.t("TABLES.SEARCH_LABEL")}
                                                      paginationLabel={
                                                          [
                                                              this.props.t("TABLES.PAGINATION_LABEL.PREVIOUS"),
                                                              this.props.t("TABLES.PAGINATION_LABEL.NEXT")
                                                          ]
                                                      }
                                                      noRecordsFoundLabel={this.props.t("TABLES.NO_RECORD_FOUND_LABEL")}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <ViewEmployerModal employerViewModalShow={this.state.employerViewModalShow}
                                           employerDataModal={this.state.employerDataModal}
                                           closeViewEmployerModal={() => this.closeViewEmployerModal()}/>
                        <EmployerModal EmployerModalShow={this.state.EmployerModalShow}
                                       employerDataModal={this.state.employerDataModal} isNew={this.state.isNew}
                                       closeEmployerModal={(refresh) => this.closeEmployerModal(refresh)}/>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(EmployersListPage);
