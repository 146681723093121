import React, {useEffect} from 'react';
import {Button, Col, Form, Radio, Row, Select, Typography} from "antd";
import AlertMessage from "../../../components/antDesign/Alert";
import useDeviceDetect from "../../../hooks/useDeviceDetect";

const {Title} = Typography;

interface Props {
  client: any;
  onSubmit: (values: any) => void;
  error: { active: boolean, message: string };
}

const ageOptions = [...Array(103).keys()].map(age => ({value: age + 18, label: (age + 18).toString()}));
const Questionnaire: React.FC<Props> = (props) => {
  const {isMobile} = useDeviceDetect();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    props.onSubmit(values)
  }

  const handleChange = (value: number) => {
    form.setFieldValue('age', value)
  };
  useEffect(() => {
    form.setFieldsValue({
      hasInvestment: '',
      hasLoan: '',
      employmentStatus: '',
      age: undefined
    })
    return () => {
    };
  }, [props.client])


  return (
    <Form
      form={form}
      layout={isMobile ? "vertical" : "horizontal"}
      onFinish={onFinish}
    >
      <Row gutter={[16, 16]}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Title level={5}>לשם בדיקת הזכאות נא ענו על 3 שאלות הבאות:</Title>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            label="גיל"
            initialValue={''}
            name='age'>
            <Select
              style={{width: 68}}
              onChange={handleChange}
              options={ageOptions}
            />
          </Form.Item>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            label="האם קיימת לך קרן פנסיה/גמל/השתלמות מעל 100,000?"
            initialValue={''}
            rules={[{required: true, message: "שדה חובה"}]}
            name='hasInvestment'>
            <Radio.Group>
              <Radio value={true}> כן </Radio>
              <Radio value={false}> לא </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            label="האם קיים עיקול/הלוואה על אחת או יותר מהקרנות / קופות שלך?"
            initialValue={''}
            rules={[{required: true, message: "שדה חובה"}]}
            name='hasLoan'>
            <Radio.Group>
              <Radio value={true}> כן </Radio>
              <Radio value={false}> לא </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>

          <Form.Item
            label='מצבך התעסוקתי הוא:'
            initialValue={''}
            rules={[{required: true, message: "שדה חובה"}]}
            name='employmentStatus'>
            <Radio.Group>
              <Radio value="employee"> שכיר </Radio>
              <Radio value="freelancer"> עצמאי </Radio>
              <Radio value="unemployed"> לא עובד </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        {props.error.active &&
          <Col lg={24} md={24} sm={24} xs={24} style={{marginBottom: 10}}>
            <AlertMessage type={'error'} message={props.error.message} showIcon/>
          </Col>
        }
      </Row>
      <Button type="primary" htmlType="submit" className='custom-button'>
        המשך
      </Button>
    </Form>
  );
};

export default Questionnaire;
