import React, {useEffect, useState} from 'react';
import {Alert, Checkbox, Col, Form, Input, Modal, Radio, Row} from "antd";
import {sendAgainDocumentSignature} from "../../services/client";
import Messages from "../antDesign/Message";
import {getLoggedInUser} from "../../services/auth";

interface Props {
  visible: boolean;
  client: any;
  onClose: () => void;
}

const DocumentsSignatureModal: React.FC<Props> = (props) => {

  const [signatureBy, setSignatureBy] = useState<string | undefined>(undefined);

  const [form] = Form.useForm();

  const createBodyRequest = (values: any) => {
    const documents = {};
    values.documents.forEach((document: string) => {
      const index = props.client.appendixDocuments.findIndex((appendixDocument: any) => appendixDocument.appendixType === document);
      if (index !== -1) {
        documents[document] = !props.client.appendixDocuments[index].isSigned;
      } else {
        documents[document] = true;
      }
    })
    values.documents = documents;

    if (getLoggedInUser().type === 'treatment') {
      values.agentId = props.client.agent._id;
    }
    return values;
  }

  const handleSendSignature = (values: any) => {
    values = createBodyRequest(values);
    sendAgainDocumentSignature(values).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        Messages.Success('המסמכים נשלחו בהצלחה').then(() => {
        })
        props.onClose();
      } else {
        Messages.Error('שליחת המסמכים נכשלה').then(() => {
        })
      }
    })
  }

  const handleAfterClose = () => {
    setSignatureBy(undefined);
    form.resetFields();
  }

  const onModalOpen = () => {
    console.log(props.client)
    setSignatureBy('byEmail');
    form.setFieldsValue({
      documents:
        props.client.appendixDocuments.map((appendixDocument: any) => appendixDocument.appendixType)
    })
  }

  useEffect(() => {
    if (props.visible) {
      onModalOpen();
    }

  }, [props.visible])

  const isDisabled = (docName: string) => {
    const appendixDocuments = props.client && props.client.appendixDocuments ? props.client.appendixDocuments : [];
    const doc = appendixDocuments.filter((doc: any) => doc.appendixType === docName);
    return doc.length > 0 && doc[0].isSigned
  }

  return (
    <Modal
      title={"שליחת חתימת מסמכים"}
      open={props.visible}
      afterClose={handleAfterClose}
      onCancel={() => props.onClose()}
      onOk={form.submit}
      maskClosable={false}
      destroyOnClose
      okButtonProps={{
        disabled: (signatureBy === 'byEmail' && !props.client.email) ||
          ((signatureBy === 'byWhatsapp' || signatureBy === 'bySMS') && !props.client.phone) ||
          (isDisabled('appendixA') && isDisabled('appendixB') && isDisabled('appendixE'))
      }}
      okText={"שליחת חתימת מסמכים"}
      cancelText="ביטול"
      width={700}>

      <Form form={form}
            layout="vertical"
            onFinish={handleSendSignature}>

        <Form.Item
          name='_id'
          initialValue={props.client && props.client._id ? props.client._id : ''}
          hidden>
          <Input hidden/>
        </Form.Item>

        <Row gutter={[16, 16]}>

          <Col lg={24} md={24} sm={24} xs={24} className="pt-2">
            {
              signatureBy === 'byEmail' &&
                <Alert message={
                  !props.client.email ? 'אימייל לא קיים. יש להזין למערכת על מנת לבצע שליחה' :
                    'המסמכים ישלחו לאימייל שכתובתו ' + props.client.email
                } type={!props.client.email ? "error" : 'info'} showIcon/>
            }

            {
              (signatureBy === 'byWhatsapp' || signatureBy === 'bySMS') &&
                <Alert message={
                  !props.client.phone ? 'טלפון נייד לא קיים. יש להזין למערכת על מנת לבצע שליחה' :
                    'המסמכים ישלחו לטלפון הנייד שמספרו ' + props.client.phone
                } type={!props.client.phone ? "error" : 'info'} showIcon/>
            }
          </Col>

          <Col lg={24} md={24} sm={24} xs={24} className="pt-2">
            <Form.Item initialValue="byEmail" name="signatureBy" label="חתום באמצעות">
              <Radio.Group onChange={(event) => setSignatureBy(event.target.value)}>
                <Radio value="byEmail">Email</Radio>
                <Radio value="byWhatsapp">Whatsapp</Radio>
                <Radio value="bySMS">SMS</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="documents" label="מסמכים לתחימה">
              <Checkbox.Group>
                <Row>
                  <Col span={24}>
                    <Checkbox disabled={isDisabled('appendixA')} value="appendixA"
                              style={{lineHeight: '32px'}}>
                      נספח א׳ (מסלקה)
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox disabled={isDisabled('appendixB')} value="appendixB"
                              style={{lineHeight: '32px'}}>
                      נספח ב׳ (חברות ביטוח)
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox disabled={isDisabled('appendixE')} value="appendixE"
                              style={{lineHeight: '32px'}}>
                      נספח ה׳ (הר הביטוח)
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>

        </Row>

      </Form>

    </Modal>
  );
};

export default DocumentsSignatureModal;
