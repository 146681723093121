import React from 'react';
import {getLoggedInUserId} from "../../../../services/auth";
import Mislaka from "./documentsTabs/mislaka";
import InsuranceMountain from "./documentsTabs/insuranceMountain";
import Signature from "./documentsTabs/signature";
import Boxes from "./documentsTabs/boxes";
import Tabs from "../../../antDesign/Tabs";

const Documents = () => {

  const getClientId = () => {
    const params = new URLSearchParams(window.location.search);
    const url = window.location.href.split('?');
    const cid = params.get('cid');
    // agent
    if (url.length > 0 && url[0].endsWith('client-details'))
      if (cid)
        return cid;
      else
        // client
      if (window.location.href.endsWith('client/details'))
        return getLoggedInUserId();

    return undefined;
  }

  const items = [
    { label: 'נספחים לחתימה', key: '1', children: <Signature clientId={getClientId()}/> }, // remember to pass the key prop
    { label: 'מסלקה פנסיונית', key: '2', children: <Mislaka clientId={getClientId()}/> },
    { label: 'הר הביטוח', key: '3', children: <InsuranceMountain clientId={getClientId()}/> },
    { label: 'קוביות ה׳1', key: '4', children: <Boxes clientId={getClientId()}/> },
  ];

  return (<Tabs
    style={{marginTop: '1rem', marginBottom: '3rem'}}
    items={items}
    type='card'/>);
};

export default Documents;
