import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Form, Input, Row} from "antd";
import {useHistory} from "react-router-dom";
import AlertMessage from "../../../components/antDesign/Alert";
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {isUserAuthenticated, postLogin, setAuthUser} from '../../../services/auth';
import useDeviceDetect from "../../../hooks/useDeviceDetect";
import Main from "./components/Main";

const Login: React.FC = () => {

  const [loginError, setLoginError] = useState<boolean>(false);

  const [form] = Form.useForm();
  const history = useHistory();
  const {isMobile} = useDeviceDetect();

  const handleLogin = (values: any) => {
    setLoginError(false);
    postLogin({user: values}).then(response => {
      if (response.status &&
        response.status.toString() === 'SUCCESS') {
        setAuthUser({user: response.user})
        history.push('/reports/insurancemountain');
      } else {
        setLoginError(true);
      }
    }).catch(() => {
      setLoginError(true);
    })
  }

  useEffect(() => {

    if (isUserAuthenticated()) {
      history.push('/dashboard');
    }

    form.setFieldsValue({customControlInline: false});
    return () => {
    };
  }, []);

  return (
    <Main>
      <div className="text-center">
        <h4 className="font-size-18 mt-4">היי, טוב לראות אותך</h4>
        <p className="text-muted">התחבר כדי להמשיך למערכת קליק אג׳נט</p>
      </div>

      {
        loginError &&
          <Row className='mt-4 mb-3'
               style={!isMobile ? {
                 marginRight: 31,
                 marginLeft: 31,
               } : {
                 marginRight: 8,
                 marginLeft: 8,
               }}>
              <Col xl={24} lg={24} md={24} sm={24} xs={24} className='text-left'>
                  <AlertMessage type="error" message='אימייל או סיסמא לא נכונים' showIcon/>
              </Col>
          </Row>
      }
      <div className={"p-2 text-left " + (!loginError ? 'mt-5' : '')}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleLogin}>

          <Row className={!isMobile ? 'mx-4' : ''}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                /*initialValue="netanelmal@gmail.com"*/
                initialValue=""
                rules={[{required: true, message: 'אנא הכנס אימייל'}, {
                  type: 'email',
                  message: 'אימייל לא תקין'
                }]}
                name='email'>
                <Input placeholder="אימייל" prefix={<UserOutlined className="site-form-item-icon"/>}/>
              </Form.Item>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item
               /* initialValue="a123456"*/
                initialValue=""
                rules={[{required: true, message: 'אנא הכנס סיסמא'}]}
                name='password'>
                <Input type="password" placeholder="סיסמא"
                       prefix={<LockOutlined className="site-form-item-icon"/>}/>
              </Form.Item>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                name="customControlInline"
                valuePropName='checked'
                noStyle>
                <Checkbox className='text-secondary'>זכור אותי</Checkbox>
              </Form.Item>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="mt-4 text-center">
                <Button type="primary"
                        className='px-4'
                        htmlType="submit">כניסה</Button>
              </div>
            </Col>

            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="mt-5 text-center">
                <p> אין לך חשבון? <a href="mailto:office@ab-fin.co.il"
                                     className="font-weight-medium text-secondary"> צור קשר </a>
                </p>
                <p>© כל הזכויות שמורות לקליק אג׳נט</p>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Main>
  );
};

export default Login;
