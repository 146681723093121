import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import {reactI18nextModule} from "react-i18next";

import translationENG from './locales/en/translation.json';
import translationHE from './locales/he/translation.json';

//translations
const resources = {
    he: {
        translation: translationHE
    },
    eng: {
        translation: translationENG
    }
};

i18n
    .use(detector)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "he",
        fallbackLng: "he", // use en if detected lng is not available

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;