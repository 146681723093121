import React from 'react';
import {Spin} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import {AntDesign} from "./typings";

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>

const Loader: React.FC<AntDesign.Loading> = (props) => {
  return (
    <Spin className={props.className} style={props.style} spinning={props.loading} tip={props.tip}></Spin>
  );
};

const LoadingContainer: React.FC<AntDesign.Loading> = (props) => {
  return (
    <Spin className={props.className} style={props.style} spinning={props.loading} indicator={antIcon} tip={props.tip}>{props.children}</Spin>
  );
};

const Load = {Loading: Loader, LoadingContainer}

export default Load;
