import React, {useEffect, useState} from 'react';
import {Avatar, Col, Form, Input, Row, Typography, Upload, UploadProps} from "antd";
import Button from "../../../../components/antDesign/Button";
import {getUsersDetails, updateUsersDetails, uploadLogo} from "../../../../services/user";
import Messages from "../../../../components/antDesign/Message";
import {UploadOutlined} from "@ant-design/icons";
import Message from "../../../../components/antDesign/Message";
import {UploadFile} from "antd/lib/upload/interface";

const {Text} = Typography;

const PersonalDetails = () => {

  const [form] = Form.useForm();
  const [user, setUser] = useState<USER.UserDetails | undefined>(undefined);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<any>(undefined);

  const handleUpdate = (values: any) => {
    updateUsersDetails(values).then(response => {
      console.log(response);
      if(response.status === 'SUCCESS') {
        Messages.Success('הפרטים האישיים עודכנו בהצלחה!');
      }else {
        Messages.Error('נכשל בעדכון הפרטים האישיים');
      }
    }).catch((error) => {
      Messages.Error(error.displayMessage);
    })
  }

  const upload = (file: any) => {
    setUploadLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', event => {
      const image = document.createElement('img');
      // @ts-ignore
      image.src = event.target?.result;
      image.addEventListener('load', () => {
        const { width, height } = image;
        // console.log(width, height);
        const pixels = 350;
        if (width !== pixels || height !== pixels) {
          Message.Error(`גודל התמונה צריכה להיות ${pixels}x${pixels}`);
          setUploadLoading(false);
          return;
        }
        uploadLogo(file).then(response => {
          if(response.status === 'SUCCESS') {
            setLogo(response.logo)
          }else {
            setLogo(undefined);
          }
          setUploadLoading(false);
        }).catch(() => {
          setLogo(undefined);
        })
      });
    });

  }

  useEffect(() => {
    getUsersDetails().then(response => {
      if (response.status === 'SUCCESS') {
        response.user.type =
          response.user.type === 'agent' ? 'סוכן' :
            response.user.type === 'treatment' ? 'גורם מטפל' :
              response.user.type === 'admin-company' ? 'מנהל חברה' :
                response.user.type === 'admin' ? 'מנהל' : '';

        setUser(response.user);
        setLogo(response.logo);
        form.setFieldsValue(response.user)
      }
    })
    return () => {
    };
  }, []);

  const propsUpload: UploadProps = {
    name: 'logo',
    accept: 'image/*',
    showUploadList: false,
    maxCount: 1,
    listType: 'picture',
    beforeUpload: () => {
      return false;
    },
    onChange: (info) => {
      if (info.file.status !== 'uploading') {
        if(info.file.size) {
          const isLt2M = info.file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            Message.Error('הלוגו צריך להיות קטן מ 2MB');
            return;
          }
          upload(info.file);
        }
      }
    },
  };

  return (
    <div id='personal-details'>
      <Form form={form}
            layout="vertical"
            onFinish={handleUpdate}>

        <Row gutter={[16, 16]}>
          <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
            <Row gutter={[16, 16]}>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className='my-3'>
                <Text strong>אימייל</Text>
                <br/>
                <Text>{user?.email}</Text>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className='my-3'>
                <Text strong>ת.ז</Text>
                <br/>
                <Text>{user?.userId}</Text>
              </Col>

              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} className='my-3'>
                <Text strong>סוכנות</Text>
                <br/>
                <Text>{user?.agencyName}</Text>
              </Col>
              <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24} className='my-2'>
                <Form.Item
                  label='שם פרטי'
                  rules={[{required: true, message: "שדה חובה"}]}
                  name='firstName'>
                  <Input size='large' placeholder='שם פרטי'/>
                </Form.Item>
              </Col>

              <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24} className='my-2'>
                <Form.Item
                  label='שם משפחה'
                  rules={[{required: true, message: "שדה חובה"}]}
                  name='lastName'>
                  <Input size='large' placeholder='שם משפחה'/>
                </Form.Item>
              </Col>

              <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24} className='my-2'>
                <Form.Item
                  label='טלפון'
                  rules={[{required: true, message: "שדה חובה"}]}
                  name='phone'>
                  <Input size='large' placeholder='טלפון'/>
                </Form.Item>
              </Col>

              <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24} className='my-2'>
                <Form.Item
                  label='מס׳ רשיון'
                  rules={[{required: true, message: "שדה חובה"}]}
                  name='agentLicense'>
                  <Input size='large' placeholder='מס׳ רשיון'/>
                </Form.Item>
              </Col>

              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className='my-2' style={{textAlign: 'right'}}>
                <Button htmlType="submit" type='primary'>עדכן פרטים</Button>
              </Col>
            </Row>
          </Col>

          <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
            <Row style={{width: 400}}>
              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} style={{textAlign: 'center'}}>
                {logo &&
                    <img src={logo} alt='logo' className='img-user'/>
                }
                {!logo &&
                    <Avatar>LOGO</Avatar>
                }
              </Col>
              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className='mt-4' style={{textAlign: 'center'}}>
                <Upload disabled={uploadLoading} {...propsUpload}>
                  <Button loading={uploadLoading} icon={<UploadOutlined/>}>העלאת לוגו</Button>
                </Upload>
              </Col>
            </Row>
          </Col>
        </Row>

      </Form>
    </div>
  );
};

export default PersonalDetails;
