import React, {useEffect, useState} from 'react';
import {Row, Col, Card, Table, Tag, Badge, Button} from 'antd';
import {getProperties} from '../../../services/task';
import AddEditPropertiesModal from "../../../components/Properties/AddEditPropertiesModal";
import {Properties} from "./typings";
import {ColumnsType} from "antd/lib/table";
import Breadcrumbs from "../../../components/antDesign/Breadcrumb";

const BreadcrumbsItems = {
  title: "רשימת תכונות",
  breadcrumbs: [{item: "רשימת תכונות"}, {item: "תכונות"}]
}

const columns: ColumnsType<Properties.DataType> = [
  {title: 'תכונה', dataIndex: 'type', key: 'type'},
  {title: 'תת תכונה', dataIndex: 'subtype', key: 'subtype'},
  {title: 'חברה', dataIndex: 'company', key: 'company'},
  {
    title: 'מצב',
    key: 'status',
    dataIndex: 'status',
    render: (status) => (
      <span>
                {
                  status &&
                    <>
                        <Badge status="success"/>
                        פעיל
                    </>
                }

        {
          !status &&
            <>
                <Badge status="error"/>
                לא פעיל
            </>
        }

            </span>
    ),
  },
  {title: 'פעולות', dataIndex: 'actions', key: 'actions'},
];

const columnsExpanded: ColumnsType<Properties.ExpandedDataType> = [
  {title: 'סוג שדה', dataIndex: 'type_field', key: 'type_field'},
  {title: 'שם שדה', dataIndex: 'name_field', key: 'name'},
  {
    title: 'נדרש',
    key: 'required_field',
    render: (field) => (
      <>
        <Tag
          color={field.required_field ? "error" : "default"}>{field.required_field ? "חובה" : "לא חובה"}</Tag>
      </>
    ),
  },
  {
    title: 'מצב',
    key: 'state_field',
    render: (field) => (
      <span>
                {
                  field.state_field &&
                    <>
                        <Badge status="success"/>
                        פעיל
                    </>
                }

        {
          !field.state_field &&
            <>
                <Badge status="error"/>
                לא פעיל
            </>
        }

            </span>
    ),
  },
];

const PropertiesList: React.FC = () => {
  const [data, setDate] = useState<Properties.DataType[]>([]);
  const [dataModal, setDateModal] = useState<any>(undefined);
  const [propertiesModalShow, setPropertiesModalShow] = useState<boolean>(false);
  const [isNew, setNew] = useState<boolean>(false);

  useEffect(() => {
    loadData();
    return () => {
    };
  }, [])

  const loadData = () => {
    getData().then(() => {
    });
  }

  const getData = async () => {
    const response = await getProperties();
    loadTable(response.list)
  }

  const expandedRowRender = (record: any) => {
    const expandedRowData = [];

    for (let i = 0; i < record.fields.length; ++i) {
      let type;

      switch (record.fields[i].type) {
        case 'text':
          type = 'טקסט';
          break
        case 'number':
          type = 'מספר';
          break
        case 'email':
          type = 'אימייל';
          break
        case 'tel':
          type = 'טלפון';
          break
        case 'checkbox':
          type = 'תיבת סימון';
          break
        case 'date':
          type = 'תאריך';
          break
        case 'radio':
          type = 'כפתור בחירה';
          break
        case 'select':
          type = 'בחירה מרובה';
          break
        case 'file':
          type = 'קובץ';
          break
      }
      expandedRowData.push({
        key: i + "-" + record._id,
        type_field: type,
        name_field: record.fields[i].label,
        state_field: record.fields[i].active,
        required_field: record.fields[i].required,
      });
    }
    // @ts-ignore
    return <Table columns={columnsExpanded} dataSource={expandedRowData} pagination={false}/>;
  };

  const loadTable = (list: any) => {
    setDate(list.map(((property: any, index: number) => {

      const actions = <div key={index}>
        <Button
          type="primary"
          onClick={() => {
            setDateModal(property);
            setNew(false);
            setPropertiesModalShow(true);
          }}>ערוך
          <i className="mdi mdi-plus ml-2"/></Button>
      </div>

      return {
        key: index,
        type: property.type_label,
        subtype: property.subtype_label ? property.subtype_label : '--',
        company: property.company && property.company.name ? property.company.name : '--',
        status: property.active ? property.active : false,
        fields: property.fields,
        actions: actions
      }
    })))
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title={BreadcrumbsItems.title} breadcrumbs={BreadcrumbsItems.breadcrumbs}/>

        <Row>
          <Col xs={24}>
            <Card>
              <Button
                type="primary"
                style={{height: 40}}
                onClick={() => {
                  setDateModal(undefined);
                  setNew(true);
                  setPropertiesModalShow(true);
                }}>
                <i className="mdi mdi-plus mr-2"/>
                צור תכונה</Button>
            </Card>
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xs={24}>
            <Card>
              <Table
                className="components-table-demo-nested"
                columns={columns}
                expandable={{expandedRowRender}}
                dataSource={data}
              />
            </Card>
          </Col>
        </Row>

        <AddEditPropertiesModal
          visible={propertiesModalShow}
          data={dataModal}
          onClose={() => setPropertiesModalShow(false)}
          onConfirm={() => {
            setPropertiesModalShow(false)
            loadData();
          }}
          isNew={isNew}/>
      </div>
    </React.Fragment>
  )
}

export default PropertiesList;
