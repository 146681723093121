import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Steps, Typography} from "antd";
import {AuditOutlined, FileDoneOutlined, FormOutlined, UserOutlined} from '@ant-design/icons';
import PersonalDetailsTab from "./tabs/PersonalDetails";
import SignatureTab from "./tabs/Signature";
import {getClient} from "../../services/client";
import Loader from "../../components/antDesign/Loader";
import Messages from "../../components/antDesign/Message";
import Tabs from "../../components/antDesign/Tabs";
import Result from "./components/Result";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import Url from "../../helpers/Url";
import {createClient, updateClient} from "../../services/signature";
import {getUserType} from "../../services/user";
import {useHistory, useLocation} from "react-router-dom";
import Modal from "../../components/antDesign/Modal";
import Button from "../../components/antDesign/Button";
import Questionnaire from './tabs/Questionnaire';

const {Title} = Typography;

const {Step} = Steps;

const Signature = () => {
    const {isMobile} = useDeviceDetect();
    const history = useHistory();
    const location = useLocation();

    const [step, setStep] = useState<number>(1);
    const [id, setId] = useState<string>('');
    const [clientId, setClientId] = useState<string>('');
    const [type, setType] = useState<"CLIENT" | "AGENT" | "PROMOTION" | ''>('');
    const [client, setClient] = useState<any>({});
    const [agent, setAgent] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [visibleNumberIdExistModal, setVisibleNumberIdExistModal] = useState<boolean>(false);
    const [error, setError] = useState<{ active: boolean, message: string }>({active: false, message: ''});
    const [questionnaireValues, setQuestionnaireValues] = useState({
      hasInvestment: false,
      hasLoan: false,
      employmentStatus: '',
      age: 0
    });
    const getTitle = (): string => {
      switch (type) {
        case 'CLIENT':
          return 'אישור פרטים וחתימת לקוח';
        case 'AGENT':
          return 'מילוי פרטים וחתימת לקוח';
        case 'PROMOTION':
          return 'הלוואות מקרנות הפנסיה/גמל/השתלמות';
        default:
          return '';
      }
    }
    const getClientId = () => {
      const params: { cid?: string, aid?: string, flow?: 'CLIENT' | 'AGENT' | 'PROMOTION' | '' } = Url.getParams(['cid', 'aid', 'flow']);
      const _type = params.cid ? 'CLIENT' : (params.flow || 'AGENT');

      setId(params.cid || params.aid || '');
      setType(_type);
      setLoading(false);

      if (!params.cid && !params.aid) {
        setStep(0);
      }
    }

    const getClientData = async (clientId: string) => {
      await getClient({clientId}).then((data) => {
        const _client = data.client;
        if (!_client) {
          setLoading(false);
          setStep(0);
          return;
        }

        const appendixDocuments = _client.appendixDocuments;

        let currentDocToSign = 0;

        let totalDoc = _client.appendixDocuments.length;
        appendixDocuments.forEach((doc: any) => {
          if (doc.isSigned)
            currentDocToSign++;
        });

        if (type !== 'PROMOTION') {
          setStep(currentDocToSign === totalDoc && _client.signatureProcessStep === 2 ? 3 : _client.signatureProcessStep);
        } else {
          setStep(currentDocToSign === totalDoc && _client.signatureProcessStep === 2 ? 4 : _client.signatureProcessStep + 1);
        }

        setClient(Object(_client))
      }).catch(() => {
        setLoading(false);
        setStep(0);
      });
    }

    useEffect(() => {
      getClientId();
      return () => {
      };
    }, [])

    useEffect(() => {
      if (id && type === 'CLIENT') {
        getClientData(id).then(() => {
          setLoading(false);
        }).catch(() => {
          setLoading(false);
          setStep(0);
        });
      } else if (id && (type === 'AGENT' || type === 'PROMOTION')) {
        getUserType({userId: id}).then((response) => {
          if (response.status.toString() === 'SUCCESS') {
            setAgent(response.user);
          }
        }).catch(() => {
          setLoading(false);
          setStep(0);
        })
      }
      return () => {
      };
    }, [id, type])

    const onUpdateDetails = async (values: any) => {
      setError({active: false, message: ''});
      setLoading(true);
      let isOk = true;
      let clientId = id;

      switch (type) {
        case 'CLIENT':
          await updateClient({client: values}).catch((error) => {
            isOk = false;
            setError({active: true, message: error.displayMessage});
            setLoading(false)
          });
          break;
        case 'AGENT':
        case 'PROMOTION':
          const _client = {
            ...values,
            companyId: agent.companyId,
            agentId: agent._id,
            questionnaire: {...questionnaireValues, note: values.note},
            type,
            note: undefined
          };

          const response = await createClient({client: _client})
            .catch((error) => {
              const errors = error.errors;

              const index = errors.findIndex((e: any) => e.param === 'client.clientId' && e.msg === 'already_exist');
              if (index !== -1) {
                setVisibleNumberIdExistModal(true);
              } else {
                Messages.Error(error.displayMessage).then(() => {
                });
              }
              isOk = false;
              setLoading(false);
            });

          if (response && response.status && response.status.toString() === 'SUCCESS') {
            clientId = response.client_id;
            setClientId(clientId);
            if (type !== 'PROMOTION') {
              const params = Url.deleteAndSetParams([{key: 'cid', value: clientId}]);
              history.replace({pathname: location.pathname, search: params.toString()});
            }
          } else {
            clientId = '';
            isOk = false;
          }
          break;
      }

      if (isOk && type !== 'PROMOTION') {
        getClientData(clientId).then(() => {
          setLoading(false);
        }).catch(() => {
          Messages.Error('שגיאה בעת קבלת המידע על הלקוח').then(() => {
          });
          setLoading(false);
        });
      } else if (isOk) {
        setStep(step + 1);
        setLoading(false);
      }
    }

    const onQuestionnaireSubmit = (values: any) => {
      setQuestionnaireValues(values);
      setStep(step + 1);
    };

    const handleResultClose = () => {
      setId('');
      setClientId('');
      setType('');
      setClient({});
      setLoading(true);
      setQuestionnaireValues({
        hasInvestment: false,
        hasLoan: false,
        employmentStatus: '',
        age: 0
      });
      getClientId();
      setStep(1);
    }

    const items = type === 'PROMOTION' ? [
      {label: <></>, key: '0', children: <Result type='error'/>}, // remember to pass the key prop
      {
        label: <></>, key: '1', children: <Questionnaire client={client}
                                                         error={error}
                                                         onSubmit={onQuestionnaireSubmit}/>
      },
      {
        label: <></>, key: '2', children: <PersonalDetailsTab
          type={type}
          client={client}
          error={error}
          firstNameAgent={(client?.agent?.firstName || agent?.firstName)}
          lastNameAgent={(client?.agent?.lastName || agent?.lastName)}
          onUpdateDetails={onUpdateDetails}/>
      },
      /*  {
          label: <></>, key: '3', children: <SignatureTab
            client={client}
            onBeforeUpdateSignature={() => setLoading(true)}
            onAfterUpdateSignature={() => {
              getClientData(clientId)
                .then(() => {
                  setLoading(false);
                });
            }}/>
        },*/
      {
        label: <></>, key: '3', children: <Result type='success'
                                                  firstNameAgent={(client?.agent?.firstName || agent?.firstName)}
                                                  lastNameAgent={(client?.agent?.lastName || agent?.lastName)}
                                                  onClose={handleResultClose}/>
      },
    ] : [
      {label: <></>, key: '0', children: <Result type='error'/>}, // remember to pass the key prop
      {
        label: <></>, key: '1', children: <PersonalDetailsTab
          type={type}
          client={client}
          error={error}
          firstNameAgent={(client?.agent?.firstName || agent?.firstName)}
          lastNameAgent={(client?.agent?.lastName || agent?.lastName)}
          onUpdateDetails={onUpdateDetails}/>
      },
      {
        label: <></>, key: '2', children: <SignatureTab
          client={client}
          onBeforeUpdateSignature={() => setLoading(true)}
          onAfterUpdateSignature={() => {
            getClientData(type === 'CLIENT' ? id : clientId)
              .then(() => {
                setLoading(false);
              });
          }}/>
      },
      {
        label: <></>, key: '3', children: <Result type='success'
                                                  firstNameAgent={(client?.agent?.firstName || agent?.firstName)}
                                                  lastNameAgent={(client?.agent?.lastName || agent?.lastName)}/>
      },
    ];

    return (
      <React.Fragment>
        <Loader.LoadingContainer loading={loading} tip='טוען...'>
          <Row className="no-gutters">
            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="signature-bg">
                <div className="bg-overlay"/>
                <div className="content">
                  <Card className='signature-card'>
                    <Row justify="space-around" align="middle">
                      <Col lg={24} md={24} sm={24} xs={24}>
                        <div className='text-center mb-4'>
                          <Title level={4}>{getTitle()}</Title>
                        </div>

                        {step !== 0 && type !== 'PROMOTION' &&
                          <Steps responsive={false}>
                            <Step status={step < 1 ? 'wait' : step === 1 ? "process" : "finish"}
                                  title={!isMobile ? "פרטים אישיים" : ""}
                                  icon={<UserOutlined/>}/>
                            <Step status={step < 2 ? "wait" : step === 2 ? 'process' : "finish"}
                                  title={!isMobile ? "חתימה" : ""}
                                  icon={<AuditOutlined/>}/>
                            <Step status={step < 3 ? "wait" : step === 3 ? 'process' : "finish"}
                                  title={!isMobile ? "סיום" : ""}
                                  icon={<FileDoneOutlined/>}/>
                          </Steps>
                        }

                        {step !== 0 && type === 'PROMOTION' &&
                          <Steps responsive={false}>
                            <Step status={step < 1 ? 'wait' : step === 1 ? "process" : "finish"}
                                  title={!isMobile ? "שאלון" : ""}
                                  icon={<FormOutlined/>}/>
                            <Step status={step < 2 ? 'wait' : step === 2 ? "process" : "finish"}
                                  title={!isMobile ? "פרטים אישיים" : ""}
                                  icon={<UserOutlined/>}/>
                            {/* <Step status={step < 3 ? "wait" : step === 3 ? 'process' : "finish"}
                                  title={!isMobile ? "חתימה" : ""}
                                  icon={<AuditOutlined/>}/>*/}
                            <Step status={step < 3 ? "wait" : step === 3 ? 'process' : "finish"}
                                  title={!isMobile ? "סיום" : ""}
                                  icon={<FileDoneOutlined/>}/>
                          </Steps>
                        }
                        <Tabs className='tab-content' activeKey={step.toString()} items={items}/>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>

          <Modal
            open={visibleNumberIdExistModal}
            title="תעודת הזהות קיימת במערכת"
            onCancel={() => setVisibleNumberIdExistModal(false)}
            footer={[
              <Button type='primary' onClick={() => {
                // TODO NEED TO THINK WHAT TO DO WHEN CLIENT ID ALREADY EXIST
                setVisibleNumberIdExistModal(false);
              }}>סגור</Button>
            ]}>
            <Typography.Text>אנא פנה לסוכן לקבלת מידע נוסף</Typography.Text>
          </Modal>
        </Loader.LoadingContainer>
      </React.Fragment>
    );
  }
;

export default Signature;
