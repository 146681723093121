import React from 'react';
import {Button as ButtonAnt} from "antd";
import {SizeType} from "antd/es/config-provider/SizeContext";

interface Props {
  type?: "link" | "text" | "ghost" | "default" | "primary" | "dashed";
  icon?: React.ReactNode;
  size?: SizeType;
  loading?: boolean | {delay?: number | undefined};
  htmlType?: "button" | "submit" | "reset";
  disabled?: boolean;
  danger?: boolean | undefined;
  onClick?: React.MouseEventHandler<HTMLElement>;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const Button: React.FC<Props> = (props) => {
  return (
    <span className={props.children && props.icon ?
      props.size === 'small' ? 'fix-icon-with-text-small' :
        props.size === 'large' ? 'fix-icon-with-text-large' : 'fix-icon-with-text-middle' : ''}>
      <ButtonAnt
        htmlType={props.htmlType}
        loading={props.loading}
        disabled={props.disabled}
        type={props.type}
        icon={props.icon}
        size={props.size}
        onClick={props.onClick}
        style={props.style}
        className={props.className}
        danger={props.danger}>
        {props.children}
      </ButtonAnt>
    </span>
  );
};

export default Button;
