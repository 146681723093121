import React, {Component} from 'react';
import {
    Card,
    CardBody,
    Col,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import {MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem} from "mdbreact";
import {getLastFileList} from '../../../services/admiringFactor/filesService';
import {withNamespaces} from 'react-i18next';
import {MDBDataTable} from "mdbreact";
import moment from "moment";
import XmlViewer from "../../../components/XmlViewer";
import {Link} from "react-router-dom";

class LatelyUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            xmlViewer: {
                show: false,
                fileContent: '',
                title: '',
            },
            list: [],
            files: [],
        }
        this.toggleMore = this.toggleMore.bind(this);
    }

    componentDidMount() {
        this.getData().then(() => {
        });
    }

    toggleMore() {
        this.setState(prevState => ({
            more_menu: !prevState.more_menu
        }));
    }

    openXmlViewer(fileContent, title) {
        this.setState({
            xmlViewer: {
                show: true,
                fileContent: fileContent,
                title: title,
            }
        })
    }

    closeXmlViewer() {
        this.setState({
            xmlViewer: {
                show: false,
                fileContent: '',
                title: '',
            }
        });
    }

    async getData() {
        const self = this;
        const response = await getLastFileList();
        let state = this.state;
        state.list = response.list;
        state.data = {
            columns: [
                {
                    label: this.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.HEADER.DOCUMENT_NUMBER"),
                    field: "documentNumber",
                    sort: "asc"
                },
                {
                    label: this.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.HEADER.DOCUMENT_TYPE"),
                    field: "interfaceTypeName",
                    sort: "asc"
                },
                {
                    label: this.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.HEADER.EMPLOYER"),
                    field: "employerName",
                    sort: "asc"
                },
                {
                    label: this.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.HEADER.CREATED_BY"),
                    field: "uploadBy",
                    sort: "asc"
                },
                {
                    label: this.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.HEADER.CREATED_AT"),
                    field: "createdAt",
                    sort: "asc"
                },
                {
                    label: this.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.HEADER.ACTION"),
                    field: "actions",
                    sort: "disabled"
                },
                {
                    label: '',
                    field: "ellipsis",
                    sort: "disabled"
                }
            ],
            rows: []
        }

        state.data.rows = state.list.map(function (value) {
            //action buttons
            let actionButtons;
            actionButtons = <>
                <Link to="#" className="mr-3 text-primary" id="upload"><i
                    className="mdi mdi-cloud-upload-outline font-size-20"></i></Link>
                <UncontrolledTooltip placement="top" target="upload">
                    {self.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.ACTIONS.UPLOAD")}
                </UncontrolledTooltip>
                <Link to="#" className="mr-3 text-primary" id="download"><i
                    className="mdi mdi-file-download-outline font-size-20"></i></Link>
                <UncontrolledTooltip placement="top" target="download">
                    {self.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.ACTIONS.DOWNLOAD")}
                </UncontrolledTooltip>
                <Link to="#" className="text-primary" id="view"
                      onClick={() => {
                          self.openXmlViewer(value.fileContent, value.interfaceTypeName + " - " + value.employer.name)
                      }}
                ><i className="mdi mdi-eye-outline font-size-20"></i></Link>
                <UncontrolledTooltip placement="top" target="view">
                    {self.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.ACTIONS.VIEW")}
                </UncontrolledTooltip>
                {/*<Button
          className="btn-rounded ml-2"
          size="sm"
          color="primary"
        >
          {self.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.ACTIONS.UPLOAD")}
          <a className="mdi mdi-cloud-upload-outline ml-2"/>
        </Button>
        <Button
          className="btn-rounded ml-2"
          size="sm"
          color="outline-primary"
        >
          {self.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.ACTIONS.DOWNLOAD")}
          <a className="mdi mdi-file-download-outline ml-2"/>
        </Button>
        <Button
          onClick={() => { self.openXmlViewer(value.fileContent, value.interfaceTypeName + " - " + value.employer.name)}}
          className="btn-rounded ml-2" size="sm"
          color="outline-primary">{self.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TABLE.ACTIONS.VIEW")}
          <i className="mdi mdi-eye-outline ml-2"/>
        </Button>*/}
            </>

            let ellipsis = <>
                <MDBDropdown size="sm">
                    <MDBDropdownToggle color="link" className="shadow-none">
                        <i className="fa fa-ellipsis-h"></i>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu basic>
                        <MDBDropdownItem>דו״ח שכר</MDBDropdownItem>
                        <MDBDropdownItem>דוח סיכומים למעסיק</MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
            </>


            return {
                documentNumber: value.documentNumber,
                interfaceTypeName: value.interfaceTypeName,
                employerName: value.employer.name,
                uploadBy: value.user.firstName + ' ' + value.user.lastName,
                createdAt: moment(value.createdAt).format('HH:mm DD/MM/YYYY'),
                actions: actionButtons,
                ellipsis: ellipsis
            }
        });
        this.setState(state);
    }

    render() {
        return (
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-lg-4 text-secondary">{this.props.t("ADMIRING_FACTOR.LATELY_UPLOAD.TITLE")}</h4>
                            <MDBDataTable responsive data={this.state.data}
                                          displayEntries={false}
                                          infoLabel={
                                              [
                                                  this.props.t("TABLES.INFO_LABEL.SHOWING"),
                                                  this.props.t("TABLES.INFO_LABEL.TO"),
                                                  this.props.t("TABLES.INFO_LABEL.OF"),
                                                  this.props.t("TABLES.INFO_LABEL.ENTRIES")
                                              ]
                                          }
                                          searchLabel={this.props.t("TABLES.SEARCH_LABEL")}
                                          paginationLabel={
                                              [
                                                  this.props.t("TABLES.PAGINATION_LABEL.PREVIOUS"),
                                                  this.props.t("TABLES.PAGINATION_LABEL.NEXT")
                                              ]
                                          }
                                          noRecordsFoundLabel={this.props.t("TABLES.NO_RECORD_FOUND_LABEL")}
                            />
                            <XmlViewer xmlModalShow={this.state.xmlViewer.show}
                                       fileContent={this.state.xmlViewer.fileContent}
                                       title={this.state.xmlViewer.title}
                                       closeXmlViewer={() => this.closeXmlViewer()}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default withNamespaces()(LatelyUpload);
