import React from 'react';
import {Card, Col, Row} from "antd";
import Breadcrumbs from "../../components/antDesign/Breadcrumb";
import PersonalDetails from "./tabs/personalDetails";

const BreadcrumbsItems = {
  title: "הפרופיל שלי",
  breadcrumbs: []
}

const Profile = () => {

  // const tabs = [
  //   { label: 'פרטים אישיים', key: '1', children: <PersonalDetails/> },
  //   { label: 'חתימות', key: '2', children: <>חתימות</> },
  //   { label: 'הגדרות', key: '3', children: <>הגדרות</> },
  // ]

  return (
    <React.Fragment>
      <div id='profile' className="page-content">

        <Breadcrumbs title={BreadcrumbsItems.title} breadcrumbs={BreadcrumbsItems.breadcrumbs}/>

        <Row className='mb-4'>
          <Col xs={24}>
            <Card className='card-profile'>
              <PersonalDetails/>
              {/*<Tabs type="card" defaultActiveKey="1">*/}
              {/*  {tabs.map(tab => (*/}
              {/*    <Tabs.TabPane tab={tab.label} key={tab.key}>*/}
              {/*      {tab.children}*/}
              {/*    </Tabs.TabPane>*/}
              {/*  ))}*/}
              {/*</Tabs>*/}
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Profile;
