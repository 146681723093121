import React from 'react';
import { Tooltip as TooltipAnt } from 'antd';
import {TooltipPlacement} from "antd/lib/tooltip";
import {LiteralUnion} from "antd/lib/_util/type";
import {PresetColorType} from "antd/lib/_util/colors";
interface Props {
  title: string;
  placement?: TooltipPlacement;
  color?: LiteralUnion<PresetColorType, string>;
  arrowPointAtCenter?: boolean;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}
const Tooltip: React.FC<Props> = (props) => {
  return (
    <TooltipAnt
      title={props.title}
      placement={props.placement}
      color={props.color}
      arrowPointAtCenter={props.arrowPointAtCenter}
      style={props.style}
      className={props.className}>
      {props.children}
    </TooltipAnt>
  );
};

export default Tooltip;
