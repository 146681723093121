import React, {Component} from 'react';
import {
    Button,
    Modal, ModalBody, ModalFooter,
    ModalHeader,
} from "reactstrap";

//i18n
import {withNamespaces} from 'react-i18next';
import XMLViewer from "react-xml-viewer";

class XmlViewer extends Component {

    render() {
        return (
            <Modal
                isOpen={this.props.xmlModalShow}
                toggle={this.tog_static}
                backdrop="static"
                centered
                size="xl"
            >
                <ModalHeader toggle={() => this.props.closeXmlViewer()}>
                    {this.props.title}
                </ModalHeader>
                <ModalBody>
                    <XMLViewer
                        xml={this.props.fileContent}
                        collapsible={true}
                        indentSize={6}
                        style={{textAlign: 'left', direction: 'ltr'}}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="primary"
                            onClick={() => this.props.closeXmlViewer()}>{this.props.t("BUTTON.CLOSE")}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withNamespaces()(XmlViewer);
