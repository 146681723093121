import {message} from "antd";

const Success = (msg: any) => {
  return (message.success(msg));
};

const Error = (msg: any) => {
  return (message.error(msg));
};

const Messages = {Success, Error}

export default Messages;
