import React, {useRef, useState} from 'react';
import ProTable from "@ant-design/pro-table";
import {
  deleteInsuranceMountainDocument,
  getClientDetailsInsuranceMountainDocuments
} from "../../../../../../services/client";
import Messages from "../../../../../antDesign/Message";
import {ActionType, ProColumns} from "@ant-design/pro-components";
import moment from "moment/moment";
import {Button, Tooltip} from "antd";
import {DeleteOutlined, DownloadOutlined} from "@ant-design/icons";
import file from "../../../../../../helpers/file";
import DeleteModal from "../../../../../Modals/DeleteModal";
import Table from "../../../../../antDesign/ProTable";

interface Props {
  clientId: string;
}

const InsuranceMountain: React.FC<Props> = (props) => {

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [documentDataModal, setDocumentDataModal] = useState<any>(undefined);

  const onDownloadDocumentPressed = (doc: any, defaultType: string) => {
    if(doc.fileContent) {
      file.download(doc.fileName, doc.contentType || defaultType, doc.fileContent);
    }
  }

  const columnsInsuranceMountainDocuments: ProColumns[] = [
    {
      align: 'right',
      sorter: (a: any, b: any) => a.idType ? a.idType.localeCompare(b.idType) : false,
      title: "שם המסמך",
      fieldProps: {
        placeholder: 'שם המסמך'
      },
      dataIndex: 'fileName',
      hideInSearch: false,
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.createdAt ? a.createdAt.localeCompare(b.createdAt) : false,
      title: "תאריך העלאה",
      fieldProps: {
        placeholder: 'תאריך העלאה'
      },
      dataIndex: 'createdAt',
      hideInSearch: false,
    }, {
      align: 'right',
      title: "פעולות",
      dataIndex: 'actions',
      hideInSearch: true,
      render: (_: any, record: any) => [
        <div key={record._id}>

          <Tooltip title='הורדת המסמך'>
            <Button
              type='link'
              onClick={() => {
                onDownloadDocumentPressed(record, 'application/xlsx')
              }}>
              <DownloadOutlined/>
            </Button>
          </Tooltip>

          <Tooltip title='מחיקת המסמך'>
            <Button
              type='link'
              style={{color: "red"}}
              onClick={() => {
                setDeleteModalOpen(true);
                setDocumentDataModal(record);
              }}>
              <DeleteOutlined/>
            </Button>
          </Tooltip>
        </div>
      ]
    }
  ];

  const actionRefDocument = useRef<ActionType>();

  const onDeletePressed = () => {
    if (documentDataModal)
      deleteInsuranceMountainDocument({documentId: documentDataModal._id, clientId: props.clientId}).then((response) => {
        if (response.isDeleted) {
          Messages.Success('המסמך הוסר בהצלחה').then(() => {
          });
          setDeleteModalOpen(false);
          actionRefDocument.current?.reload();
        } else {
          Messages.Error('הסרת המסמך נכשלה').then(() => {
          });
        }
      })
  }

  const loadDataTableInsuranceMountainDocument = async (params: any) => {
    params.clientId = props.clientId;
    return await getClientDetailsInsuranceMountainDocuments(params).then(response => {
      const dataTable = response.documents.map((document: any) => {
        return {
          contentType: document.contentType,
          createdAt: document.createdAt ? moment(document.createdAt).format('DD/MM/YYYY') : '-',
          fileContent: document.fileContent,
          fileName: document.fileName,
          _id: document._id,
        };
      })
      return {
        status: response.status,
        current: response.current,
        total: response.total,
        pageSize: response.pageSize,
        data: dataTable,
      };
    })
  }

  return (
    <>
      <Table
        actionRef={actionRefDocument}
        request={loadDataTableInsuranceMountainDocument}
        columns={columnsInsuranceMountainDocuments}
      />

      <DeleteModal
        title={'מחיקת המסמך'}
        message={'האם ברצונך להסיר את המסמך ' + documentDataModal?.fileName + '?'}
        visible={isDeleteModalOpen}
        okText={'הסר'}
        onOk={() => {
          onDeletePressed()
        }}
        cancelText={'בטל'}
        onCancel={() => setDeleteModalOpen(false)}/>
    </>
  );
};

export default InsuranceMountain;
