import baseService from "../base";
import axios from "../axios";
export const getCompanies = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.companies.companies;
  url = url.replace(':current', "current=" + (data && data.current ? data.current : ''))
    .replace(':pageSize', "pageSize=" + (data && data.pageSize ? data.pageSize : ''));
  return await axios.GET(url, {auth: true}).catch();
}
export const getCompaniesList = async () => {
  let url = baseService.baseUrl + baseService.endpoints.companies.companiesList;
  return await axios.GET(url, {auth: true}).catch();
}
export const createCompany = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.companies.create;
  return await axios.POST(url, data, {auth: true}).catch();
}
export const updateCompany = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.companies.update;
  return await axios.PUT(url, data, {auth: true}).catch();
}
