import axios from '../axios';
import baseService from "../base";

export const getEmployersList = async () => {
  let url = baseService.baseUrl + baseService.endpoints.admiringFactor.employers.list;
  return await axios.GET(url, {auth: true}).catch();
}
export const getEmployersTypes = async () => {
  let url = baseService.baseUrl + baseService.endpoints.admiringFactor.employers.types;
  return await axios.GET(url, {auth: true}).catch();
}
export const createEmployer = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.admiringFactor.employers.create;
  return await axios.POST(url, data, {auth: true}).catch();
}
export const updateEmployer = async (data: any) => {
  let url = baseService.baseUrl + baseService.endpoints.admiringFactor.employers.update;
  return await axios.PUT(url, data, {auth: true}).catch();
}
